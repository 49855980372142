import { JourneySteps } from './journeysteps.class';
import { cloneDeep } from 'lodash';

const landingpageDefault = {
  type: 'landing',
  // id: null,
  // artistId: null,
  // createdAt: null,
  // updatedAt: null,
  title: '',
  relativeUrl: '',
  accessToken: '',
  metaData: {
    info: {
      title: '',
      relativeUrl: '',
      completeTag: '',
      passwordProtected: false,
      accessToken: '',
      scheduled: false,
      signupsDisabled: false,
      launchDate: '',
      customCss: '',
    },
    design: {
      colorPalette: {
        primary: {
          base: { h: 210, s: 50, l: 5 }, // --base
          text: null,
          accent: null,
          label: null,
        },
        secondary: {
          base: { h: 210, s: 0, l: 100 }, // --base
          text: null,
          accent: null,
          label: null,
        },
      },
      background: {
        landscape: {
          url: '',
          urlModified: '',
        },
        portrait: {
          url: '',
          urlModified: '',
        },
      },
      logo: {
        url: '',
        urlModified: '',
      },
    },
    layout: {
      content: {
        leftAligned: false,
        verticalAlignment: 'center',
        straightCorners: false,
        blur: false,
        shadow: false,
      },
      foreground: {
        use: false,
        type: 'image', // image/video
        url: '',
        urlModified: '',
        videoType: '', // ''/upload/youtube/vimeo
        videoUrl: '',
        roundedCorners: false,
        borderStyle: '',
        // padding: 0,
      },
    },
    journey: new JourneySteps().toJsObject(),
    seo: {
      facebookPixelId: '', // metaData.facebook_pixel
      googleTagManagerId: '', // metaData.gtm
      tiktokPixelId: '', // metaData.tiktok_pixel
    },
    backgroundColor: '#060d13',
    primaryColor: '#ffffff',
  },
  publishedMetaData: {
    // not yet published, empty by default
  },
};

export default function defaultLandingpageFactory() {
  return cloneDeep(landingpageDefault);
}
