import { featureFlags, uuidv4 } from '@/services/util.service';

class JourneyStep {
  constructor(type, content, design) {
    this.type = type;
    this.content = content;
    this.design = design;
  }
  updateField(fieldKey, value, target = this) {
    if (!(fieldKey in target)) return;
    target[fieldKey] = value;
  }
  updateContentField(fieldKey, value) {
    this.updateField(fieldKey, value, this.content);
  }
  updateDesignField(fieldKey, value) {
    this.updateField(fieldKey, value, this.design);
  }
}
class InbetweenJourneyStep extends JourneyStep {
  constructor(type, position, content, design, id = null, status = null) {
    super(type, content, design);
    this.position = position;
    this.id = id || uuidv4();
    this.status = status || 'live';
  }
}
class ListJourneyStep extends InbetweenJourneyStep {
  constructor(type, position, content, design, id = null, status = null) {
    super(type, position, content, design, id, status);
  }

  // CONTENT LIST ITEM CRUD
  createContentListItem(newListItem = null, listFieldKey = null) {
    this.createListItem(newListItem, 'content', listFieldKey);
    this.refreshPositionsInContentList('options');
  }
  readContentListItem(itemId, listFieldKey = null) {
    return this.readListItem(itemId, 'content', listFieldKey);
  }
  updateContentListItem(itemId, listFieldKey = null, listItemFieldKey = null, value) {
    this.updateListItem(itemId, 'content', listFieldKey, listItemFieldKey, value);
  }
  deleteContentListItem(itemId, listFieldKey = null) {
    this.deleteListItem(itemId, 'content', listFieldKey);
    this.refreshPositionsInContentList('options');
  }
  moveContentListItem(itemId, movement = 1, listFieldKey = null) {
    this.moveListItem(itemId, movement, 'content', listFieldKey);
  }
  cloneContentListItem(itemId, overrideFields = {}, listFieldKey = null) {
    this.cloneListItem(itemId, overrideFields, 'content', listFieldKey);
    this.refreshPositionsInContentList('options');
  }
  sortContentList(listFieldKey = null, listItemFieldKey = null) {
    this.sortList('content', listFieldKey, listItemFieldKey);
  }
  refreshPositionsInContentList(listFieldKey = null) {
    this.refreshPositionsInList('content', listFieldKey);
  }

  // LIST ITEM CRUD
  sortList(rootFieldKey = null, listFieldKey = null, listItemFieldKey = null) {
    if (!rootFieldKey || !listFieldKey) return;
    if (!(rootFieldKey in this)) return;
    if (!this[rootFieldKey]) return;
    if (!(listFieldKey in this[rootFieldKey])) return;
    const list = this[rootFieldKey][listFieldKey];
    if (!Array.isArray(list)) return;
    if (!list.length) return;
    if (!(listItemFieldKey in list[0])) return;
    // sort array by listItemFieldKey (ascending) and update position of each listitem..
    list.sort((a, b) => {
      const aVal = a[listItemFieldKey]?.toUpperCase() || '';
      const bVal = b[listItemFieldKey]?.toUpperCase() || '';
      return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
    });
    list.forEach((f, i) => {
      this.updateListItem(f.id, rootFieldKey, listFieldKey, 'position', i);
    });
  }
  createListItem(newListItem = null, rootFieldKey = null, listFieldKey = null) {
    if (!newListItem || !rootFieldKey || !listFieldKey) return;
    const list = this[rootFieldKey][listFieldKey];
    if (!Array.isArray(list)) return;
    list.push(newListItem);
  }
  readListItem(itemId, rootFieldKey = null, listFieldKey = null) {
    if (!rootFieldKey || !listFieldKey) return null;
    if (!(rootFieldKey in this)) return null;
    if (!this[rootFieldKey]) return null;
    if (!(listFieldKey in this[rootFieldKey])) return null;
    if (!Array.isArray(this[rootFieldKey][listFieldKey])) return null;
    return this[rootFieldKey][listFieldKey].find((f) => f.id === itemId) || null;
  }
  updateListItem(itemId, rootFieldKey = null, listFieldKey = null, listItemFieldKey = null, value) {
    if (!listItemFieldKey) return;
    const item = this.readListItem(itemId, rootFieldKey, listFieldKey);
    if (!item) return;
    if (!(listItemFieldKey in item)) return;
    item[listItemFieldKey] = value;
  }
  deleteListItem(itemId, rootFieldKey = null, listFieldKey = null) {
    const listItem = this.readListItem(itemId, rootFieldKey, listFieldKey);
    if (!listItem) return;
    const list = this[rootFieldKey][listFieldKey];
    if (!list) return;
    const index = list.indexOf(listItem);
    if (index < 0) return; // not found
    // is this reactive?
    list.splice(index, 1);
    list.forEach((option) => {
      if (option.position > index) {
        option.position -= 1;
      }
    });
  }
  moveListItem(itemId, movement = 1, rootFieldKey = null, listFieldKey = null) {
    const listItem = this.readListItem(itemId, rootFieldKey, listFieldKey);
    if (!listItem) return;
    const list = this[rootFieldKey][listFieldKey];
    const posA = listItem.position;
    if (posA < 0) return; // not found
    const edge = movement < 0 ? 0 : list.length;
    if (posA === edge) return; // already at the edge

    const oldListItemInPosition = list.find((f) => f.position === posA + movement);
    if (!oldListItemInPosition) return; // not found
    listItem.position = posA + movement;
    oldListItemInPosition.position = posA;
  }
  cloneListItem(itemId, overrideFields = {}, rootFieldKey = null, listFieldKey = null) {
    const itemToClone = this.readListItem(itemId, rootFieldKey, listFieldKey);
    if (!itemToClone) return;
    const newListItem = JSON.parse(JSON.stringify(itemToClone));
    newListItem.id = uuidv4();
    this.createListItem(
      { ...newListItem, ...overrideFields, position: this[rootFieldKey][listFieldKey].length },
      rootFieldKey,
      listFieldKey,
    );
  }
  refreshPositionsInList(rootFieldKey = null, listFieldKey = null) {
    if (!rootFieldKey || !listFieldKey) return;
    const list = this[rootFieldKey][listFieldKey];
    if (!Array.isArray(list)) return;
    if (!list?.length) return;
    if (!('position' in list[0])) return;

    // sort array by position (ascending) and update position of each listitem..
    list.sort((a, b) => {
      const aVal = a.position;
      const bVal = b.position;
      return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
    });

    list.forEach((option, index) => {
      option.position = index;
    });
  }
}

class WelcomeJourneyStep extends JourneyStep {
  constructor(content = {}, design = {}) {
    const defaultContent = {
      heading: '',
      description: '',
      beginEmail: true,
      collectEmail: true, // fixed
      collectEmailMandatory: true, // fixed
      collectLocation: true,
      collectLocationMandatory: true,
      collectName: true,
      collectNameMandatory: false,
      collectPhoneNumber: false,
      collectPhoneNumberMandatory: false,
      collectDeliveryAddress: false,
      collectDeliveryAddressMandatory: false,
      collectDateOfBirth: false,
      collectDateOfBirthMandatory: false,
      dobAgeLimit: 13,
      dobFormatDmy: true,
      termsLabel: '', // metaData.welcomeTermsLabel
      termsText: '', // metaData.welcomeTerms
    };
    const defaultDesign = {};
    super('welcome', { ...defaultContent, ...content }, { ...defaultDesign, ...design });
    this.id = 'first';
    this.status = 'locked';
  }
}

// TODO - ThanksJourneyStep and ExtraJourneyStep are now almost the same?
// TODO - so let's create a superclass for them? call it BundledJourneyStep?
class ThanksJourneyStep extends ListJourneyStep {
  constructor(content = {}, design = {}) {
    const defaultContent = {
      heading: '',
      successfulText: '',
      linkToFanpage: false,
      linkToFanpageLabel: '',

      hideLinks: false,
      linksUsed: false,
      links: [
        {
          id: uuidv4(),
          position: 0,
          url: '',
          label: '',
          image: '',
        },
      ],

      // VIDEO
      videoUsed: false,
      videoUrl: '',
      videoType: '', // upload / youtube / vimeo
      videoOnlyOnce: false,
      roundedCorners: false,
      borderStyle: '',

      // GIVEAWAY
      giveawayUsed: false,
      giveawayUrl: '',
      giveawayLabel: '',
      giveawayImage: '',

      audioUsed: false,
      tracks: [
        {
          id: uuidv4(),
          position: 0,
          url: '',
          label: '',
          image: '',
        },
      ],
    };
    const defaultDesign = {};
    super('thanks', null, { ...defaultContent, ...content }, { ...defaultDesign, ...design });
    this.id = 'last';
    this.status = 'locked';
  }

  get sortedLinks() {
    const list = JSON.parse(JSON.stringify(this.content.links));
    list.sort((a, b) => a.position - b.position);
    return list;
  }

  get sortedTracks() {
    const list = JSON.parse(JSON.stringify(this.content.tracks));
    list.sort((a, b) => a.position - b.position);
    return list;
  }

  createContentListItem(
    id = uuidv4(),
    title = '',
    url = '',
    label = '',
    description = '',
    image = '',
    type = 'links',
  ) {
    const newListItem = {
      id: id || uuidv4(),
      position: this.content.links.length || 0,
      title,
      url,
      label,
      description,
      image,
    };
    super.createContentListItem(newListItem, type);
  }
  readContentListItem(id, type = 'links') {
    return super.readContentListItem(id, type);
  }
  updateContentListItem(id, fieldKey, value, type = 'links') {
    super.updateContentListItem(id, type, fieldKey, value);
  }
  deleteContentListItem(id, type = 'links') {
    super.deleteContentListItem(id, type);
  }
  moveContentListItem(id, movement = 1, type = 'links') {
    super.moveContentListItem(id, movement, type);
  }
  cloneContentListItem(id, type = 'links') {
    const optionToClone = this.readContentListItem(id, type);
    if (!optionToClone) return;
    const overrideFields = {}; // anything to override?
    super.cloneContentListItem(id, overrideFields, type);
  }
}

class ExtraJourneyStep extends ListJourneyStep {
  constructor(content = {}, design = {}, status = null) {
    const defaultContent = {
      heading: '',
      text: '',
      linkToFanpage: false,
      linkToFanpageLabel: '',

      linksUsed: false,
      links: [
        {
          id: uuidv4(),
          position: 0,
          url: '',
          label: '',
          image: '',
        },
      ],

      videoUsed: false,
      videoUrl: '', // metaData.pageVideoUrl
      videoType: '', // upload / youtube / vimeo
      videoOnlyOnce: false,
      roundedCorners: false,
      borderStyle: '',

      giveawayUsed: false,
      giveawayUrl: '',
      giveawayLabel: '',
      giveawayImage: '',

      audioUsed: false,
      tracks: [
        {
          id: uuidv4(),
          position: 0,
          url: '',
          label: '',
          image: '',
        },
      ],
    };
    const defaultDesign = {};
    super('extra', null, { ...defaultContent, ...content }, { ...defaultDesign, ...design });
    this.id = 'extra';
    this.status = status || 'live';
  }

  get sortedLinks() {
    const list = JSON.parse(JSON.stringify(this.content.links));
    list.sort((a, b) => a.position - b.position);
    return list;
  }
  get sortedTracks() {
    const list = JSON.parse(JSON.stringify(this.content.tracks));
    list.sort((a, b) => a.position - b.position);
    return list;
  }

  createContentListItem(
    id = uuidv4(),
    title = '',
    url = '',
    label = '',
    description = '',
    image = '',
    type = 'links',
  ) {
    const newListItem = {
      id: id || uuidv4(),
      position: this.content.links.length || 0,
      title,
      url,
      label,
      description,
      image,
    };
    super.createContentListItem(newListItem, type);
  }
  readContentListItem(id, type = 'links') {
    return super.readContentListItem(id, type);
  }
  updateContentListItem(id, fieldKey, value, type = 'links') {
    super.updateContentListItem(id, type, fieldKey, value);
  }
  deleteContentListItem(id, type = 'links') {
    super.deleteContentListItem(id, type);
  }
  moveContentListItem(id, movement = 1, type = 'links') {
    super.moveContentListItem(id, movement, type);
  }
  cloneContentListItem(id, type = 'links') {
    const optionToClone = this.readContentListItem(id, type);
    if (!optionToClone) return;
    const overrideFields = {}; // anything to override?
    super.cloneContentListItem(id, overrideFields, type);
  }
}

class PresaveJourneyStep extends InbetweenJourneyStep {
  constructor(position, content, design, id = null, status = null) {
    const defaultContent = {
      heading: '',
      description: '',
      spotify: true,
      labelSpotify: 'Pre-save on Spotify',
      appleMusic: true,
      labelAppleMusic: 'Pre-add on Apple Music',
      deezer: true,
      labelDeezer: 'Presave on Deezer',
      allowSkip: true,
      hideLinks: false,
      linksHeading: '',
      linksDescription: '',
      linkAmazonMusic: '',
      linkYoutubeMusic: '',
      linkSoundcloud: '',
      linkTidal: '',
      linkAnghami: '',
      linkPandora: '',
      linkQobuz: '',
      linkAudiomack: '',
      linkOther: '',
      linkOtherLabel: '',
    };
    const defaultDesign = {};
    super(
      'presave',
      position,
      { ...defaultContent, ...content },
      { ...design, ...defaultDesign },
      id,
      status,
    );
  }
}
class PollJourneyStep extends ListJourneyStep {
  constructor(position, content, design, id = null, status = null) {
    const defaultContent = {
      heading: '',
      question: '',
      version: '1.0',
      pollId: '', // link to Poll DB object
      options: [
        {
          pollId: '', // link to Poll DB object
          pollAnswerId: '', // link to PollAnswer DB object
          id: uuidv4(),
          position: 0,
          value: '',
          file: '',
          tag: '', // should be gone?
          image: '',
        },
      ],
      singleChoice: false,
      allowSkip: true,
      useDropdown: true,
    };
    const defaultDesign = {};
    super(
      'poll',
      position,
      {
        ...defaultContent,
        ...{
          ...content,
          options: (content?.options || []).map((m2) => ({
            ...m2,
            image: m2.image || '',
          })),
        },
      },
      { ...design, ...defaultDesign },
      id,
      status,
    );
  }
  get sortedOptions() {
    const list = JSON.parse(JSON.stringify(this.content.options));
    list.sort((a, b) => a.position - b.position);
    return list;
  }

  createContentListItem(id = uuidv4(), value = '', file = '', tag = '', image = '') {
    const newListItem = {
      id: id || uuidv4(),
      pollAnswerId: '', // link to PollAnswer DB object
      position: this.content.options.length,
      value,
      file,
      tag, // not needed anymore?
      image,
    };
    super.createContentListItem(newListItem, 'options');
  }
  readContentListItem(id) {
    return super.readContentListItem(id, 'options');
  }
  updateContentListItem(id, fieldKey, value) {
    super.updateContentListItem(id, 'options', fieldKey, value);
  }
  deleteContentListItem(id) {
    super.deleteContentListItem(id, 'options');
  }
  moveContentListItem(id, movement = 1) {
    super.moveContentListItem(id, movement, 'options');
  }
  cloneContentListItem(id) {
    const optionToClone = this.readContentListItem(id);
    if (!optionToClone) return;
    const overrideFields = {
      tag: optionToClone.tag ? `${optionToClone.tag}_copy` : '',
      pollAnswerId: '',
    };
    super.cloneContentListItem(id, overrideFields, 'options');
  }
  sortContentList() {
    super.sortContentList('options', 'value');
  }
}
class LinksJourneyStep extends ListJourneyStep {
  constructor(position, content, design, id = null, status = null) {
    const defaultContent = {
      heading: '',
      description: '',
      links: [
        {
          id: uuidv4(),
          position: 0,
          url: '',
          label: '',
          image: '',
        },
      ],
    };
    const defaultDesign = {};
    super(
      'links',
      position,
      { ...defaultContent, ...content },
      { ...design, ...defaultDesign },
      id,
      status,
    );
  }

  get sortedLinks() {
    const list = JSON.parse(JSON.stringify(this.content.links));
    list.sort((a, b) => a.position - b.position);
    return list;
  }

  createContentListItem(
    id = uuidv4(),
    title = '',
    url = '',
    label = '',
    description = '',
    image = '',
  ) {
    const newListItem = {
      id: id || uuidv4(),
      position: this.content.links.length,
      title,
      url,
      label,
      description,
      image,
    };
    super.createContentListItem(newListItem, 'links');
  }
  readContentListItem(id) {
    return super.readContentListItem(id, 'links');
  }
  updateContentListItem(id, fieldKey, value) {
    super.updateContentListItem(id, 'links', fieldKey, value);
  }
  deleteContentListItem(id) {
    super.deleteContentListItem(id, 'links');
  }
  moveContentListItem(id, movement = 1) {
    super.moveContentListItem(id, movement, 'links');
  }
  cloneContentListItem(id) {
    const optionToClone = this.readContentListItem(id);
    if (!optionToClone) return;
    const overrideFields = {}; // anything to override?
    super.cloneContentListItem(id, overrideFields, 'links');
  }
}
class UploadJourneyStep extends InbetweenJourneyStep {
  constructor(position, content, design, id = null, status = null) {
    const defaultContent = {
      heading: '',
      uploadNeeded: true,
      uploadTitle: '',
      storyNeeded: false,
      storyTitle: '',
      storyInputLabel: '',
      storyLimit: '',
      disclaimer: '',
      termsLabel: undefined,
      terms: '',
      nextLabel: '',
      continueButtonLabel: '',
      continueText: '',
      tag: '',
      allowSkip: true,
    };
    const defaultDesign = {};
    super(
      'upload',
      position,
      { ...defaultContent, ...content },
      { ...design, ...defaultDesign },
      id,
      status,
    );
  }
}
class SocialHandlesStep extends InbetweenJourneyStep {
  constructor(position, content, design, id = null, status = null) {
    const defaultContent = {
      heading: '',
      callToAction: '',
      allowSkip: true,
      collectInstagram: false,
      collectTiktok: false,
      collectTwitter: false,
      collectYoutube: false,
      collectFacebook: false,
    };
    const defaultDesign = {};
    super(
      'socials',
      position,
      { ...defaultContent, ...content },
      { ...design, ...defaultDesign },
      id,
      status,
    );
  }
}
class VideoPlayerJourneyStep extends InbetweenJourneyStep {
  constructor(position, content, design, id = null, status = null) {
    const defaultContent = {
      heading: '',
      description: '',
      type: '', // youtube / vimeo / upload
      url: '',
      roundedCorners: false,
      borderStyle: '',
      onlyOnce: false,
    };
    const defaultDesign = {};
    super(
      'videoPlayer',
      position,
      { ...defaultContent, ...content },
      { ...design, ...defaultDesign },
      id,
      status,
    );
  }
}
class AudioPlayerJourneyStep extends ListJourneyStep {
  constructor(position, content, design, id = null, status = null) {
    const defaultContent = {
      heading: '',
      description: '',
      onlyOnce: false,
      tracks: [
        {
          id: uuidv4(),
          position: 0,
          url: '',
          label: '',
          image: '',
        },
      ],
    };
    const defaultDesign = {};
    super(
      'audioPlayer',
      position,
      { ...defaultContent, ...content },
      { ...design, ...defaultDesign },
      id,
      status,
    );
  }

  get sortedTracks() {
    const list = JSON.parse(JSON.stringify(this.content.tracks));
    list.sort((a, b) => a.position - b.position);
    return list;
  }

  createContentListItem(id = uuidv4(), url = '', label = '', image = '') {
    const newListItem = {
      id: id || uuidv4(),
      position: this.content.tracks.length,
      url,
      label,
      image,
    };
    super.createContentListItem(newListItem, 'tracks');
  }
  readContentListItem(id) {
    return super.readContentListItem(id, 'tracks');
  }
  updateContentListItem(id, fieldKey, value) {
    super.updateContentListItem(id, 'tracks', fieldKey, value);
  }
  deleteContentListItem(id) {
    super.deleteContentListItem(id, 'tracks');
  }
  moveContentListItem(id, movement = 1) {
    super.moveContentListItem(id, movement, 'tracks');
  }
}
class DownloadJourneyStep extends ListJourneyStep {
  constructor(position, content, design, id = null, status = null) {
    const defaultContent = {
      heading: '',
      description: '',
      downloadables: [
        {
          id: uuidv4(),
          position: 0,
          url: '',
          label: '',
          image: '',
        },
      ],
    };
    const defaultDesign = {};
    super(
      'download',
      position,
      { ...defaultContent, ...content },
      { ...design, ...defaultDesign },
      id,
      status,
    );
  }

  get sortedDownloadables() {
    const list = JSON.parse(JSON.stringify(this.content.downloadables));
    list.sort((a, b) => a.position - b.position);
    return list;
  }

  createContentListItem(id = uuidv4(), title = '', url = '', label = '', description = '') {
    const newListItem = {
      id: id || uuidv4(),
      position: this.content.downloadables.length,
      title,
      url,
      label,
      description,
    };
    super.createContentListItem(newListItem, 'downloadables');
  }
  readContentListItem(id) {
    return super.readContentListItem(id, 'downloadables');
  }
  updateContentListItem(id, fieldKey, value) {
    super.updateContentListItem(id, 'downloadables', fieldKey, value);
  }
  deleteContentListItem(id) {
    super.deleteContentListItem(id, 'downloadables');
  }
  moveContentListItem(id, movement = 1) {
    super.moveContentListItem(id, movement, 'downloadables');
  }
  cloneContentListItem(id) {
    const optionToClone = this.readContentListItem(id);
    if (!optionToClone) return;
    const overrideFields = {}; // anything to override?
    super.cloneContentListItem(id, overrideFields, 'downloadables');
  }
}
class WhereShouldWePlayJourneyStep extends InbetweenJourneyStep {
  constructor(position, content, design, id = null, status = null) {
    const defaultContent = {
      heading: '',
      description: '',
      allowSkip: true,
    };
    const defaultDesign = {};
    super(
      'whereShouldWePlay',
      position,
      { ...defaultContent, ...content },
      { ...design, ...defaultDesign },
      id,
      status,
    );
  }
}
class TextJourneyStep extends InbetweenJourneyStep {
  constructor(position, content, design, id = null, status = null) {
    const defaultContent = {
      heading: '',
      description: '',
    };
    const defaultDesign = {};
    super(
      'text',
      position,
      { ...defaultContent, ...content },
      { ...design, ...defaultDesign },
      id,
      status,
    );
  }
}
class NumericSliderJourneyStep extends InbetweenJourneyStep {
  constructor(position, content, design, id = null, status = null) {
    const defaultContent = {
      heading: '',
      description: '',
      min: 0,
      max: 100,
      step: 1,
      suggested: 0,
      isMoney: false,
      allowSkip: true,
    };
    const defaultDesign = {};
    super(
      'numericSlider',
      position,
      { ...defaultContent, ...content },
      { ...design, ...defaultDesign },
      id,
      status,
    );
  }
}

export class JourneySteps {
  constructor(jsObject = null) {
    if (!jsObject) {
      this.first = new WelcomeJourneyStep();
      this.last = new ThanksJourneyStep();
      this.extra = new ExtraJourneyStep();
      this.steps = [];
    } else {
      this.fromJsObject(jsObject);
    }
  }

  get sortedSteps() {
    const list = JSON.parse(JSON.stringify(this.steps));
    list.sort((a, b) => a.position - b.position);
    return list;
  }

  getStep(id) {
    return this[id] || this.steps.find((f) => f.id === id) || null;
  }

  addStep(type, position) {
    let newStep = null;
    const newPosition = position + 1;
    switch (type) {
      case 'presave':
        newStep = new PresaveJourneyStep(newPosition);
        break;
      case 'poll':
        newStep = new PollJourneyStep(
          newPosition,
          featureFlags.pollVersion2 ? { version: '2.0' } : {},
        );
        break;
      case 'links':
        newStep = new LinksJourneyStep(newPosition);
        break;
      case 'upload':
        newStep = new UploadJourneyStep(newPosition);
        break;
      case 'videoPlayer':
        newStep = new VideoPlayerJourneyStep(newPosition);
        break;
      case 'audioPlayer':
        newStep = new AudioPlayerJourneyStep(newPosition);
        break;
      case 'download':
        newStep = new DownloadJourneyStep(newPosition);
        break;
      case 'socials':
        newStep = new SocialHandlesStep(newPosition);
        break;
      case 'extra':
        newStep = new ExtraJourneyStep(newPosition);
        break;
      case 'whereShouldWePlay':
        newStep = new WhereShouldWePlayJourneyStep(newPosition);
        break;
      case 'text':
        newStep = new TextJourneyStep(newPosition);
        break;
      case 'numericSlider':
        newStep = new NumericSliderJourneyStep(newPosition);
        break;
      default:
        return;
    }

    this.steps.forEach((step) => {
      if (step.position >= newPosition) {
        step.position += 1;
      }
    });
    this.steps.push(newStep);
  }

  removeStep(stepId) {
    const index = this.steps.findIndex((f) => f.id === stepId);
    if (index < 0) return; // not found
    const stepPos = this.steps[index].position;
    this.steps.splice(index, 1);
    this.steps.forEach((step) => {
      if (step.position > stepPos) {
        step.position -= 1;
      }
    });
  }

  moveStep(id, movement = 1) {
    const step = this.steps.find((f) => f.id === id);
    if (!step) return;
    const posA = step.position;
    if (posA < 0) return; // not found
    const edge = movement < 0 ? 0 : this.steps.length;
    if (posA === edge) return; // already at the edge

    const oldStepInThisPos = this.steps.find((f) => f.position === posA + movement);
    if (!oldStepInThisPos) return; // not found
    step.position = posA + movement;
    oldStepInThisPos.position = posA;
  }

  switchStatus(id, status) {
    const step = id === 'extra' ? this.extra : this.steps.find((f) => f.id === id);
    if (!step) return;
    step.status = status;
  }

  clone() {
    const clonedJourney = new JourneySteps();
    clonedJourney.steps = this.steps.map((step) => {
      const { id, type, status, position, content, design } = step;
      return new JourneyStep(type, position, content, design, id, status); // FIXME ??
    });
    return clonedJourney;
  }

  getContentField(id, key) {
    const step = this.getStep(id);
    if (!step) return null;
    return step.content[key] || null;
  }
  updateContentField(id, key, value) {
    const step = this.getStep(id);
    if (!step) return;
    step.updateContentField(key, value);
  }

  toJsObject() {
    return JSON.parse(JSON.stringify(this));
  }

  fromJsObject(jsObject) {
    const { first, last, extra, steps } = jsObject;
    this.first = new WelcomeJourneyStep(first?.content || {}, first?.design || {});
    this.last = new ThanksJourneyStep(last?.content || {}, last?.design || {});
    this.extra = new ExtraJourneyStep(
      extra?.content || {},
      extra?.design || {},
      extra?.status || null,
    );

    this.steps =
      steps
        .map((step) => {
          const { position, content, design, id, status } = step;
          // console.table(step);
          switch (step.type) {
            case 'presave':
              return new PresaveJourneyStep(position, content, design, id, status);
            case 'poll':
              return new PollJourneyStep(position, content, design, id, status);
            case 'links':
              return new LinksJourneyStep(position, content, design, id, status);
            case 'upload':
              return new UploadJourneyStep(position, content, design, id, status);
            case 'videoPlayer':
              return new VideoPlayerJourneyStep(position, content, design, id, status);
            case 'audioPlayer':
              return new AudioPlayerJourneyStep(position, content, design, id, status);
            case 'download':
              return new DownloadJourneyStep(position, content, design, id, status);
            case 'socials':
              return new SocialHandlesStep(position, content, design, id, status);
            case 'whereShouldWePlay':
              return new WhereShouldWePlayJourneyStep(position, content, design, id, status);
            case 'text':
              return new TextJourneyStep(position, content, design, id, status);
            case 'numericSlider':
              return new NumericSliderJourneyStep(position, content, design, id, status);
            default:
              return null;
          }
        })
        .filter((f) => !!f) || [];
  }
}
