export const teamMembershipList = {
  namespaced: true,
  state: () => ({
    list: [],
    inProgress: false,
    error: null,
  }),
  mutations: {
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    setList(state, list) {
      state.list = list;
    },
  },
  actions: {},
};
