import {
  doRegenerateQRCode,
  doFetchQRCode,
  doGenerateQRCodePreview,
} from '@/services/agent/qr_code.service';
import { getField, updateField } from 'vuex-map-fields';
import { isEqual } from 'lodash';
import humps from 'lodash-humps';

const hasChangesHelper = (a, b) => {
  if (!a || !b) return true;
  return !isEqual(a, b);
};

export const emptyOne = {
  id: null,
  pageId: '',
  targetUrl: '',
  tag: '',
  centreImageUrl: null,
  lightColour: '#FFFFFF',
  darkColour: '#000000',
};

export const qrOne = {
  namespaced: true,
  state: () => ({
    one: { ...emptyOne },
    oneUpdated: { ...emptyOne },
    onePreview: { ...emptyOne },
    inProgress: false,
    error: null,
  }),
  getters: {
    getField,
    hasChanges: (state) => {
      return hasChangesHelper(
        {
          pageId: state?.one?.pageId || null,
          targetUrl: state?.one?.targetUrl || null,
          tag: state?.one?.tag,
          centreImageUrl: state?.one?.centreImageUrl,
          lightColour: state?.one?.lightColour,
          darkColour: state?.one?.darkColour,
        },
        {
          pageId: state?.oneUpdated?.pageId || null,
          targetUrl: state?.oneUpdated?.targetUrl || null,
          tag: state?.oneUpdated?.tag,
          centreImageUrl: state?.oneUpdated?.centreImageUrl,
          lightColour: state?.oneUpdated?.lightColour,
          darkColour: state?.oneUpdated?.darkColour,
        },
      );
    },
    hasChangesPreview: (state) => {
      return hasChangesHelper(
        {
          centreImageUrl: state?.onePreview?.centreImageUrl,
          lightColour: state?.onePreview?.lightColour,
          darkColour: state?.onePreview?.darkColour,
        },
        {
          centreImageUrl: state?.oneUpdated?.centreImageUrl,
          lightColour: state?.oneUpdated?.lightColour,
          darkColour: state?.oneUpdated?.darkColour,
        },
      );
    },
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    error(state, error = null) {
      state.error = error;
    },
    one(state, one = { ...emptyOne }) {
      state.one = one;
    },
    oneUpdated(state, one = { ...emptyOne }) {
      state.oneUpdated = one;
    },
    onePreview(state, one = { ...emptyOne }) {
      state.onePreview = one;
    },
  },
  actions: {
    fetchOne: async ({ commit, dispatch }, data) => {
      const { artistId, pageId } = data;
      if (!artistId || !pageId) {
        console.log('No artistId or pageId');
        commit('error', 'Could not load QR code, please retry');
        return;
      }
      commit('inProgress', true);
      try {
        const fetchedOne = await doFetchQRCode(artistId, pageId);
        if (fetchedOne && fetchedOne[0]) {
          const firstOne = humps(fetchedOne[0]);
          firstOne.lightColour = firstOne.lightColour || '#FFFFFF';
          firstOne.darkColour = firstOne.darkColour || '#000000';
          dispatch('setOne', firstOne);
        } else dispatch('resetOne');
      } finally {
        commit('inProgress', false);
      }
    },
    setOne: ({ commit }, one) => {
      commit('error', null);
      commit('one', one);
      commit('oneUpdated', JSON.parse(JSON.stringify(one)));
      commit('onePreview', JSON.parse(JSON.stringify(one)));
    },
    revertOne: ({ dispatch, state }) => {
      dispatch('setOne', state.one);
    },
    resetOne: ({ commit }) => {
      commit('error', null);
      commit('one');
      commit('oneUpdated');
      commit('onePreview');
    },
    saveOne: async ({ commit, dispatch, state, rootState }) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id');
        return;
      }
      commit('inProgress', true);
      let success = true;
      try {
        const response = await doRegenerateQRCode(
          rootState.artistOne.one.id,
          state.oneUpdated.id || null,
          state.oneUpdated.centreImageUrl || null,
          state.oneUpdated.pageId || null,
          state.oneUpdated.targetUrl || null,
          20, // margin, fixed
          state.oneUpdated.tag || null,
          state.oneUpdated.lightColour || null,
          state.oneUpdated.darkColour || null,
        );
        if (!response?.id) throw new Error('failed save');
        dispatch('setOne', humps(response));
      } catch (err) {
        commit('error', 'Could not save QR code, please retry');
        success = false;
      } finally {
        commit('inProgress', false);
      }
      return success;
    },
    previewOne: async ({ commit, state, rootState }) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id');
        return;
      }
      commit('inProgress', true);
      let success = true;
      try {
        const response = await doGenerateQRCodePreview(
          rootState.artistOne.one.id,
          state.oneUpdated.centreImageUrl || null,
          state.oneUpdated.pageId || null,
          state.oneUpdated.targetUrl || null,
          20, // margin, fixed
          state.oneUpdated.tag || null,
          state.oneUpdated.lightColour || null,
          state.oneUpdated.darkColour || null,
        );
        const text = await response.text();
        commit('onePreview', {
          ...state.oneUpdated,
          cloudinaryUrl: `data:image/png;base64,${text}`,
        });
      } catch (err) {
        commit('error', 'Could not generate QR code preview, please retry');
        success = false;
      } finally {
        commit('inProgress', false);
      }
      return success;
    },
  },
};
