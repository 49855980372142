import { doFetchList as doFetchAccountManagersList } from '@/services/agent/account_managers.service';
import { getField, updateField } from 'vuex-map-fields';

export const accountManagers = {
  namespaced: true,
  state: () => ({
    accountManagers: [],
    accountManagersError: null,
    accountManagersFetching: false,
  }),
  getters: {
    getField,
    accountManagersSortedWithAll: (state, getters) => {
      const ams = JSON.parse(
        JSON.stringify([
          {
            fanId: null,
            fullName: 'All',
          },
          ...(getters.accountManagersSorted || []),
        ]),
      );
      return ams;
    },
    accountManagersSortedWithAllAndUnassigned: (state, getters) => {
      const ams = JSON.parse(
        JSON.stringify([
          {
            fanId: null,
            fullName: 'All',
          },
          {
            fanId: 'unassigned',
            fullName: 'Unassigned',
          },
          ...(getters.accountManagersSorted || []),
        ]),
      );
      return ams;
    },
    accountManagersSorted: (state) => {
      const ams = JSON.parse(JSON.stringify(state.accountManagers));
      return ams.sort((a, b) => {
        const nameA = a.fullName?.toLowerCase();
        const nameB = b.fullName?.toLowerCase();
        return nameA > nameB ? 1 : nameA === nameB ? 0 : -1;
      });
    },
  },
  mutations: {
    updateField,
    accountManagers(state, val = []) {
      state.accountManagers = (val || []).filter((f) => !!f.fullName);
    },
    accountManagersError(state, val = '') {
      state.accountManagersError = val;
    },
    accountManagersFetching(state, val = false) {
      state.accountManagersFetching = val;
    },
  },
  actions: {
    fetchAccountManagersList: async ({ commit }) => {
      try {
        commit('accountManagersFetching', true);
        commit('accountManagersError');
        const accountManagersResponse = await doFetchAccountManagersList();
        if (accountManagersResponse && accountManagersResponse?.status !== 'ERROR') {
          commit('accountManagers', accountManagersResponse);
        } else {
          throw new Error(
            accountManagersResponse?.message || 'Could not load account managers list',
          );
        }
      } catch (error) {
        commit('accountManagersError', 'Could not load account managers list');
      } finally {
        commit('accountManagersFetching');
      }
    },
  },
};
