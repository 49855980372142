export const gettingStarted = {
  namespaced: true,
  state: () => ({
    inProgress: false,
    path: '',
    menu: '',
    step: 0,
  }),
  mutations: {
    start(state, value) {
      state.inProgress = true;
      state.path = value;
      state.step = 1;
      state.ended = false;
    },
    openMenu(state, value) {
      state.menu = value;
    },
    end(state) {
      if (!state.ended) {
        state.inProgress = false;
        //We create a timer here to decide if the user is navigating away or is still running the tutorial
        setTimeout(() => {
          if (!state.inProgress) {
            state.ended = true;
          }
        }, 500);
      }
    },
    setPath(state, value) {
      state.path = value;
    },
    setStep(state, value) {
      if (!state.ended) {
        state.step = value;
        state.inProgress = true;
      }
    },
  },
  actions: {
    start({ commit }, type) {
      commit('start', type);
    },
    openMenu({ commit }, type) {
      commit('openMenu', type);
    },
    end({ commit }) {
      commit('end');
    },
    setPath({ commit }, path) {
      commit('setPath', path);
    },
    setStep({ commit }, step) {
      commit('setStep', step);
    },
  },
};
