import { authHeader, openstageApi2Url } from '../auth';
import { wrapRequest } from '../nav';

export const doFetchList = wrapRequest(() => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  };
  var url = `${openstageApi2Url}/accountManagers`;
  return fetch(url, requestOptions);
});
