import { computed, ref, type ComputedRef, type InjectionKey, type WritableComputedRef } from 'vue';
import { normalizeFilterWithDefaults } from '@/components-new/modules/AudienceBuilder/AudienceFilter/AudienceFilter.meta';
import type {
  AudienceCriteria,
  AudienceCriteriaFilter,
  AudienceCriteriaFilterGroup,
  AudienceCriteriaFilterGroupAnd,
  AudienceCriteriaOrder,
} from '@/interfaces/AudienceCriteria.interface';

export const getDefaultAudienceCriteria = () => ({
  version: '1.0' as const,
  // limit: 1000,
  // order: 'engagementScore',
  filters: [],
});

export const parseNewCriteria = (criteria: string | null) => {
  if (!criteria) return null;
  try {
    const parsed = JSON.parse(criteria);
    return {
      ...parsed,
      filters: parsed.filters.map((m: AudienceCriteriaFilter | AudienceCriteriaFilterGroup) =>
        normalizeFilterWithDefaults(m),
      ),
    };
  } catch (error) {
    return null;
  }
};

export interface IFilterUpdatePosition {
  i: number;
  j?: number;
  k?: number;
}

export function useCriteriaComposable(
  criteria: WritableComputedRef<AudienceCriteria | null> | ComputedRef<AudienceCriteria | null>,
  criteriaUpdated:
    | WritableComputedRef<AudienceCriteria | null>
    | ComputedRef<AudienceCriteria | null>,
) {
  const limit = computed(() => criteria?.value?.limit || null);
  const limitUpdated = computed({
    get() {
      return criteriaUpdated.value?.limit || null;
    },
    set(limit: number | null) {
      if (!criteriaUpdated.value) return;
      criteriaUpdated.value.limit = limit || undefined;
    },
  });
  const limitHasChanged = computed(() => limit.value !== limitUpdated.value);
  const updateLimit = (limit: number | null) => {
    if (!criteriaUpdated.value) return;
    limitUpdated.value = limit || null;
  };

  const order = computed(() => criteria?.value?.order || null);
  const orderUpdated = computed({
    get() {
      return criteriaUpdated.value?.order || null;
    },
    set(order: AudienceCriteriaOrder | null) {
      if (!criteriaUpdated.value) return;
      criteriaUpdated.value.order = order;
    },
  });
  const orderHasChanged = computed(() => order.value !== orderUpdated.value);
  const updateOrder = (order: AudienceCriteriaOrder | null) => {
    if (!criteriaUpdated.value) return;
    orderUpdated.value = order || null;
  };

  const filters = computed(
    () =>
      criteria?.value?.filters?.map((m: AudienceCriteriaFilter | AudienceCriteriaFilterGroup) =>
        normalizeFilterWithDefaults(m),
      ) || [],
  );
  const filtersUpdated = computed({
    get() {
      return criteriaUpdated.value?.filters || [];
    },
    set(filters: (AudienceCriteriaFilter | AudienceCriteriaFilterGroup)[]) {
      if (!criteriaUpdated.value) return;
      criteriaUpdated.value.filters = filters;
    },
  });
  const filtersHaveChanged = computed(
    () => JSON.stringify(filters.value) !== JSON.stringify(filtersUpdated.value),
  );

  const hasChanged = computed(
    () => filtersHaveChanged.value || limitHasChanged.value || orderHasChanged.value,
  );

  const addPosition = ref<IFilterUpdatePosition | null>(null);
  const markAddPosition = (position: IFilterUpdatePosition | null) => {
    addPosition.value = position || null;
  };
  const addFilter = (filter: AudienceCriteriaFilter) => {
    if (!filter) return;
    addFilterToPosition(filter);
    markAddPosition(null);
  };
  const addFilterToPosition = (filter: AudienceCriteriaFilter) => {
    if (!filtersUpdated.value) filtersUpdated.value = [];
    const i = addPosition.value ? addPosition.value.i : filtersUpdated.value?.length - 1;
    const j = addPosition.value ? addPosition.value.j : undefined;
    const k = addPosition.value ? addPosition.value.k : undefined;

    const filters = filtersUpdated.value;
    if (j !== undefined && k !== undefined) {
      const rootFilter = filters[i] as AudienceCriteriaFilterGroup;
      const existingFilter = rootFilter.filters[j] as
        | AudienceCriteriaFilter
        | AudienceCriteriaFilterGroup;
      if (existingFilter.type !== 'group') {
        const andGroup = {
          type: 'group' as const,
          condition: 'and' as const,
          filters: [existingFilter] as AudienceCriteriaFilter[],
        };
        andGroup.filters.splice(k + 1, 0, filter);
        rootFilter.filters[j] = andGroup;
      } else existingFilter.filters.splice(k + 1, 0, filter);
      return;
    }
    if (j !== undefined) {
      const existingFilter = filters[i] as AudienceCriteriaFilter | AudienceCriteriaFilterGroup;
      if (existingFilter.type !== 'group') {
        const orGroup = {
          type: 'group' as const,
          condition: 'or' as const,
          filters: [
            existingFilter,
            filter, // always adding to end pos
          ] as (AudienceCriteriaFilter | AudienceCriteriaFilterGroupAnd)[],
        };
        filters[i] = orGroup;
      } else existingFilter.filters.splice(j + 1, 0, filter);
      return;
    }
    filters.splice(i + 1, 0, filter);
  };
  const removeFilterFromPosition = (position?: IFilterUpdatePosition | null) => {
    if (!filtersUpdated.value) return;
    const i = position ? position.i : filtersUpdated.value?.length - 1;
    const j = position ? position.j : undefined;
    const k = position ? position.k : undefined;
    if (j !== undefined && k !== undefined) {
      const rootFilter = filtersUpdated.value[i] as AudienceCriteriaFilterGroup;
      const existingFilter = rootFilter.filters[j] as AudienceCriteriaFilterGroup;
      existingFilter.filters.splice(k, 1);
      if (existingFilter.filters.length === 1) rootFilter.filters[j] = existingFilter.filters[0];
      return;
    }
    if (j !== undefined) {
      const rootFilter = filtersUpdated.value[i] as AudienceCriteriaFilterGroup;
      rootFilter.filters.splice(j, 1);
      if (rootFilter.filters.length === 1) {
        if (rootFilter.filters[0].type === 'group')
          filtersUpdated.value.splice(i, 1, ...rootFilter.filters[0].filters);
        else filtersUpdated.value[i] = rootFilter.filters[0];
      }
      return;
    }
    if (i < 0 || i >= filtersUpdated.value.length) return;
    filtersUpdated.value.splice(i, 1);
  };
  const updateFilter = (filter: AudienceCriteriaFilter, update: AudienceCriteriaFilter) => {
    if (!filter) return;
    if (filter.exists !== undefined) filter.exists = update.exists;
    if (filter.condition !== undefined) filter.condition = update.condition;
    if (filter.fields !== undefined) {
      filter.fields.forEach((f) => {
        const fieldInUpdate = update?.fields?.find((f2) => f2.name === f.name);
        if (!fieldInUpdate) return;
        f.value = fieldInUpdate.value;
      });
    }
  };
  const clear = () => {
    limitUpdated.value = null;
    orderUpdated.value = null;
    filtersUpdated.value = [];
  };

  return {
    criteria, // saved
    criteriaUpdated, // updated
    filters,
    filtersUpdated,
    filtersHaveChanged,
    addPosition,
    markAddPosition,
    addFilter,
    updateFilter,
    removeFilterFromPosition,
    clear,
    limit,
    limitUpdated,
    limitHasChanged,
    updateLimit,
    order,
    orderUpdated,
    orderHasChanged,
    updateOrder,
    hasChanged,
  };
}

export const criteriaInjectionKey = Symbol() as InjectionKey<
  | {
      criteria: AudienceCriteria | null;
      criteriaUpdated: AudienceCriteria | null;
      filters: (
        | AudienceCriteriaFilter
        | AudienceCriteriaFilterGroup
        | AudienceCriteriaFilterGroupAnd
      )[];
      filtersUpdated: (
        | AudienceCriteriaFilter
        | AudienceCriteriaFilterGroup
        | AudienceCriteriaFilterGroupAnd
      )[];
      filtersHaveChanged: boolean;
      addPosition: IFilterUpdatePosition | null;
      markAddPosition: (position: IFilterUpdatePosition | null) => void;
      addFilter: (filter: AudienceCriteriaFilter) => void;
      updateFilter: (filter: AudienceCriteriaFilter, update: AudienceCriteriaFilter) => void;
      removeFilterFromPosition: (position?: IFilterUpdatePosition | null) => void;
      limit: number | null;
      limitUpdated: number | null;
      limitHasChanged: boolean;
      updateLimit: (limit: number | null) => void;
      order: AudienceCriteriaOrder | null;
      orderUpdated: AudienceCriteriaOrder | null;
      orderHasChanged: boolean;
      updateOrder: (order: AudienceCriteriaOrder | null) => void;
      hasChanged: boolean;
    }
  | undefined
>;
