//import { organizationService } from 'vuex-suitcase';
import { doDeleteSelected, doFetchList, doLookup } from '@/services/agent/organization.service';
import humps from 'lodash-humps';

export const organizationList = {
  namespaced: true,
  state: () => ({
    list: [],
    recordsCount: 0,
    inProgress: false,
    error: null,
  }),
  mutations: {
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    setList(state, list) {
      state.list = list;
    },
    setListCount(state, number) {
      state.recordsCount = number;
    },
  },
  actions: {
    fetchList: async ({ state, commit, dispatch, rootState }, { appId, recursive }) => {
      commit('inProgress', true);
      const response = await doFetchList(appId);
      const list = await response.data;
      commit('setListCount', list.length);
      if (list) {
        commit('setList', humps(list));
      } else commit('setList', []);

      if (recursive && state.list[0].appId === rootState) {
        //rootState.appOne.one.id) {
        dispatch('appOne/fetchOne', state.list[0].appId, { root: true });
      }
      commit('inProgress', false);
    },
    lookUpList: async ({ commit }, { search, appId }) => {
      commit('inProgress', true);
      const response = await doLookup(search, appId);
      const list = await response.data;
      commit('setListCount', list.length);
      if (list) {
        commit('setList', humps(list));
      }
      commit('inProgress', false);
    },
    deleteSelected: async ({ commit }, organizationIds) => {
      commit('inProgress', true);
      try {
        let ids = organizationIds.map(function (organizationIds) {
          return organizationIds.id;
        });
        ids = ids.join();
        await doDeleteSelected(ids);
        return { deleteSelected: true };
      } catch (err) {
        throw 'error';
      } finally {
        commit('inProgress', false);
      }
    },
  },
};
