import { useToast } from 'vue-toastification';

const toasterOptionsDefault = {
  position: 'top-right',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  // icon: "mdi-",
  // rtl: false,
  toastClassName: ['openstage-toaster'],
};
export const toastification = useToast(toasterOptionsDefault);

export default {
  install: (app) => {
    app.config.globalProperties.$toastification = toastification;
  },
};
