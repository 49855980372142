import { authHeader, openstageApi2Url } from '../auth';
import { wrapRequest, wrapRequestNoError } from '../nav';

export const twilioListComplianceBundle = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId }),
  };
  return fetch(`${openstageApi2Url}/twilioListComplianceBundle`, requestOptions);
});

export const twilioCreateComplianceBundle = wrapRequest((artistId, type) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
      type,
    }),
  };
  return fetch(`${openstageApi2Url}/twilioCreateComplianceBundle`, requestOptions);
});

export const twilioUpdateComplianceBundle = wrapRequest((artistId, registrationId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
      registrationId,
    }),
  };
  return fetch(`${openstageApi2Url}/twilioUpdateComplianceBundle`, requestOptions);
});

export const twilioDeleteComplianceBundle = wrapRequest((artistId, sid) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
      sid,
    }),
  };
  return fetch(`${openstageApi2Url}/twilioDeleteComplianceBundle`, requestOptions);
});

export const twilioListTollFreeVerifications = wrapRequestNoError((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId }),
  };
  return fetch(`${openstageApi2Url}/twilioListTollFreeVerifications`, requestOptions);
});

export const twilioCreateTollFreeVerification = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
    }),
  };
  return fetch(`${openstageApi2Url}/twilioCreateTollFreeVerification`, requestOptions);
});

export const twilioDeleteTollFreeVerification = wrapRequest((artistId, sid) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
      sid,
    }),
  };
  return fetch(`${openstageApi2Url}/twilioDeleteTollFreeVerification`, requestOptions);
});
