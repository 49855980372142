import { authHeader, openstageApiCrudUrl } from '../auth';
import { wrapRequest } from '../nav';

const openstageApi2Url = import.meta.env.VITE_OPENSTAGE_API2_URL;

export const doFetchOne = wrapRequest((artistId, fanId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  };
  return fetch(
    `${openstageApiCrudUrl}/fan_artist?artist_id=eq.${artistId}&fan_id=eq.${fanId}`,
    requestOptions,
  );
});

export const doPostOne = wrapRequest((fanArtist) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(fanArtist),
  };
  return fetch(`${openstageApiCrudUrl}/fan_artist?id=eq.${fanArtist.id}`, requestOptions);
});

export const doPatchOne = wrapRequest((id, change) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(change),
  };
  return fetch(`${openstageApiCrudUrl}/fan_artist?id=eq.${id}`, requestOptions);
});

export const doFanUpdate = wrapRequest((fanDetails) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      ...fanDetails,
      utm: '7619683f-2a0d-48f2-8a56-a6406db0e2e8',
    }),
  };
  return fetch(`${openstageApi2Url}/fanUpdate`, requestOptions);
});
