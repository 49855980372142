<template>
  <svg class="custom-icon" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 26 22">
    <path class="st0" style="opacity: 0.5" d="M15.9,4.9L13,0,0,22h5.8L15.9,4.9Z" />
    <path d="M17.3,17.1l2.9-4.9-2.9-4.9-8.7,14.7h17.3l-2.9-4.9h-5.8Z" />
  </svg>
</template>

<style scoped>
.svg-icon {
  fill: currentColor;
}
</style>
