<template>
  <svg class="custom-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <path d="M4.72778 9.66476H5.44134V13.5268H6.11641V9.66476H6.84322V9.03223H4.72778V9.66476Z" />
    <path
      d="M10.4361 10.1356C10.2129 10.1356 10.0046 10.2575 9.8089 10.4988V9.03076H9.20215V13.5255H9.8089V13.2011C10.012 13.4517 10.2201 13.5717 10.4361 13.5717C10.6775 13.5717 10.8399 13.4447 10.9209 13.1953C10.9616 13.0534 10.9824 12.8304 10.9824 12.5205V11.1869C10.9824 10.8698 10.9616 10.6485 10.9209 10.5139C10.8401 10.2627 10.6773 10.1356 10.4361 10.1356ZM10.3753 12.568C10.3753 12.8707 10.2866 13.0199 10.1113 13.0199C10.0116 13.0199 9.91018 12.9722 9.80872 12.8705V10.8295C9.91018 10.7291 10.0116 10.6816 10.1113 10.6816C10.2864 10.6816 10.3753 10.8367 10.3753 11.1386V12.568Z"
    />
    <path
      d="M8.06983 12.7371C7.93522 12.9274 7.80798 13.0211 7.68608 13.0211C7.60505 13.0211 7.55883 12.9734 7.5441 12.8791C7.53858 12.8596 7.53858 12.7856 7.53858 12.6434V10.1777H6.93164V12.8255C6.93164 13.0622 6.95208 13.222 6.98523 13.3236C7.04618 13.4934 7.18097 13.573 7.37616 13.573C7.59953 13.573 7.8286 13.4382 8.06983 13.1616V13.5269H8.67713V10.1777H8.06983V12.7371Z"
    />
    <path
      d="M8.59113 7.08375C8.78871 7.08375 8.88355 6.92704 8.88355 6.614V5.19039C8.88355 4.87716 8.78871 4.72119 8.59113 4.72119C8.39336 4.72119 8.29834 4.87734 8.29834 5.19039V6.614C8.29834 6.92723 8.39336 7.08375 8.59113 7.08375Z"
    />
    <path
      d="M9 0C4.02942 0 0 4.02942 0 9C0 13.9706 4.02942 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02942 13.9706 0 9 0ZM10.001 4.20951H10.6125V6.70134C10.6125 6.84516 10.6125 6.91973 10.6199 6.93981C10.6334 7.03482 10.6816 7.08344 10.7637 7.08344C10.8864 7.08344 11.0151 6.98842 11.1512 6.79636V4.20951H11.7644V7.59388H11.1512V7.22486C10.907 7.50476 10.6742 7.64047 10.4505 7.64047C10.2531 7.64047 10.1168 7.56037 10.0553 7.38911C10.0214 7.28655 10.001 7.12469 10.001 6.88585V4.20951ZM7.68485 5.31308C7.68485 4.95124 7.74635 4.68681 7.87673 4.51574C8.04632 4.28428 8.28516 4.16863 8.59083 4.16863C8.89798 4.16863 9.13663 4.28428 9.30623 4.51574C9.43476 4.68663 9.49627 4.95124 9.49627 5.31308V6.49823C9.49627 6.85805 9.43476 7.12487 9.30623 7.2941C9.13663 7.52501 8.89798 7.64047 8.59083 7.64047C8.28516 7.64047 8.04632 7.52501 7.87673 7.2941C7.74635 7.12487 7.68485 6.85786 7.68485 6.49823V5.31308ZM6.04487 3.05236L6.52769 4.83578L6.99155 3.05236H7.67932L6.86118 5.75466V7.59407H6.18132V5.75466C6.11963 5.42873 5.98355 4.95124 5.76534 4.31779C5.62208 3.89629 5.47274 3.47349 5.32947 3.05236H6.04487ZM13.845 13.822C13.722 14.3558 13.2854 14.7499 12.7597 14.8086C11.5143 14.9476 10.2546 14.9484 8.99945 14.9476C7.74488 14.9484 6.48442 14.9476 5.23943 14.8086C4.7137 14.7499 4.27691 14.356 4.15427 13.822C3.97915 13.0616 3.97915 12.2313 3.97915 11.4484C3.97915 10.6654 3.98136 9.83527 4.1563 9.07476C4.27912 8.54075 4.71554 8.14668 5.24127 8.08812C6.48644 7.94909 7.74672 7.94836 9.00166 7.94909C10.2562 7.94836 11.5165 7.94909 12.7617 8.08812C13.2874 8.14686 13.7242 8.54075 13.8474 9.07476C14.022 9.83527 14.0208 10.6654 14.0208 11.4484C14.0208 12.2313 14.0201 13.0616 13.845 13.822Z"
    />
    <path
      d="M12.3686 10.1367C12.0582 10.1367 11.8171 10.2507 11.6418 10.48C11.5131 10.6494 11.4543 10.9112 11.4543 11.269V12.4422C11.4543 12.7981 11.5201 13.0618 11.6494 13.2296C11.8245 13.4583 12.0663 13.5726 12.3837 13.5726C12.7006 13.5726 12.9494 13.4528 13.1173 13.2095C13.191 13.102 13.2389 12.9803 13.2591 12.8458C13.2648 12.7851 13.272 12.6505 13.272 12.4547V12.3665H12.6531C12.6531 12.6096 12.6452 12.7446 12.6399 12.7775C12.6052 12.9397 12.518 13.0208 12.3686 13.0208C12.1602 13.0208 12.0582 12.8661 12.0582 12.5564V11.9623H13.272V11.2686C13.272 10.9109 13.2114 10.649 13.0842 10.4796C12.9146 10.2509 12.6732 10.1367 12.3686 10.1367ZM12.6655 11.4574H12.0585V11.1475C12.0585 10.8378 12.1604 10.6829 12.3631 10.6829C12.5638 10.6829 12.6655 10.8379 12.6655 11.1475V11.4574Z"
    />
  </svg>
</template>

<style scoped>
.svg-icon {
  fill: currentColor;
}
</style>
