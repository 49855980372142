<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    style="enable-background: new 0 0 306 358"
    viewBox="0 0 306 358"
  >
    <path
      d="M22.7 115.7c4.4 0 8-14.5 8-32.4s-3.6-32.4-8-32.4-8 14.5-8 32.4 3.6 32.4 8 32.4zM257.6 18c-4.2 0-7.9 9.3-10.5 24.2C242.9 16.7 236.2 0 228.6 0c-9 0-16.9 23.5-20.6 57.7-3.6-24.9-9.1-40.8-15.3-40.8-8.6 0-16 31.2-18.7 74.7-5.1-22.3-12.5-36.3-20.7-36.3s-15.6 14-20.7 36.3C129.8 48.2 122.5 17 113.8 17c-6.2 0-11.7 15.9-15.3 40.8C94.8 23.5 87 0 77.9 0c-7.6 0-14.4 16.7-18.5 42.3C56.9 27.4 53.1 18 48.9 18c-7.8 0-14.1 32.4-14.1 72.4 0 40 6.3 72.4 14.1 72.4 3.2 0 6.2-5.5 8.5-14.7 3.7 33.8 11.5 57 20.5 57 7 0 13.2-13.9 17.4-35.9 2.9 41.8 10.1 71.5 18.5 71.5 5.3 0 10.1-11.8 13.7-30.9 4.3 39.5 14.2 67.2 25.8 67.2s21.5-27.7 25.8-67.2c3.6 19.1 8.4 30.9 13.7 30.9 8.4 0 15.6-29.7 18.5-71.5 4.2 22 10.4 35.9 17.4 35.9 9 0 16.8-23.2 20.5-57 2.4 9.2 5.3 14.7 8.5 14.7 7.8 0 14.1-32.4 14.1-72.4-.1-39.9-6.4-72.4-14.2-72.4zM283.8 115.7c4.4 0 8-14.5 8-32.4s-3.6-32.4-8-32.4-8 14.5-8 32.4 3.6 32.4 8 32.4z"
    />
    <path d="M.8 305.1h304.8v52.4H.8z" style="fill: none" />
    <path
      d="M58 357.5h43.1v-14.1H78.3v-6.5h21.4v-11.6H78.3v-6.1h22.8v-14.1H58v52.4zM106 357.5h43.1v-14.1h-22.8v-6.5h21.4v-11.6h-21.4v-6.1h22.8v-14.1H106v52.4zM305.6 357.5c-2.9-8-7.1-16.6-12.6-26.1 6.5-1.9 10.3-6 10.3-12.2 0-9.5-8.8-14-22.7-14H252v52.4h20.4v-21.7c4.5 7.6 7.9 14.8 10.1 21.7h23.1zm-33-29v-9.3h7.1c3 0 4.7 1.6 4.7 4.6 0 3-1.7 4.6-4.7 4.6h-7.1zM204.1 357.5h43.1v-14.1h-22.8v-6.5h21.4v-11.6h-21.4v-6.1h22.8v-14.1h-43.1v52.4zM154 319.3h21.1c-8.7 7.1-15.9 15.2-21.5 24.1v14.1h46.3v-14.1h-23.2c5.3-7.7 12.7-15.4 23.2-24.1v-14.1H154v14.1zM27.1 305.1H.8v52.4h26.3c16.3 0 27.8-10.8 27.8-26.2-.1-15.4-11.6-26.2-27.8-26.2zM26 343.3h-4.8v-24.1H26c5.1 0 7.8 3.4 7.8 12 0 8.7-2.7 12.1-7.8 12.1z"
    />
  </svg>
</template>
