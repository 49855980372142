// import { getAuthUser } from './auth';
import { replaceDateTimeStrings } from './util.service';

import { toastification } from '@/plugins/toastification';

const loginUrl = import.meta.env.VITE_LOGIN_URL ? import.meta.env.VITE_LOGIN_URL : '/login';
// const homeUrl = import.meta.env.VITE_HOME_URL ? import.meta.env.VITE_HOME_URL : '/';

const loginInterrupt = function (current_path, message) {
  sessionStorage.interrupted_page = current_path;
  sessionStorage.interrupt_message = message;
  window.location.href = loginUrl; // resets store
};

// const redirectToInterruptedPage = function () {
//     const query = window.location.search
//     const url = sessionStorage.interrupted_page
//     if (url
//         // && !url.includes(loginUrl) // this would not let redirect to create account for existing user flow
//     ) {
//         window.location.href = url + query;
//     }
//     else {
//         window.location.href = homeUrl + query;
//     }
// }

const wrapCsvRequest =
  (fn) =>
  (...params) =>
    fn(...params)
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.text();
      })
      .catch((error) => handleError(error));

const wrapRequest =
  (fn) =>
  (...params) =>
    fn(...params)
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .catch((error) => {
        handleError(error);
      });
export const wrapRequestNoError =
  (fn) =>
  (...params) =>
    fn(...params)
      .then((response) => {
        // if (!response.ok) {
        //     return response.json()
        // }
        return response.json();
      })
      .catch((error) => {
        if (!error?.text) {
          return {
            status: 'ERROR',
            message: error?.message || 'Failed to fetch',
          };
        }
        return error.text()?.then((message) => {
          return {
            status: 'ERROR',
            message,
          };
        });
      });

const wrapRequestWithRange =
  (fn) =>
  (...params) =>
    fn(...params)
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        let data = response.json();
        return { data: data, range: response.headers.get('Content-Range') };
      })
      .catch((error) => handleError(error));

const hideError = (message) => {
  // List errors here to skip. Future: create a list of front end error rules and parse through server error messages
  if (typeof message === 'string' && message?.includes('no active card')) {
    return true;
  }
};

export const displayErrorWithTitle = (message) => {
  if (hideError(message)) {
    return;
  }
  if (message) {
    toastification.error(replaceDateTimeStrings(message), { timeout: 0 });
  } else {
    throw { message: "We're sorry, there appears to be an error. Please refresh and try again." };
  }
};

export const displayWarningWithTitle = (message) => {
  if (message) {
    toastification.warning(replaceDateTimeStrings(message), { timeout: 0 });
  } else {
    throw { message: "We're sorry, there appears to be an issue. Please refresh and try again." };
  }
};
const handleError = async (errorResponse) => {
  if (!errorResponse.ok) {
    let data = null;
    let error = {};
    if (typeof errorResponse.text === 'function') {
      let text = await errorResponse.text();
      try {
        data = JSON.parse(text);
      } catch (e) {
        data = { message: text };
      }
      switch (errorResponse.status) {
        case 403:
          if (toastification) {
            displayErrorWithTitle(data.message);
          }
          throw data;
        //break;
        case 401:
          loginInterrupt(
            window.location.pathname,
            'You have been logged out for security reasons. Please log back in.',
          );
          break;
        case 504:
          if (toastification) {
            displayWarningWithTitle(
              data.message ||
                "We're sorry, some content has failed to load. Please refresh and try again.",
            );
          }
          break;
        case 400:
          if (
            data.code == '22023' &&
            data.message.indexOf('role') >= 0 &&
            data.message.indexOf('does not exist')
          ) {
            loginInterrupt(
              window.location.pathname,
              'You have been logged out for security reasons. Please log back in.',
            );
          } else {
            if (toastification) {
              displayErrorWithTitle(data.message || '');
            }
            throw data;
          }
          break;
        // case 500:
        //     if (data.message.indexOf('permission denied') >= 0) {
        //         loginInterrupt(window.location.pathname, 'You have been logged out for security reasons. Please log back in.');
        //     }
        //     break;
        default:
          error = {
            text: errorResponse.statusText,
            code: errorResponse.status,
            url: errorResponse.url,
          };
          if (data?.message) {
            try {
              error.message = JSON.parse(data.message);
              if (error.message?.error?.messages) {
                try {
                  error.message = error.message.error.messages.join(' ');
                } catch (err) {
                  /**/
                }
              }
            } catch (err) {
              error.message = data.message;
            }
          }
          if (toastification) {
            displayErrorWithTitle(error?.message || error?.text || null);
          }
          throw error;
      } //switch
    }
    if (typeof errorResponse.text === 'function' && errorResponse.status === 401) {
      //Don't show error when logging out user. It is confusing
      return;
    }
    if (toastification) {
      displayErrorWithTitle(error?.text || null);
    } else {
      error.message = "We're sorry, something has gone wrong. Please refresh and try again.";
      if (errorResponse.message) {
        error.text = errorResponse.message;
      }
      if (errorResponse.statusText) {
        error.text = errorResponse.statusText;
      }
      if (errorResponse.status) {
        error.code = errorResponse.status;
      }
      if (errorResponse.url) {
        error.url = errorResponse.url;
      }
      throw error;
    }
  }
};

export {
  loginInterrupt,
  // redirectToInterruptedPage,
  wrapRequest,
  wrapCsvRequest,
  wrapRequestWithRange,
  handleError,
};
