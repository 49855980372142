import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import type { AudienceCriteria } from '@/interfaces/AudienceCriteria.interface';
import { getDefaultAudienceCriteria } from './criteria.composable';

export type TChannel = 'email' | 'sms' | 'messaging' | 'presave' | 'push' | 'instagram';

export function useQueryCachedStore<TData>(fetcher: Function, getAddtionalCacheKeyData?: Function) {
  const vuexStore = useStore();
  const artistId = computed(() => vuexStore.state.artistOne?.one?.id || null);
  const cacheDuration = 1000 * 60 * 1; // 1 minutes - long enough?

  const cache = ref(
    new Map<
      string,
      {
        data?: TData;
        timestamp?: number;
        inProgress?: boolean;
        errorMessage?: string;
      }
    >(),
  );

  const normalizeCriteria = (criteria?: AudienceCriteria | null, forCacheKey?: boolean) =>
    Object.assign(
      getDefaultAudienceCriteria(),
      criteria || {},
      (forCacheKey && getAddtionalCacheKeyData && getAddtionalCacheKeyData()) || {},
      (forCacheKey && { artistId: artistId.value }) || {},
    );
  const getCacheKey = (criteria?: AudienceCriteria | null, channel: TChannel | null = null) =>
    `${channel || ''}_${JSON.stringify(normalizeCriteria(criteria, true))}`;

  const getFromCache = (
    criteria?: AudienceCriteria | null,
    onlyValid?: boolean,
    channel: TChannel | null = null,
  ) => {
    const cacheKey = getCacheKey(criteria, channel);
    let cached = cache.value?.get(cacheKey);
    if (!cached) {
      cache.value.set(cacheKey, {
        inProgress: true,
        errorMessage: undefined,
      });
      cached = cache.value?.get(cacheKey)!; // prepare
    }
    if (onlyValid && !cached?.data) return null;
    if (onlyValid && !cached?.timestamp) return null;
    if (onlyValid && cached?.timestamp && cacheDuration < Date.now() - cached.timestamp)
      return null;
    return cached;
  };

  const fetchQuery = async (
    criteria?: AudienceCriteria | null,
    force?: boolean,
    channel: TChannel | null = null,
  ) => {
    if (!artistId.value) {
      console.error('No artistId present');
      resetCache();
      // TODO - also call main queryStore.resetAllCache()
      return;
    }
    const validCacheEntry = getFromCache(criteria, true, channel);
    if (!force && validCacheEntry) {
      // console.log('Skip fetch as we have valid data and no need to force reload');
      return;
    }

    const newCriteria = normalizeCriteria(criteria);
    const cacheKey = getCacheKey(criteria, channel);

    cache.value.get(cacheKey)!.inProgress = true;
    cache.value.get(cacheKey)!.errorMessage = undefined;

    try {
      const data = await fetcher(artistId.value, newCriteria, channel);
      if (!data) throw new Error('Invalid data');
      cache.value.set(cacheKey, {
        data,
        timestamp: Date.now(),
        inProgress: false,
        errorMessage: undefined,
      });
    } catch (error) {
      // console.log(error);
      cache.value.set(cacheKey, {
        inProgress: false,
        timestamp: Date.now(),
        errorMessage: 'Could not load data',
      });
    }
  };

  const resetCache = () => (cache.value = new Map());

  return {
    getFromCache,
    fetchQuery,
  };
}
