import { authHeader, openstageApi2Url } from '../auth';
import { wrapRequest, wrapRequestNoError } from '../nav';
import * as Sentry from '@sentry/vue';

export const facebookAuthorize = wrapRequest((code, state, redirect_uri) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  };
  return fetch(
    `${openstageApi2Url}/instagramAuthorize?state=${state}&code=${code}&redirect_uri=${redirect_uri}`,
    requestOptions,
  );
});

export const doDisconnectMeta = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
    }),
  };
  return fetch(`${openstageApi2Url}/instagramDisconnect`, requestOptions);
});

export const instagramAuthorize = wrapRequest((code, state, redirect_uri) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  };
  return fetch(
    `${openstageApi2Url}/instagramAuthorize?state=${state}&code=${code}&redirect_uri=${redirect_uri}`,
    requestOptions,
  );
});

export const doDisconnectInstagram = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
    }),
  };
  return fetch(`${openstageApi2Url}/instagramDisconnect`, requestOptions);
});

export const doAuthorizeBigCartel = wrapRequest((code, state) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  };
  return fetch(
    `${openstageApi2Url}/bigCartelAuthorize?state=${state}&code=${code}`,
    requestOptions,
  );
});

export const doDisconnectBigCartel = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
    }),
  };
  return fetch(`${openstageApi2Url}/bigCartelDisconnect`, requestOptions);
});

export const doAuthorizeShopify = wrapRequestNoError((query, artistId) => {
  if (!artistId) {
    Sentry.captureException('shopify artistId missing');
    throw Error('artistId missing');
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
    }),
  };
  return fetch(`${openstageApi2Url}/shopifyAppAuthorize${query}`, requestOptions);
});

export const doDisconnectShopify = wrapRequest((artistId, shop) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({
      artistId,
      shop,
    }),
  };
  return fetch(`${openstageApi2Url}/shopifyAppDisconnect`, requestOptions);
});
