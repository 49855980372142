export const convertToUrlSafe = (title) => {
  if (!title) return '';
  // Trim leading/trailing spaces
  let urlSafeString = title.trim();
  // Replace spaces with dashes
  urlSafeString = urlSafeString.replace(/\s+/g, '-');
  // Remove non-URL characters
  urlSafeString = urlSafeString.replace(/[^a-zA-Z0-9-]/g, '');
  // Convert to lowercase
  urlSafeString = urlSafeString.toLowerCase();
  return urlSafeString;
};

export const redirectToUrl = async (url) => {
  const newTab = window.open('', '_blank');

  try {
    const redirect = await url;
    if (redirect) {
      newTab.location.href = url;
    } else {
      newTab.close();
    }
  } catch (error) {
    newTab.close;
  }
};
