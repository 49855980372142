import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';
import moment from 'moment';
import createHumps from 'lodash-humps/lib/createHumps';
import { snakeCase } from 'lodash';
import humps from 'lodash-humps';
import { numberWithKMB } from '@/_helpers/numberFormatter';
const snakes = createHumps(snakeCase);

import { toastification } from '@/plugins/toastification';

export const minTransactionAmount = '1.00';
export const chartColours = [
  '#38b6f1',
  '#CD6155',
  '#AF7AC5',
  '#48C9B0',
  '#5499C7',
  '#F4D03F',
  '#EB984E',
  '#CACFD2',
  '#5D6D7E',
];

export const numberWithCommas = (val) => {
  if (val !== 0 && !val) {
    return '';
  }

  const parsedVal = parseFloat(val);
  if (isNaN(parsedVal)) {
    return '';
  }

  return formatNumberForDP(parsedVal)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const formatNumberForDP = (num) => {
  return Number.isInteger(num) ? num : num.toFixed(2);
};

export const decimalIfNeededNumber = (val) => {
  // Convert number to a string with two decimals
  let formatted = val.toFixed(2);
  // Remove unnecessary trailing zeros and the decimal point if not needed
  formatted = formatted.replace(/\.?0+$/, '');
  // Convert back to number (optional to match input type format)
  return Number(formatted);
};

export const moveNegativeSignToFront = (input) => {
  // Check if there's a negative sign in the string
  const negativeIndex = input.indexOf('-');
  if (negativeIndex !== -1) {
    // Remove the negative sign from its original position
    input = input.replace('-', '');
    // Add the negative sign to the front
    return '-' + input;
  }
  return input;
};

export const numberWithKMBNoSymbol = (value) => {
  const numberWithKMBValue = numberWithKMB(value, true);
  let numberWithKMBValueNoDot = numberWithKMBValue.replace('.', '');
  if (
    numberWithKMBValue.includes('.') &&
    numberWithKMBValue.includes('000') &&
    numberWithKMBValue.split('.')[1].split('000')[0].length === 1
  ) {
    numberWithKMBValueNoDot = numberWithKMBValueNoDot + '0';
  }

  return numberWithKMBValueNoDot;
};

export const toDecimal = (val) => {
  return Math.round(val * 100) / 100;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getPercentages = (nominator, denominator, decimals = 2) => {
  if (!nominator) return 0;
  var percent = (nominator / denominator) * 100;
  if (percent > 100) percent = 100;
  return percent.toFixed(decimals);
};

export const getAverage = (nominator, denominator) => {
  return nominator && denominator ? (nominator / denominator).toFixed(2) : 0;
};

export const secondsAgo = (date) => {
  const currentDate = new Date();
  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - date;
  // Calculate the number of seconds
  return Math.floor(timeDifference / 1000);
};

export const daysAgo = (date) => {
  const currentDate = new Date();
  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - date;
  // Calculate the number of days
  return Math.floor(timeDifference / (1000 * 60 * 60 * 24));
};

export const price = (amount) => {
  return `${amount.toFixed(2)}`;
};

export const validateSelection = (sel) => {
  if (sel.length == 0) {
    let message = null;
    message = 'You need to select an item first.';
    toastification.error(message, { timeout: false });
    return false;
  } else {
    if (sel.length > 1) {
      let message = null;
      message = 'You had more then one selection. Assuming the first ....';
      toastification.success(message);
    }
    return true;
  }
};
export const validateMutipleSelection = (sel) => {
  if (sel.length == 0) {
    let message = null;
    message = 'You need to select an item first.';
    toastification.error(message, { timeout: false });
    return false;
  } else return true;
};
export const copyToClipboard = (copyText, successText) => {
  if (navigator?.clipboard) {
    const message = successText || 'Copy successful!';
    navigator.clipboard.writeText(copyText);
    toastification.success(message);
  } else {
    toastification.error('Could not copy to clipboard', { timeout: false });
  }
};

export const goToRoute = (name, params, vm, query = {}) => {
  vm.$router
    .push({
      name,
      params,
      query,
    })
    .catch(() => {});
  return true;
};
export const goToRouteInNewTab = (name, params, vm, query = {}) => {
  const url = vm.$router.resolve({
    name,
    params,
    query,
  }).href;
  console.log(url);
  window.open(url, '_blank');
  return true;
};
export const isoStringToNormalDate = (date) => {
  let newDateTime = Date.parse(date);
  return new Date(newDateTime);
};
export const isoStringToYMDDate = (date) => {
  let newDateTime = Date.parse(date);
  return new Date(newDateTime).toLocaleString();
};
/*export const splitString = (content, splitstr) => {
   return  content.filter(element => element.attrs.property==splitstr).map(element => element.attrs.content).toString();
}
export const updateMetaString = (content, metaName,value) => {    
    content.childNodes.forEach((element) => {
        if(element.attrs.property==metaName)
        element.attrs.content=value        
      });
      return content;
}*/
export const commonValidationRules = {
  descriptionRule: [
    // (v) => !!v || 'This field is required',
    (v) =>
      !v ||
      (v.length >= 6 && v.length <= 128) ||
      'This field must be at least 6 and maximum 128 characters',
  ],
  requiredRules: [(v) => !!v || 'This field is required'],
  requiredArrayRules: [(v) => (!!v && !!v.length) || 'This field is required'],
  minLengthTenChars: [
    (v) => !!v || 'This field is required',
    (v) => (v && v.length >= 10) || 'This field must be at least 10 characters',
  ],
  urlRules: [
    (v) => !!v || 'This field is required',
    (v) => /^[a-zA-Z0-9\\-]+$/g.test(v) || 'Special characters, or spaces not allowed',
  ],
  urlRulesStrict: [
    (v) => !!v || 'This field is required',
    (v) => /^[a-z0-9\\-]+$/g.test(v) || 'Only lowercase letters, numbers, and hyphens allowed',
  ],
  urlRulesStrictNoHomepage: [
    (v) =>
      !v || /^[a-z0-9\\-]+$/g.test(v) || 'Only lowercase letters, numbers, and hyphens allowed',
    (v) =>
      !v ||
      v.toLowerCase() !== 'homepage' ||
      "'homepage' is a reserved name. Please choose another name.",
  ],
  urlValidRules: [
    (v) =>
      !v ||
      /^(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]*[A-Za-z0-9+&@#/%=~_|]$/.test(v) ||
      'Not a valid URL',
  ],
  urlLandingPage: [
    (v) =>
      !v || /^[a-z0-9\\-]+$/g.test(v) || 'Only lowercase letters, numbers, and hyphens allowed',
    (v) =>
      !v ||
      ![
        'changeemail',
        'discord-verify',
        'feed',
        'homepage',
        'index',
        'post',
        'profile',
        'resetpassword',
        'verify',
        'zone',
        'twilioverify',
      ].includes(v.toLowerCase()) ||
      `'${v}' is a reserved name. Please choose another name.`,
  ],
  noUrlRules: [
    (v) => !v || !v.includes('http') || 'URLs are not allowed. Please enter the ID only.',
  ],
  emailSimpleRules: [(v) => !v || /.+@.+\..+/.test(v) || 'E-mail must be valid'],
  emailStrictRules: [
    (v) =>
      !v ||
      /^[a-zA-Z0-9](?:[a-zA-Z0-9._+-]*[a-zA-Z0-9_+-])?@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/.test(
        v.trim().toLowerCase(),
      ) ||
      'Please ensure that the email address contains only valid characters (e.g. example@domain.com).',
  ],
  emailSenderNameRules: [
    (v) => !v || !v.includes('@') || "The '@' character is not allowed in sender name",
  ],
  domainRules: [
    (v) => !!v || 'Name is required',
    (v) => (v && v.length < 64) || 'This field must be no more than 63 characters',
  ],
  passwordRules: [
    (v) => !!v || 'Password is required',
    (v) => (v && v.length >= 8) || 'Password must be at least 8 characters',
    (v) => !/\s+/.test(v) || 'Spaces not allowed',
  ],
  passwordRulesNoLimit: [
    (v) => !!v || 'Password is required',
    (v) => !/\s+/.test(v) || 'Spaces not allowed',
  ],
  tagRulesAllowBlank: [
    (v) =>
      !v ||
      /^[\\.a-zA-Z0-9_\\-]+$/g.test(v) ||
      'Only letters, numbers, hyphens, dots, and underscores allowed',
  ],
  tagRules: [
    (v) =>
      (v && v.length === 0) ||
      (v && v.length >= 0 && /^[\\.a-zA-Z0-9_\\-]+$/g.test(v)) ||
      'Only letters, numbers, hyphens, dots, and underscores allowed',
  ],
  phoneNumberRules: [
    (v) =>
      !v ||
      /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im.test(v) ||
      'Phone number must be valid',
  ],
  phoneNumberRulesCC: [
    (v) => {
      const number = v || '';
      if (!number) {
        return true;
      }
      if (number.charAt(0) !== '+') {
        return 'Phone number must start with +';
      }
      if (/\s/g.test(number)) {
        return 'Phone number must not include any spaces';
      }
      if (!/^\+[0-9]+$/.test(number)) {
        return 'Phone number must include digits only';
      }
      const parsedNumber = parsePhoneNumber(number);
      if (!parsedNumber || !number.includes('+')) {
        return 'Phone number must be valid';
      }
      const isValid = isValidPhoneNumber(parsedNumber.number);
      return isValid || 'Phone number must be valid';
    },
  ],
  phoneNumberRulesUS: [
    (v) => {
      const number = v || '';
      if (!number) {
        return true;
      }
      if (number.substr(0, 2) !== '+1') {
        return 'Phone number must start with +1';
      }
      if (/\s/g.test(number)) {
        return 'Phone number must not include any spaces';
      }
      if (!/^\+[0-9]+$/.test(number)) {
        return 'Phone number must include digits only';
      }
      const parsedNumber = parsePhoneNumber(number);
      if (!parsedNumber || !number.includes('+1')) {
        return 'Phone number must be valid';
      }
      const isValid = isValidPhoneNumber(parsedNumber.number);
      return isValid || 'Phone number must be valid';
    },
  ],
  noBlankRules: [(v) => /^\S*$/.test(v) || 'No spaces allowed'],
  alphanumericRules: [
    (v) => !v || /^[A-Za-z0-9]+$/.test(v) || 'You can use only alphanumeric characters',
  ],
  alphanumericAndDashRules: [
    (v) => !!v || 'This field is required',
    (v) => /^[A-Za-z0-9-]+$/.test(v) || 'You can use only alphanumeric characters',
  ],
  noLeadingSpaceRules: [(v) => !v || /^[^\s]/.test(v) || 'Cannot start with space'],
  noTrailingSpaceRules: [(v) => !v || /[^\s]$/.test(v) || 'Cannot end with space'],
  latRules: [
    (v) => !!v || 'This field is required',
    (v) => (v <= 90 && v >= -90) || 'Latitude range is -90 to 90',
  ],
  lngRules: [
    (v) => !!v || 'This field is required',
    (v) => (v <= 180 && v >= -180) || 'Longitude range is -180 to 180',
  ],
  iso2Rules: [
    (v) => !!v || 'This field is required',
    (v) => v.length === 2 || 'ISO2 must have 2 characters',
  ],
  spotifySongIdRules: [
    (v) => !v || v.startsWith('https://') || 'Spotify URL should start with "https://"',
    (v) =>
      !v ||
      v.startsWith('https://open.spotify.com/track/') ||
      v.startsWith('https://open.spotify.com/album/') ||
      'Spotify track or album URL should look like: https://open.spotify.com/track/2PzU4IB8Dr6mxV3lHuaG34 or https://open.spotify.com/album/7aOtKCNfEgLS90QUAovBk3',
  ],
  spotifyPlaylistIdRules: [
    (v) => !v || v.startsWith('https://') || 'Spotify URL should start with "https://"',
    (v) =>
      !v ||
      v.startsWith('https://open.spotify.com/playlist/') ||
      'Spotify playlist URL should look like: https://open.spotify.com/playlist/6lAShLbKlrgszYmC7xjqM1',
  ],
  spotifyPodcastIdRules: [
    (v) => !v || v.startsWith('https://') || 'Spotify URL should start with "https://"',
    (v) =>
      !v ||
      v.startsWith('https://open.spotify.com/episode/') ||
      'Spotify podcast URL should look like: https://open.spotify.com/episode/2HrlLxamF3WEHjgsH9SFHy',
  ],
  appleSongIdRules: [
    (v) => !v || v.startsWith('https://') || 'Apple Music URL should start with "https://"',
    (v) =>
      !v ||
      (v.startsWith('https://music.apple.com/') &&
        (v.includes('/song/') || v.includes('/album/'))) ||
      'Apple Music track or album URL should look like: https://music.apple.com/us/album/hot-rocks-1964-1971/1440764786',
  ],
  applePlaylistIdRules: [
    (v) => !v || v.startsWith('https://') || 'Apple Music URL should start with "https://"',
    (v) =>
      !v ||
      (v.startsWith('https://music.apple.com/') && v.includes('/playlist/')) ||
      'Apple Music playlist URL should look like: https://music.apple.com/us/playlist/classic-rock-essentials/pl.1a7fd42205674dd282d106f533f4bea6',
  ],
  deezerSongIdRules: [
    (v) => !v || v.startsWith('https://') || 'Deezer URL should start with "https://"',
    (v) =>
      !v ||
      (v.startsWith('https://www.deezer.com/') && (v.includes('/track') || v.includes('/album'))) ||
      'Deezer track or album URL should look like: https://www.deezer.com/us/track/7677778',
  ],
  deezerPlaylistIdRules: [
    (v) => !v || v.startsWith('https://') || 'Deezer URL should start with "https://"',
    (v) =>
      !v ||
      (v.startsWith('https://www.deezer.com/') && v.includes('/playlist')) ||
      'Deezer playlist URL should look like: https://www.deezer.com/us/playlist/1479458365',
  ],
  spotifyArtistUrlRules: [
    (v) => !v || v.startsWith('https://') || 'Spotify artist URL should start with "https://"',
    (v) =>
      !v ||
      (v.startsWith('https://open.spotify.com/') &&
        (v.includes('/artist/') || v.includes('/show/'))) ||
      'Spotify artist URL should look like: https://open.spotify.com/artist/3w6zswp5THsSKYLICUbDTZ',
  ],
  appleArtistUrlRules: [
    (v) => !v || v.startsWith('https://') || 'Apple artist URL should start with "https://"',
    (v) =>
      !v ||
      (v.startsWith('https://music.apple.com/') && v.includes('/artist')) ||
      'Apple artist URL should look like: https://music.apple.com/gb/artist/gabrielle-aplin/434657397',
  ],
  deezerArtistUrlRules: [
    (v) => !v || v.startsWith('https://') || 'Deezer artist URL should start with "https://"',
    (v) =>
      !v ||
      (v.startsWith('https://www.deezer.com/') && v.includes('/artist/')) ||
      'Deezer artist URL should look like: https://www.deezer.com/us/artist/516830',
  ],
  ageRestrictionRules: [(v) => !v || v >= 13 || 'Required minimum age is 13'],
  moneyStringRules: [
    (v) => !v || /^(0|[1-9]\d*)(\.\d{1,2})?$/.test(v) || 'Please use numbers (maximum 2 decimals)',
  ],
  moneyStringMinRule(v, min, currency = '£') {
    if (!v) return true;
    const parsedV = parseFloat(v);
    if (isNaN(parsedV)) return 'Please input numbers only';
    const parsedMin = parseFloat(min);
    if (isNaN(parsedMin)) return 'Minimum value not met';
    if (parsedV === 0.0) return true;
    return !v || parsedV >= parsedMin || `Minimum value is ${currency}${min}`;
  },
  sectionHeadingRules: [(v) => !v || v.length <= 30 || 'Maximum 30 characters allowed'],
  buttonLabelRules: [(v) => !v || v.length <= 40 || 'Maximum 40 characters allowed'],
  generalUrlRules: [
    (v) =>
      !v ||
      /^(https?):\/\/([^/\s]+)((\/[^/\s]+)*\/?)?$|^(sms|tel):([^/\s]+)?$|^mailto:([^\s@]+@[^\s@]+\.[^\s@]+)$/.test(
        v,
      ) ||
      'Invalid URL',
  ],
  vimeoEmbedUrlRules: [
    (v) =>
      /^(https?:\/\/)?(www\.)?(vimeo\.com\/|player\.vimeo\.com\/video\/)([0-9]+)(\/[a-zA-Z0-9_-]+)?(\/[0-9]+)?$/.test(
        v,
      ) || 'Invalid Vimeo URL',
  ],
  youtubeEmbedUrlRules: [
    (v) =>
      /^https:\/\/(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]{11})$/.test(v) ||
      'Invalid Youtube Embed URL',
  ],
  youtubeAccountUrlRules: [
    (v) =>
      !v ||
      /^(https?:\/\/)?(www\.)?youtube\.com\/(c|channel|user)\/[A-Za-z0-9_-]+$/.test(v) ||
      'Invalid YouTube account URL',
  ],
  tiktokAccountUrlRules: [
    (v) =>
      !v ||
      /^(https?:\/\/)?(www\.)?tiktok\.com\/@[\w.-]+$/.test(v) ||
      'TikTok artist URL should look like: https://tiktok.com/@artist',
  ],
  twitterAccountUrlRules: [
    (v) =>
      !v ||
      /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/[A-Za-z0-9_]+$/.test(v) ||
      'Invalid X (Twitter) account URL',
  ],
  facebookAccountUrlRules: [
    (v) =>
      !v ||
      /^(https?:\/\/)?(www\.)?facebook\.com\/[A-Za-z0-9.]+\/?$/.test(v) ||
      'Invalid Facebook account URL',
  ],
  instagramAccountUrlRules: [
    (v) =>
      !v ||
      /^(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9._-]+\/?$/.test(v) ||
      'Invalid Instagram account URL',
  ],
  smsHelpTextRules: [
    (v) => {
      if (!v) return true;
      try {
        const parsedSms = smsLength(v, 100);
        if (parsedSms.count > 53) return 'Maximum 53 characters.';
        if (parsedSms.limit < 1) return 'No special characters allowed.';
        return true;
      } catch (error) {
        return '' + error;
      }
    },
  ],
};

export const validateWithEachRule = (rules, v) => {
  for (let i = 0; i < rules.length; i++) {
    const result = rules[i](v);
    if (result !== true) {
      return result; // Return the error message if validation fails
    }
  }
  return true; // All rules passed
};

export const isCorrectYoutubeFormat = (url) => {
  return /^https:\/\/(?:www\.)?youtube\.com\/embed\/([a-zA-Z0-9_-]{11})$/.test(url);
};
export const formatYouTubeVideoUrl = (url) => {
  if (!url) return '';
  const id = extractYouTubeVideoId(url);
  if (!id) return '';
  return `https://youtube.com/embed/${id}`;
};
export const extractYouTubeVideoId = (url) => {
  const regexList = [
    /^(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]{11})(\?.*)?$/, // https://youtu.be/4uFveWcIHsM
    /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:embed\/|watch\?.*v=)?([a-zA-Z0-9_-]{11})(?:\?|&.*)?$/, // https://youtube.com/4uFveWcIHsM or https://youtube.com/embed/4uFveWcIHsM or https://www.youtube.com/watch?v=4uFveWcIHsM&t=1122s
    /^([a-zA-Z0-9_-]{11})(\?.*)?$/, // 4uFveWcIHsM
  ];
  // Iterate through the regex patterns and return the video ID if there's a match
  for (const regex of regexList) {
    const match = url.match(regex);
    if (match) {
      return match[1]; // The video ID is captured in the first group (index 1)
    }
  }
  return null;
};

export const multiSelectValidationRules = (vals, rules) => {
  if (!Array.isArray(vals) || !Array.isArray(rules)) return 'Invalid';
  for (let i = 0; i < vals.length; i++) {
    for (let j = 0; j < rules.length; j++) {
      const validated = rules[j](vals[i]);
      if (typeof validated !== 'boolean') return validated;
    }
  }
  return true;
};

export const sanitizeTags = (tags) => {
  // removes duplicates and invalid values
  return [...new Set(tags)].filter((v) => !['', '""', null, 'NULL'].includes(v));
};

export const jsonString = (contentHead, ogTitle, ogDescription) => {
  let data = contentHead.replace(/(\r\n|\n|\r|\s+)/g, ' ');
  let ogtitleNew = data.replace(
    `<meta property="og:title" content="${ogTitle}" />`,
    '<meta property="og:title" content="{{ogTitle}}" />',
  );
  let return1 = ogtitleNew.replace(
    `<meta property="og:description"  content="${ogDescription}" />`,
    '<meta property="og:description" content="{{ogDescription}}"/>',
  );
  return return1;
};

export const formatUrl = (text) => {
  if (!text || typeof text !== 'string') return '';
  return text.replace(/[\W_]+/g, '').toLowerCase();
};

export const localTimezone = `UTC ${moment(new Date()).format('Z')}`;

export const replaceSubstring = (data, replaceString, newString) => {
  return data.replace(replaceString, newString);
};

export const getMetaObject = (property, content) => {
  let metaObject = { attributes: [{}, {}], tagName: 'meta', type: 'element', children: [] };
  metaObject.attributes[0].key = 'property';
  metaObject.attributes[0].value = property;
  metaObject.attributes[1].key = 'content';
  metaObject.attributes[1].value = content;
  return metaObject;
};

export const getScriptObject = (src) => {
  let scriptObject = { attributes: [{}], tagName: 'script', type: 'element', children: [] };
  scriptObject.attributes[0].key = 'src';
  scriptObject.attributes[0].value = src;

  return scriptObject;
};

export const smsLimit = 160;
export const smsLength = (message, smsLimitReduction = 0) => {
  let unicodeType = '7bit';
  const charset7bit = [
    '@',
    '£',
    '$',
    '¥',
    'è',
    'é',
    'ù',
    'ì',
    'ò',
    'Ç',
    '\n',
    'Ø',
    'ø',
    '\r',
    'Å',
    'å',
    'Δ',
    '_',
    'Φ',
    'Γ',
    'Λ',
    'Ω',
    'Π',
    'Ψ',
    'Σ',
    'Θ',
    'Ξ',
    'Æ',
    'æ',
    'ß',
    'É',
    ' ',
    '!',
    '"',
    '#',
    '¤',
    '%',
    '&',
    "'",
    '(',
    ')',
    '*',
    '+',
    ',',
    '-',
    '.',
    '/',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    ':',
    ';',
    '<',
    '=',
    '>',
    '?',
    '¡',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'Ä',
    'Ö',
    'Ñ',
    'Ü',
    '§',
    '¿',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'ä',
    'ö',
    'ñ',
    'ü',
    'à',
  ];
  const charset7bitext = ['\f', '^', '{', '}', '\\', '[', '~', ']', '|', '€'];
  let smsChars = smsLimit - smsLimitReduction;
  let charsArr = message.split('');
  let charsUsed = 0;
  let i = 0;

  for (i = 0; i < charsArr.length; i += 1) {
    if (charset7bit.indexOf(charsArr[i]) >= 0) {
      charsUsed += 1;
      unicodeType = '7bit';
    } else if (charset7bitext.indexOf(charsArr[i]) >= 0) {
      charsUsed += 2;
      unicodeType = '7bit';
    } else {
      unicodeType = '16bit';
      charsUsed = charsArr.length;
      break;
    }
  }

  smsChars = unicodeType === '7bit' ? smsLimit - smsLimitReduction : 70 - smsLimitReduction;

  if (charsUsed === 0) {
    unicodeType = '7bit';
    smsChars = smsLimit - smsLimitReduction;
  }

  return { count: charsUsed, limit: smsChars };
};

export const findLastIndex = (json, prop) => {
  let previousIndex,
    index = json.length - 1;
  for (; index >= 0; index--) {
    if (json[index].tagName == prop) {
      previousIndex = index;
      break;
    }
  }
  return previousIndex;
};

export const csvToJson = (csv) => {
  let result = [];
  try {
    let lines = csv.split('\n');
    let headers = lines[0].split(',');

    for (let i = 1; i < lines.length; i++) {
      if (!lines[i]) continue;
      let obj = {};
      let currentline = lines[i].split(',');

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }
  } catch (error) {
    //
  }

  return result;
};

export const replaceNonGenericQuotes = (text) => {
  return text.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
};

export const includesNonGenericQuotes = (text) => {
  return /[\u2018\u2019]/g.test(text) || /[\u201C\u201D]/g.test(text);
};

// Feature flag(s)
export const featureFlags = {
  collections: import.meta.env.VITE_FF_COLLECTIONS === 'true',
  queue: import.meta.env.VITE_FF_QUEUE === 'true',
  metaDemo: import.meta.env.VITE_FF_METADEMO === 'true',
  bigcartel: import.meta.env.VITE_FF_BIGCARTEL === 'true',
  integrations: import.meta.env.VITE_FF_INTEGRATIONS === 'true',
  dashboardPlatformUsage: import.meta.env.VITE_FF_DASHBOARD_PLATFORM_USAGE === 'true',
  dashboardBenchmarks: import.meta.env.VITE_FF_DASHBOARD_BENCHMARKS === 'true',
  dashboardRevenuePaths: import.meta.env.VITE_FF_DASHBOARD_REVENUE_PATHS === 'true',
  dashboardCampaigns: import.meta.env.VITE_FF_DASHBOARD_CAMPAIGNS === 'true',
  dashboardTasks: import.meta.env.VITE_FF_DASHBOARD_TASKS === 'true',
  dashboardInsights: import.meta.env.VITE_FF_DASHBOARD_INSIGHTS === 'true',
  dashboardDateCustom: import.meta.env.VITE_FF_DASHBOARD_DATE_CUSTOM === 'true',
  dashboardReports: import.meta.env.VITE_FF_DASHBOARD_REPORTS === 'true',
  audienceSuggestions: import.meta.env.VITE_FF_AUDIENCE_SUGGESTIONS === 'true',
  accountCreationSuspended: import.meta.env.VITE_FF_ACCOUNT_CREATION_SUSPENDED === 'true',
  pagesView: import.meta.env.VITE_FF_PAGES_VIEW === 'true',
  summaryOffers: import.meta.env.VITE_FF_SUMMARY_OFFERS === 'true',
  summaryEarnings: import.meta.env.VITE_FF_SUMMARY_EARNINGS === 'true',
  pollVersion2: import.meta.env.VITE_FF_POLL_VERSION_2 === 'true',
  automationsInstagram: import.meta.env.VITE_FF_AUTOMATIONS_INSTAGRAM === 'true',
};

export const isOasis = (artistId) => {
  return [
    '28400196-01ba-4920-810b-9592f9f1045d', // Oasis
  ].includes(artistId);
};
export const isTestArtist = (artistId) => {
  return [
    '3d8801d3-a2c3-4ffc-a1ba-9fd6c2239341', // Mate Artist
  ].includes(artistId);
};
export const isArtistToUseStandardLatencyStream = (artistId) => {
  return [
    '3d8801d3-a2c3-4ffc-a1ba-9fd6c2239341', // Mate Artist
    '96d9c518-9406-40b3-9d63-459e293e76de', // sknd
  ].includes(artistId);
};
export const isArtistToUseInstagramDM = (artistId) => {
  return [
    '3d8801d3-a2c3-4ffc-a1ba-9fd6c2239341', // Mate Artist
    '2afa9c33-e268-4df5-8571-ebaa83abd8e7', // deyyess
  ].includes(artistId);
};

export const requireContextSvgIcon = ''; //require.context('@/assets/icons', false, /\.svg$/)

export const replaceDateTimeStrings = (inputString) => {
  if (!inputString || typeof inputString !== 'string') return inputString;
  // create a regular expression to match datetime strings in the format YYYY-MM-DDTHH:MM:SS.sssZ
  const dateTimeRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/g;
  // find all datetime strings in the input string and replace them with the return value of the replaceDateString function
  return inputString.replace(dateTimeRegex, replaceDateString);
};
export const replaceDateString = (datestring) => {
  if (!datestring || typeof datestring !== 'string') return datestring;
  return moment(datestring).format('HH:mm a');
};

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const toSnakes = (content) => {
  return snakes(content);
};
export const toHumps = (content) => {
  return humps(content);
};

export const minsToDHM = (mins) => {
  if (mins && mins > 0) {
    const remainingMins = mins % 60;
    let hours = Math.floor(mins / 60);
    const days = Math.floor(hours / 24);
    hours = hours % 24;
    return {
      days,
      hours,
      mins: remainingMins,
    };
  } else {
    return {
      days: 0,
      hours: 0,
      mins: 0,
    };
  }
};

export const dHMToMins = (days, hours, mins) => {
  return parseInt(days * 24 * 60) + parseInt(hours * 60) + parseInt(mins);
};

export const parseAppleImage = (image) => {
  return image ? image.replace('{w}', 256).replace('{h}', 256) : '';
};

export const ensureHttps = (url) => {
  if (typeof url !== 'string') return null;
  const urlTrimmed = url.trim();
  if (urlTrimmed.startsWith('https://')) return urlTrimmed;
  if (urlTrimmed.startsWith('http://')) return 'https://' + urlTrimmed.slice(7);
  return 'https://' + urlTrimmed;
};
