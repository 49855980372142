import {
  doFetchListByArtist,
  doLookup,
  doCloneOne,
} from '@/services/agent/email_master_template.service';
import { getField, updateField } from 'vuex-map-fields';
import humps from 'lodash-humps';
import { toastification } from '@/plugins/toastification';

export const emailMasterTemplateList = {
  namespaced: true,
  state: () => ({
    list: [],
    inProgress: false,
    cloningInProgress: false,
    error: null,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo = false) {
      state.inProgress = yesOrNo;
    },
    cloningInProgress(state, yesOrNo = false) {
      state.cloningInProgress = yesOrNo;
    },
    setList(state, list = []) {
      state.list = list || [];
    },
  },
  actions: {
    fetchList: async ({ commit, rootState }) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id');
        return;
      }
      commit('inProgress', true);
      const list = await doFetchListByArtist(rootState.artistOne.one.id);
      if (list && list?.status !== 'ERROR') {
        commit('setList', humps(list));
      }
      commit('inProgress');
    },
    lookUpList: async ({ commit, rootState }, { search }) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id');
        return;
      }
      commit('inProgress', true);
      try {
        const list = await doLookup(search, rootState.artistOne.one.id);
        if (list) {
          commit('setList', humps(list));
        }
      } finally {
        commit('inProgress');
      }
    },
    cloneMasterTemplate: async ({ commit }, { templateId }) => {
      commit('cloningInProgress', true);
      try {
        if (!templateId) {
          throw new Error('No email master template Id');
        }
        const response = await doCloneOne(templateId);
        if (response.status === 'ERROR') {
          throw new Error(response.message);
        }
        toastification.success('Template cloned successfully');
        return response;
      } catch (error) {
        console.error(error.message);
        toastification.error('Failed to clone template');
      } finally {
        commit('cloningInProgress');
      }
    },
    resetList: ({ commit }) => {
      commit('setList');
    },
  },
};
