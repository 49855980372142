import { authHeader, openstageApiCrudUrl } from './auth';
import { wrapRequest } from './nav';

export const doDeleteOne = (membership_id) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
  };
  // VITE_OPENSTAGE_API_URL is now pointint to api2 which can serve suitcase services
  return fetch(`${openstageApiCrudUrl}/team_membership?id=eq.${membership_id}`, requestOptions);
};

export const doPostOne = wrapRequest((teamMembership) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(teamMembership),
  };
  return fetch(`${openstageApiCrudUrl}/team_membership`, requestOptions);
});

export const doFetchOne = wrapRequest((id) => {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };
  return fetch(`${openstageApiCrudUrl}/team_membership?id=eq.${id}`, requestOptions);
});

export const doFetchListForTeam = wrapRequest(({ teamId }) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${openstageApiCrudUrl}/team_membership?team_id=eq.${teamId}`, requestOptions);
});
