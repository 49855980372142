import { doFetchOne, doPostOne, doPatchOne } from '@/services/agent/email_template.service';
import { getField, updateField } from 'vuex-map-fields';
import humps from 'lodash-humps';
import createHumps from 'lodash-humps/lib/createHumps';
import { snakeCase } from 'lodash';
const snakes = createHumps(snakeCase);

// const emptyOne = {
//   title: '',
//   description: '',
//   status: 'draft',
//   contentHtmlFull: '',
//   previewText: '',
// };

export const emailTemplateOne = {
  namespaced: true,
  state: () => ({
    one: {
      title: '',
      description: '',
      status: '',
      contentHtmlFull: '',
      previewText: '',
      // and more for existing ones:
      // id: null,
      // artistId: null,
      // createdAt: null,
      // updatedAt: null,
      // topolJson: null,
    },
    inProgress: false,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo = false) {
      state.inProgress = yesOrNo;
    },
    setOne(state, one = {}) {
      state.one = JSON.parse(JSON.stringify(one));
    },
    setPreviewText(state, previewText = '') {
      state.one.previewText = previewText;
    },
  },
  actions: {
    // new({ commit }) {
    //   commit('inProgress');
    //   commit('setOne', emptyOne);
    // },
    fetchOne: async ({ commit, dispatch }, emailTemplateId = null) => {
      if (!emailTemplateId) {
        dispatch('setOne');
        commit('inProgress');
        return;
      }

      try {
        commit('inProgress', true);
        const emailTemplates = await doFetchOne(emailTemplateId);
        if (emailTemplates.status === 'ERROR' || !emailTemplates?.length) {
          throw new Error('Email template not found');
        }
        dispatch('setOne', emailTemplates[0]); // humped in setOne action
        commit('inProgress');
      } catch (err) {
        commit('inProgress');
        throw err;
      }
    },
    getOneById: async ({ commit }, emailTemplateId = null) => {
      if (!emailTemplateId) {
        commit('inProgress');
        return null;
      }

      try {
        commit('inProgress', true);
        const emailTemplates = await doFetchOne(emailTemplateId);
        if (emailTemplates.status === 'ERROR' || !emailTemplates?.length) {
          throw new Error('Email template not found');
        }
        commit('inProgress');
        return emailTemplates[0];
      } catch (err) {
        commit('inProgress');
        throw err;
      }
    },
    save: async ({ commit, dispatch, state }, updatedFields = {}) => {
      commit('inProgress', true);
      try {
        if (!state.one?.id) {
          const emailTemplate = await doPostOne(
            snakes(Object.assign({}, state.one, updatedFields || {})),
          );
          if (emailTemplate) {
            dispatch('setOne', emailTemplate);
            commit('inProgress');
            return { new: true }; // shouldn't return
          } else {
            throw 'Failed to create email template';
          }
        } else {
          const emailTemplate = await doPatchOne(
            snakes(Object.assign({}, state.one, updatedFields || {})),
          );
          if (emailTemplate) {
            commit('setOne', humps(emailTemplate));
            commit('inProgress');
            return { update: true }; // shouldn't return
          } else {
            throw 'Failed to save email template';
          }
        }
      } catch (err) {
        commit('inProgress');
        throw err;
      }
    },
    setOne: async ({ commit }, emailTemplate = {}) => {
      commit('setOne', emailTemplate ? humps(emailTemplate) : {});
    },
    generateEmailTemplate: async ({ commit, dispatch }, emailTemplate) => {
      commit('inProgress', true);
      let response = await doPostOne(snakes(emailTemplate));
      if (!response?.id) {
        commit('inProgress');
        throw new Error('Could not generate email template');
      }
      dispatch('setOne', response);
      commit('inProgress');
    },

    resetOne: ({ commit }) => {
      commit('setOne');
    },
  },
};
