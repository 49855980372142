import {
  doFetchOrganizationMetrics,
  doPostOne,
  doFetchOne,
  doPatchOne,
} from '@/services/agent/organization.service';
import { getField, updateField } from 'vuex-map-fields';
import humps from 'lodash-humps';
import { snakeCase } from 'lodash';
import createHumps from 'lodash-humps/lib/createHumps';
const snakes = createHumps(snakeCase);

export const organizationOne = {
  namespaced: true,
  state: () => ({
    one: {},
    organizationMetrics: null,
    inProgress: true,
    organizationMetricsInProgress: false,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    organizationMetrics(state, organizationMetrics) {
      state.organizationMetrics = organizationMetrics;
    },
    organizationMetricsInProgress(state, yesOrNo) {
      state.organizationMetricsInProgress = yesOrNo;
    },
  },
  actions: {
    new({ commit }) {
      commit('inProgress', false);
      commit('setOne', {
        name: '',
        address: '',
        postcode: '',
        city: '',
        country: '',
        automatedBilling: false,
      });
    },
    save: async ({ commit, state }, extraData) => {
      commit('inProgress', true);
      try {
        if (undefined === state.one.id) {
          const org = await doPostOne(snakes(Object.assign({}, state.one, extraData)));
          commit('setOne', humps(org));
          commit('inProgress', false);
          return { new: true, id: org.id };
        } else {
          const org = await doPatchOne(snakes(state.one));
          // if(!org) { commit('resetOne') } // ?
          commit('setOne', humps(org));
          commit('inProgress', false);
          return { update: true };
        }
      } catch (err) {
        commit('inProgress', false);
        throw err;
      }
    },

    fetchOne: async ({ commit }, organizationId) => {
      try {
        commit('inProgress', true);
        const organizations = await doFetchOne(organizationId);
        commit('inProgress', false);
        if (organizations && organizations.length > 0) {
          commit('setOne', humps(organizations[0]));
        }
      } catch (err) {
        commit('inProgress', false);
        throw err;
      }
    },
    refreshOneNoInProgress: async ({ commit }, organizationId) => {
      const organizations = await doFetchOne(organizationId);
      if (organizations && organizations.length > 0) {
        commit('setOne', humps(organizations[0]));
      }
    },
    fetchOrganizationMetrics: async ({ commit }, organizationId) => {
      try {
        commit('organizationMetricsInProgress', true);
        const metrics = await doFetchOrganizationMetrics(organizationId);
        if (metrics?.fans_with_delivery_address_count)
          delete metrics.fans_with_delivery_address_count;
        commit('organizationMetricsInProgress', false);
        commit('organizationMetrics', humps(metrics));
      } catch (err) {
        commit('organizationMetricsInProgress', false);
        throw err;
      }
    },
    resetOne: async ({ commit }) => {
      commit('setOne', {});
    },
  },
};
