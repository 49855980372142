import { getField, updateField } from 'vuex-map-fields';
import {
  // isEqual,
  cloneDeep,
} from 'lodash';
import { toSnakes } from '@/services/util.service';
import humps from 'lodash-humps';
import { doFetchOne, doPostOne, doDeleteOne, doPatchOne } from '@/services/agent/catalog.service';
import { toastification } from '@/plugins/toastification';
import moment from 'moment';

export const daysRemaining = (date = '') => {
  if (!date) return Infinity; // sometime in the infinite void of future. deep
  const eventdate = moment(date);
  const todaysdate = moment();
  return eventdate.diff(todaysdate, 'days');
};

const emptyOne = {
  title: '', // null?
  type: '', // null?
  coverImage: null,
  releaseDate: null,
  isrc: null,
  upc: null,
  spotifyId: null,
  appleMusicId: null,
  deezerId: null,
};

export const catalogOne = {
  namespaced: true,
  state: () => ({
    error: { state: false },
    one: cloneDeep(emptyOne),
    oneUpdated: cloneDeep(emptyOne),
    inProgress: false,
  }),
  getters: {
    getField,
    // if the release day is today or in the past
    isReleased: (state) => {
      return daysRemaining(state?.one?.releaseDate || '') <= 0;
    },
  },
  mutations: {
    updateField,
    error(state, error) {
      state.error = error;
    },
    one(state, one) {
      state.one = one;
    },
    oneUpdated(state, one) {
      state.oneUpdated = one;
    },
    inProgress(state, inProgress = false) {
      state.inProgress = inProgress || false;
    },
  },
  actions: {
    setOne({ commit }, one) {
      const humpedOne = humps(one);
      commit('one', humpedOne);
      commit('oneUpdated', cloneDeep(humpedOne));
    },
    newOne({ commit }) {
      const humpedOne = humps(emptyOne);
      commit('one', humpedOne);
      commit('oneUpdated', cloneDeep(humpedOne));
    },
    async fetchOne({ commit, dispatch }, catalogItemId) {
      commit('inProgress', true);
      try {
        dispatch('newOne');
        if (!catalogItemId) throw new Error('no catalog item id');
        const list = await doFetchOne(catalogItemId);
        if (!list || !list[0]) throw new Error('no such catalog item');
        const one = humps(list[0]);
        dispatch('setOne', one);
        commit('error', { state: false }); // move this to a default
      } catch (err) {
        commit('error', {
          state: true,
          type: 'not found',
          message: 'Could not load this catalog item, please reload and retry.',
        });
      }
      commit('inProgress');
    },
    async deleteOne({ state }) {
      let responseOne = await doDeleteOne(state.one.id);

      if (responseOne?.status === 'OK') {
        return { success: true };
      }
      let message = 'Could not delete this catalog item';

      if (responseOne?.status === 'ERROR') {
        if (
          responseOne.message.includes(
            'violates foreign key constraint "page_catalogue_item_id_fkey"',
          )
        ) {
          message += ' because it is referenced by a page.';
        } else if (
          responseOne.message.includes(
            'violates foreign key constraint "broadcast_catalogue_item_id_fkey"',
          )
        ) {
          message += ' because it is referenced by a broadcast.';
        } else {
          message += `. ${responseOne.message}`;
        }
      }

      return { success: false, message };
    },
    async saveOne({ state, dispatch }, artistId) {
      const one = toSnakes(JSON.parse(JSON.stringify(state.oneUpdated)));

      let responseOne = {};
      try {
        /* Prevent empty strings being saved. However, this wasn't needed on vue2. Suspect this is a v-input issue and requires fix there instead */
        if (!one.isrc) one.isrc = null;
        if (!one.upc) one.upc = null;
        one.artist_id = artistId;
        if (state?.one?.id) {
          responseOne = await doPatchOne(one);
          toastification.success('Catalog item updated successfully');
        } else {
          responseOne = await doPostOne(one);
          toastification.success('Catalog item created successfully');
        }
        if (!responseOne.id) throw new Error('failed');
        dispatch('setOne', responseOne);
      } catch (error) {
        //
      }

      return responseOne.id;
    },
    resetOne({ commit }) {
      commit('one', cloneDeep(emptyOne));
      commit('oneUpdated', cloneDeep(emptyOne));
    },
  },
};
