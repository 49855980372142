import { authHeader, openstageApiCrudUrl, openstageApi2Url } from '../auth';
import { wrapRequest, wrapRequestWithRange } from '../nav';

export const moveOrganization = wrapRequest((artistId, organizationId) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ artistId, organizationId }),
  };
  return fetch(`${openstageApi2Url}/moveOrganization`, requestOptions);
});

export const tagSummary = wrapRequest((artistId, tags) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ artistId, tags }),
  };
  return fetch(`${openstageApi2Url}/tagSummary`, requestOptions);
});

export const tagCount = wrapRequest((artistId, tag) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ artistId, tag }),
  };
  return fetch(`${openstageApi2Url}/tagCount`, requestOptions);
});

// get a list of all artist stats on fan activity.
// allowed types: fans, signins, clicks, spend, presaves, broadcasts
export const artistFanActivity = wrapRequest((type, dateFrom, dateTo, accountManagerId) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({
      type,
      dateFrom,
      dateTo,
      accountManagerId,
    }),
  };
  return fetch(`${openstageApi2Url}/artistFanActivity`, requestOptions);
});

// get a list of all artist stats.
// allowed types: logins, broadcasts, pages, nextInvoices
export const artistActivity = wrapRequest(
  (type, dateFrom, dateTo, accountManagerId, search = null) => {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader() },
      body: JSON.stringify({
        type,
        dateFrom,
        dateTo,
        accountManagerId,
        search,
      }),
    };
    return fetch(`${openstageApi2Url}/artistActivity`, requestOptions);
  },
);

// get a list of all artist financials stats.
// allowed types: subscriptions, upsell
export const artistFinancials = wrapRequest(
  (type, dateFrom, dateTo, accountManagerId, search = null) => {
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader() },
      body: JSON.stringify({
        type,
        dateFrom,
        dateTo,
        accountManagerId,
        search,
      }),
    };
    return fetch(`${openstageApi2Url}/artistFinancials`, requestOptions);
  },
);

// get a list of all activity for an artist
export const artistActivityDetail = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ artistId }),
  };
  return fetch(`${openstageApi2Url}/artistActivityDetail`, requestOptions);
});

export const deleteTags = wrapRequest((artistId, tags) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ artistId, tags }),
  };
  return fetch(`${openstageApi2Url}/deleteTags`, requestOptions);
});

export const renameTag = wrapRequest((artistId, oldTag, newTag) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({ artistId, oldTag, newTag }),
  };
  return fetch(`${openstageApi2Url}/renameTag`, requestOptions);
});

export const doSmsSpend = wrapRequest((artistId, startDate, broadcastId) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({
      artistId: artistId,
      startDate: startDate,
      broadcastId: broadcastId,
    }),
  };
  return fetch(`${openstageApi2Url}/smsSpend`, requestOptions);
});

export const doFetchList = wrapRequestWithRange((organizationId) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-type': 'application/json', ...authHeader(), Prefer: 'count=exact' },
  };
  const url = `${openstageApiCrudUrl}/artist?organization_id=eq.${organizationId}`;
  return fetch(url, requestOptions);
});

export const doFetchOne = wrapRequest((id) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  };
  var url = `${openstageApiCrudUrl}/artist?id=eq.${id}`;
  return fetch(url, requestOptions);
});

export const doFetchInstagramMessagePrice = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify({
      artistId: artistId,
    }),
  };
  return fetch(`${openstageApi2Url}/instagramPrice`, requestOptions);
});

export const doFetchOneByShortName = wrapRequest((shortName) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  };
  var url = `${openstageApiCrudUrl}/artist?short_name=eq.${shortName}`;
  return fetch(url, requestOptions);
});

export const doFetchInfluencers = wrapRequest((id) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  };
  var url = `${openstageApi2Url}/influencers?artistId=${id}`;
  return fetch(url, requestOptions);
});

export const doFetchTopSpotifyListeners = wrapRequest((artistId, trackId = null) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  };
  let queryParams = `?artistId=${artistId}`;
  if (trackId) queryParams += `&trackId=${trackId}`;
  const url = `${openstageApi2Url}/topSpotifyListeners${queryParams}`;
  return fetch(url, requestOptions);
});

export const doFetchTopSpenders = wrapRequest((id) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  };
  var url = `${openstageApi2Url}/topSpenders?artistId=${id}`;
  return fetch(url, requestOptions);
});

export const doFetchTopSpenderSummary = wrapRequest((id) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  };
  var url = `${openstageApi2Url}/topSpendersSummary?artistId=${id}`;
  return fetch(url, requestOptions);
});

export const doLookup = wrapRequestWithRange((search, organizationId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Prefer: 'count=exact',
      ...authHeader(),
    },
  };
  return fetch(
    `${openstageApiCrudUrl}/artist?name=ilike.*${search.name}*&organization_id=eq.${organizationId}`,
    requestOptions,
  );
});

export const doPostOne = wrapRequest((artist) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(artist),
  };
  return fetch(`${openstageApiCrudUrl}/artist`, requestOptions);
});

export const doPatchOne = wrapRequest((artist) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(artist),
  };
  return fetch(`${openstageApiCrudUrl}/artist?id=eq.${artist.id}`, requestOptions);
});

export const doDeleteOne = (id) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  };
  return fetch(`${openstageApiCrudUrl}/artist?id=eq.${id}`, requestOptions);
};

export const accessibleArtists = wrapRequest((name) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    body: JSON.stringify({ name: name }),
  };
  return fetch(`${openstageApi2Url}/accessibleArtists`, requestOptions);
});

export const doFetchArtistMetrics = wrapRequest((param) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId: param.artist_id }),
  };
  return fetch(`${openstageApi2Url}/artistStats`, requestOptions);
});

export const doPostPage = wrapRequest((page) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'return=representation',
      ...authHeader(),
    },
    body: JSON.stringify(page),
  };
  return fetch(`${openstageApiCrudUrl}/page`, requestOptions);
});

export const artistFanHistory = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId }),
  };
  return fetch(`${openstageApi2Url}/artistFanHistory`, requestOptions);
});

export const artistEmailHistory = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId }),
  };
  return fetch(`${openstageApi2Url}/artistEmailHistory`, requestOptions);
});

export const artistTelemetryHistory = wrapRequest((artistId, type) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, type: type || null }),
  };
  return fetch(`${openstageApi2Url}/artistTelemetryHistory2`, requestOptions);
});

export const artistTelemetryHistoryOld = wrapRequest((artistId, type) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, type }),
  };
  return fetch(`${openstageApi2Url}/artistTelemetryHistory`, requestOptions);
});

export const artistTransactionHistory = wrapRequest((artistId, type) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId, type }),
  };
  return fetch(`${openstageApi2Url}/artistTransactionHistory`, requestOptions);
});

export const doArtistNextInvoice = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  };
  return fetch(`${openstageApi2Url}/artistNextInvoice?artistId=${artistId}`, requestOptions);
});

export const doCallingCountryPrice = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(),
    },
    body: JSON.stringify({ artistId }),
  };
  return fetch(`${openstageApi2Url}/callingCountryPrice`, requestOptions);
});

export const doFetchParams = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-type': 'application/json', ...authHeader() },
  };
  const url = `${openstageApiCrudUrl}/parameter?artist_id=eq.${artistId}`;
  return fetch(url, requestOptions);
});

export const doSyncShopifyShop = wrapRequest((artistId, shopName) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json', ...authHeader() },
    body: JSON.stringify({
      type: 'shopify',
      artist_id: artistId,
      value: 'in progress',
      key: 'sync',
      group: shopName,
    }),
  };

  return fetch(`${openstageApiCrudUrl}/parameter`, requestOptions);
});

export const doDisconnectMoshtixAccount = wrapRequest((artistId, id) => {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-type': 'application/json', ...authHeader() },
  };

  return fetch(
    `${openstageApiCrudUrl}/parameter?artist_id=eq.${artistId}&id=eq.${id}`,
    requestOptions,
  );
});

export const doConnectAtvenueToken = wrapRequest((artistId, token) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json', ...authHeader() },
    body: JSON.stringify({
      type: 'atvenu',
      artist_id: artistId,
      value: token,
      key: 'token',
    }),
  };

  return fetch(`${openstageApiCrudUrl}/parameter`, requestOptions);
});

export const doConnectSeatedAccount = wrapRequest((artistId, id) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json', ...authHeader() },
    body: JSON.stringify({
      type: 'seated',
      artist_id: artistId,
      value: id,
      key: 'artistId',
    }),
  };

  return fetch(`${openstageApiCrudUrl}/parameter`, requestOptions);
});

export const doConnectBandsintownAccount = wrapRequest(async (artistId, parameters) => {
  await Promise.all(
    parameters.map((item) => {
      const requestBody = {
        type: 'bandsintown',
        artist_id: artistId,
        value: item.value,
        key: item.key,
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        body: JSON.stringify(requestBody),
      };

      return fetch(`${openstageApiCrudUrl}/parameter`, requestOptions);
    }),
  );
});

export const doConnectOchreStore = wrapRequest(async (artistId, parameters) => {
  await Promise.all(
    parameters.map((item) => {
      const requestBody = {
        type: 'ochre',
        artist_id: artistId,
        value: item.value,
        key: item.key,
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-type': 'application/json', ...authHeader() },
        body: JSON.stringify(requestBody),
      };

      return fetch(`${openstageApiCrudUrl}/parameter`, requestOptions);
    }),
  );
});

export const doDisconnectIntegration = wrapRequest((artistId, id) => {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-type': 'application/json', ...authHeader() },
  };

  return fetch(
    `${openstageApiCrudUrl}/parameter?artist_id=eq.${artistId}&id=eq.${id}`,
    requestOptions,
  );
});
