import { doFetchQRCodes } from '@/services/agent/qr_code.service';
import { getField, updateField } from 'vuex-map-fields';
import humps from 'lodash-humps';

export const qrList = {
  namespaced: true,
  state: () => ({
    list: [],
    inProgress: false,
    error: null,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    setList(state, list) {
      state.list = list;
    },
    setError(state, error) {
      state.error = error;
    },
  },
  actions: {
    fetchList: async ({ commit }, artistId) => {
      commit('inProgress', true);
      try {
        const list = await doFetchQRCodes(artistId);
        if (list?.status === 'ERROR') {
          throw new Error('failed');
        }
        if (list) commit('setList', humps(list));
        else commit('setList', []);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('inProgress', false);
      }
    },
    resetList: async ({ commit }) => {
      commit('setList', []);
    },
  },
};
