import type {
  AudienceCriteriaFilter,
  AudienceCriteriaFilterField,
  AudienceCriteriaFilterGroup,
  AudienceCriteriaFilterGroupAnd,
} from '@/interfaces/AudienceCriteria.interface';
import { featureFlags } from '@/services/util.service';

export interface AudienceFilterMetaConditionOption {
  value: any;
  title: string;
}
export interface AudienceFilterMeta {
  criteriaType: string;
  title: string;
  group: string;
  icon?: string;
  search?: string; // TODO add to each type
  conditionOptions?: AudienceFilterMetaConditionOption[];
  conditionDefault?: string;
  existsOptions?: AudienceFilterMetaConditionOption[];
  defaultFields?: AudienceCriteriaFilterField[];
  normalizeWithDefaults?: Function;
  legacy?: boolean;
}
// and then grouped:
export interface AudienceFilterGroupMeta {
  group: string;
  title?: string;
  icon?: string;
  items: AudienceFilterMeta[];
}

export const normalizeFilterWithDefaults = (
  filter: AudienceCriteriaFilter | AudienceCriteriaFilterGroup,
  isNestedGroup: boolean = false,
): AudienceCriteriaFilter | AudienceCriteriaFilterGroup | AudienceCriteriaFilterGroupAnd => {
  if (!filter?.type) return filter;
  const meta = getFilterMeta(filter.type);
  if (!meta) return filter;

  if (filter.type === 'group') {
    // TODO also prevent or groups within groups!
    return {
      type: filter.type,
      condition: isNestedGroup ? ('and' as const) : ('or' as const),
      filters: (filter.filters || []).map((m) => normalizeFilterWithDefaults(m, false)) as (
        | AudienceCriteriaFilter
        | AudienceCriteriaFilterGroupAnd
      )[],
    };
  }

  const newFilter: {
    type: string;
    exists?: boolean;
    condition?: string | null;
    fields?: AudienceCriteriaFilterField[];
  } = {
    type: filter.type,
  };
  const exists =
    'existsOptions' in meta
      ? 'exists' in filter &&
        (meta.existsOptions as AudienceFilterMetaConditionOption[]).find(
          (f) => f.value === filter.exists,
        )
        ? filter.exists
        : (meta.existsOptions as AudienceFilterMetaConditionOption[])[0].value
      : undefined;
  if (exists !== undefined) newFilter.exists = exists;
  const condition =
    'conditionOptions' in meta
      ? 'condition' in filter &&
        (meta.conditionOptions as AudienceFilterMetaConditionOption[]).find(
          (f) => f.value === filter.condition,
        )
        ? filter.condition
        : (meta.conditionOptions as AudienceFilterMetaConditionOption[])[0].value
      : undefined;
  if (condition !== undefined) newFilter.condition = condition;
  const fields =
    'defaultFields' in meta
      ? meta.defaultFields?.map((m) => ({
          name: m.name,
          value:
            'fields' in filter
              ? filter.fields?.find((f) => f.name === m.name)?.value || m.value
              : m.value,
        }))
      : undefined;
  if (fields !== undefined) newFilter.fields = fields;

  return newFilter as AudienceCriteriaFilter;
};

const getDateRangeFields = (): AudienceCriteriaFilterField[] => {
  return [
    {
      name: 'datetimeFrom',
      value: null,
    },
    {
      name: 'datetimeTo',
      value: null,
    },
    {
      name: 'daysSince',
      value: null,
    },
  ];
};

export const audienceFilterOptionsMeta: AudienceFilterMeta[] = [
  {
    criteriaType: 'activity', // 'activity',
    title: 'Been active',
    group: 'interaction',
    icon: 'mdi-timeline-clock-outline',
    search: 'engagement read listen play click link',
    legacy: true,
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
    conditionOptions: [
      { value: 'or', title: 'any of' },
      { value: 'and', title: 'all of' },
    ],
    defaultFields: [
      {
        name: 'metric',
        value: [],
      },
      ...getDateRangeFields(),
    ],
  },
  {
    criteriaType: 'age', // 'Fan Age Like',
    title: 'Age',
    group: 'fan-properties',
    icon: 'mdi-human-female-boy',
    search: 'young old birthday date',
    defaultFields: [
      {
        name: 'ageFrom',
        value: null,
      },
      {
        name: 'ageTo',
        value: null,
      },
    ],
  },
  {
    criteriaType: 'address', // 'hasAddress',
    title: 'Postal address',
    group: 'fan-properties',
    icon: 'mdi-truck-delivery-outline',
    search: 'home postal address road street zip postcode delivery package house',
    existsOptions: [
      { value: true, title: 'Have got' },
      { value: false, title: "Haven't got" },
    ],
    conditionOptions: [
      { value: null, title: 'on file' },
      { value: 'like', title: 'like' },
    ],
    defaultFields: [
      {
        name: 'address',
        value: null,
      },
    ],
  },
  {
    criteriaType: 'audience',
    title: 'In audience',
    group: 'other',
    icon: 'mdi-account-multiple-outline',
    search: 'audience segment group fan fans',
    existsOptions: [
      { value: true, title: 'Are' },
      { value: false, title: 'Are not' },
    ],
    defaultFields: [
      {
        name: 'audienceId',
        value: null,
      },
    ],
  },
  {
    criteriaType: 'broadcast', // 'broadcast',
    title: 'Broadcast',
    group: 'interaction',
    icon: 'mdi-bullhorn-outline',
    search: 'broadcast read unread sent send bounce complain complained unsubscribe unsubscribed',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
    defaultFields: [
      {
        name: 'broadcastId',
        value: null,
      },
      {
        name: 'action',
        value: 'sentAt',
      },
      ...getDateRangeFields(),
    ],
  },
  {
    criteriaType: 'callingCountry', // 'Country Calling Codes',
    title: 'Calling country code',
    group: 'location',
    icon: 'mdi-phone-plus-outline',
    search: 'phone telephone call ring dial country state code',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
    defaultFields: [
      {
        name: 'callingCountryCode',
        value: [],
      },
    ],
  },
  {
    criteriaType: 'city', // 'city',
    title: 'City',
    group: 'location',
    icon: 'mdi-city-variant-outline',
    search: 'city location lives place home town',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Have't" },
    ],
    defaultFields: [
      {
        name: 'cityId',
        value: [],
      },
    ],
  },
  {
    criteriaType: 'clickedLink', // 'link',
    title: 'Clicked link',
    group: 'interaction',
    icon: 'mdi-cursor-default-click-outline',
    search: 'clicked link url uri',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
    defaultFields: [
      {
        name: 'url',
        value: null,
      },
      {
        name: 'broadcastId',
        value: null,
      },
      ...getDateRangeFields(),
    ],
  },
  {
    criteriaType: 'consent', // 'consent',
    title: 'Given consent',
    group: 'permission',
    icon: 'mdi-lock-open-variant',
    search: 'sms email permission send',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
    conditionOptions: [
      { value: 'or', title: 'to any of' },
      { value: 'and', title: 'to all of' },
    ],
    defaultFields: [
      {
        name: 'consentType',
        value: [],
      },
    ],
  },
  {
    criteriaType: 'contactable',
    title: 'Contactable',
    group: 'permission',
    icon: 'mdi-handshake-outline',
    search: 'contact bot reach connect',
    existsOptions: [
      { value: true, title: 'Are' },
      { value: false, title: 'Are not' },
    ],
  },
  {
    criteriaType: 'country', // 'Magnet Located In Country',
    title: 'Country',
    group: 'location',
    icon: 'mdi-earth',
    search: 'usa location place country state',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
    defaultFields: [
      {
        name: 'countryCode',
        value: [],
      },
    ],
  },
  {
    criteriaType: 'email', // 'Fan Email Like',
    title: 'Email address',
    group: 'fan-properties',
    icon: 'mdi-email-outline',
    search: 'contact e-mail inbox',
    existsOptions: [
      { value: true, title: 'Have got' },
      { value: false, title: "Haven't got" },
    ],
    conditionOptions: [
      { value: null, title: 'on file' },
      { value: 'like', title: 'like' },
    ],
    defaultFields: [
      {
        name: 'email',
        value: null,
      },
    ],
  },
  {
    criteriaType: 'emailStatus', // 'Last Email Status Like',
    title: 'Email status',
    group: 'permission',
    icon: 'mdi-email-check',
    search: 'error status email',
    existsOptions: [
      { value: true, title: 'error' },
      { value: false, title: 'ok' },
    ],
  },
  {
    criteriaType: 'engagementScore', // 'Fan Engagement',
    title: 'Engagement',
    group: 'interaction',
    icon: 'mdi-counter',
    search: 'engagement score engage value activity active year',
    defaultFields: [
      {
        name: 'scoreFrom',
        value: null,
      },
      {
        name: 'scoreTo',
        value: null,
      },
    ],
  },
  {
    criteriaType: 'fanValue', // 'Fan Value',
    title: 'Fan value',
    group: 'fan-properties',
    icon: 'mdi-account-cash',
    search: 'cost spend shopify paid spent ticket merch value money purchase bought year',
    defaultFields: [
      {
        name: 'valueFrom',
        value: null,
      },
      {
        name: 'valueTo',
        value: null,
      },
    ],
  },
  {
    criteriaType: 'location', // 'Fan Has Location',
    title: 'Known location',
    group: 'location',
    icon: 'mdi-map-marker-question-outline',
    search: 'usa location lives place home latlong gps coordinates',
    existsOptions: [
      { value: true, title: 'Have got a' },
      { value: false, title: "Haven't got a" },
    ],
  },
  // {
  //   criteriaType: 'locationCombined', // new
  //   title: 'Location',
  //   group: 'location',
  //   icon: 'mdi-map-marker-outline',
  //   search: 'usa location lives place home latlong gps coordinates',
  //   existsOptions: [
  //     { value: true, title: 'Have' },
  //     { value: false, title: "Haven't" },
  //   ],
  //   defaultFields: [
  //     {
  //       name: "continentCode",
  //       value: null
  //     },
  //     {
  //       name: "countryCode",
  //       value: null
  //     },
  //     {
  //       name: "cityId",
  //       value: null
  //     },
  //   ],
  // },
  {
    criteriaType: 'messageHistory', // 'Fan Has Conversation',
    title: 'Messages',
    group: 'interaction',
    icon: 'mdi-message-processing-outline',
    search: 'SMS unread read text message',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
    defaultFields: [
      {
        name: 'readStatus',
        value: null,
      },
    ],
  },
  {
    criteriaType: 'name', // 'Fan Name Like',
    title: 'Name',
    group: 'fan-properties',
    icon: 'mdi-card-account-details-outline',
    search: 'surname forename familyname birthname christian',
    existsOptions: [
      { value: true, title: 'Have got' },
      { value: false, title: "Haven't got" },
    ],
    conditionOptions: [
      { value: null, title: 'on file' },
      { value: 'like', title: 'like' },
    ],
    defaultFields: [
      {
        name: 'name',
        value: null,
      },
    ],
  },
  {
    criteriaType: 'pageMetric', // 'pageMetric',
    title: 'Page interaction',
    group: 'interaction',
    icon: 'mdi-chart-box-outline',
    search: 'page metric action engagement interaction',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
    conditionOptions: [{ value: 'or', title: 'any of' }],
    defaultFields: [
      {
        name: 'type',
        value: null,
      },
      {
        name: 'pageId',
        value: null,
      },
      {
        name: 'eventId',
        value: null,
      },
      {
        name: 'metric',
        value: [],
      },
      ...getDateRangeFields(),
    ],
  },
  {
    criteriaType: 'phoneNumber', // 'Fan Phone Number Like',
    title: 'Phone number',
    group: 'fan-properties',
    icon: 'mdi-cellphone',
    search: 'telephone contact',
    existsOptions: [
      { value: true, title: 'Have got' },
      { value: false, title: "Haven't got" },
    ],
    conditionOptions: [
      { value: null, title: 'on file' },
      { value: 'like', title: 'like' },
    ],
    defaultFields: [
      {
        name: 'phoneNumber',
        value: null,
      },
    ],
  },
  {
    criteriaType: 'poll',
    title: 'Poll',
    group: 'interaction',
    icon: 'mdi-poll',
    search: 'poll vote answer answers question survey feedback opinion rate rating choice choose',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
    defaultFields: [
      {
        name: 'pageId',
        value: null,
      },
      {
        name: 'pollId',
        value: null,
      },
      {
        name: 'pollAnswerId',
        value: [],
      },
      ...getDateRangeFields(),
    ],
    legacy: !featureFlags.pollVersion2,
  },
  {
    criteriaType: 'presave', // 'Fan Has Presave',
    title: 'Presaved',
    group: 'fan-properties',
    icon: 'mdi-music-note-plus',
    search: 'spotify apple deezer listen music dsp streaming',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
    conditionOptions: [
      { value: 'or', title: 'any of' },
      { value: 'and', title: 'all of' },
    ],
    defaultFields: [
      {
        name: 'presave',
        value: [],
      },
    ],
  },
  {
    criteriaType: 'signedUp', // 'Fan Has Been Created',
    title: 'Signed up',
    group: 'interaction',
    icon: 'mdi-account-box-plus-outline',
    search: 'signed campaign account create',
    defaultFields: [...getDateRangeFields()],
  },
  {
    criteriaType: 'subscribedForEmail', // 'Fan Subscribed For Email',
    title: 'Subscribed for emails',
    group: 'permission',
    icon: 'mdi-email-open',
    search: 'e-mail inbox',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
  },
  {
    criteriaType: 'subscribedForNotifications', // 'Push Notifications Enabled',
    title: 'Subscribed for push notifications',
    group: 'permission',
    icon: 'mdi-cellphone-sound',
    search: 'phone notify alert mobile content ',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
  },
  {
    criteriaType: 'subscribedForSms', // 'Fan Subscribed For SMS',
    title: 'Subscribed for SMS',
    group: 'permission',
    icon: 'mdi-message-processing',
    search: 'text whatsapp inbox',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
  },
  {
    criteriaType: 'subscription', // 'subscription',
    title: 'Subscription',
    group: 'fan-properties',
    icon: 'mdi-cash-sync',
    search: 'paid page fanpage subscription tier exclusive content club zone fanzone',
    existsOptions: [
      { value: true, title: 'Are' },
      { value: false, title: "Aren't" },
    ],
    defaultFields: [
      {
        name: 'subscriptionId',
        value: [],
      },
    ],
  },
  {
    criteriaType: 'tag',
    title: 'Tags',
    group: 'fan-properties',
    icon: 'mdi-tag-outline',
    search: 'tag with without label labelled marked',
    existsOptions: [
      { value: true, title: 'Have got' },
      { value: false, title: "Haven't got" },
    ],
    conditionOptions: [
      { value: 'or', title: 'any of' },
      { value: 'and', title: 'all of' },
    ],
    defaultFields: [
      {
        name: 'tag',
        value: [],
      },
    ],
  },
  {
    criteriaType: 'tagCount', // 'Fan Has Number Of Tags',
    title: 'Tag count',
    group: 'fan-properties',
    icon: 'mdi-tag-multiple',
    search: 'tag with without label labelled marked count minimum maximum more less exactly equal',
    defaultFields: [
      {
        name: 'tagCountFrom',
        value: null,
      },
      {
        name: 'tagCountTo',
        value: null,
      },
    ],
  },
  {
    criteriaType: 'transaction', // 'transaction',
    title: 'Transaction',
    group: 'interaction',
    icon: 'mdi-cash',
    search: 'cost spend shopify paid spent ticket merch value money purchase date bought',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
    defaultFields: [
      {
        name: 'type',
        value: null,
      },
      {
        name: 'description',
        value: [],
      },
      {
        name: 'valueFrom',
        value: null,
      },
      {
        name: 'valueTo',
        value: null,
      },
      {
        name: 'volumeFrom',
        value: null,
      },
      {
        name: 'volumeTo',
        value: null,
      },
      ...getDateRangeFields(),
    ],
  },
  {
    criteriaType: 'upload', // 'Fan Has Upload',
    title: 'Uploaded',
    group: 'interaction',
    icon: 'mdi-cloud-upload-outline',
    search: 'upload image video text submit content',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
    defaultFields: [
      {
        name: 'pageId',
        value: null,
      },
      {
        name: 'type',
        value: null,
      },
      {
        name: 'text',
        value: null,
      },
      ...getDateRangeFields(),
    ],
  },
  {
    criteriaType: 'wswc', // 'whereShallWePlay',
    title: 'Asked you to come',
    group: 'location',
    icon: 'mdi-bus-marker',
    search: 'where shall we play should location wswp response',
    existsOptions: [
      { value: true, title: 'Have' },
      { value: false, title: "Haven't" },
    ],
    defaultFields: [
      {
        name: 'countryCode',
        value: null,
      },
      {
        name: 'cityId',
        value: null,
      },
    ],
  },
];

export const getFilterMeta = (type: string): AudienceFilterMeta | undefined => {
  if (!type) return undefined;
  return audienceFilterOptionsMeta.find((f) => f.criteriaType === type);
};

const nonLegacyAudienceFilterOptionsMeta = audienceFilterOptionsMeta.filter((f) => !f.legacy);

export const getGroupedAudienceFilterOptions = (
  includeAudiences?: boolean,
): AudienceFilterGroupMeta[] => {
  const groups = {} as Record<string, AudienceFilterGroupMeta>;
  if (includeAudiences) {
    groups.audience = {
      group: 'audience',
      title: 'Audience',
      // icon: 'mdi-account-multiple-outline',
      items: nonLegacyAudienceFilterOptionsMeta.filter((f) => f.group === 'audience'),
    };
  }
  groups.location = {
    group: 'location',
    title: 'Location',
    // icon: 'mdi-earth',
    items: nonLegacyAudienceFilterOptionsMeta.filter((f) => f.group === 'location'),
  };
  groups.interactions = {
    group: 'interaction',
    title: 'Interactions',
    // icon: 'mdi-badge-account-horizontal',
    items: nonLegacyAudienceFilterOptionsMeta.filter((f) => f.group === 'interaction'),
  };
  groups.fanProperties = {
    group: 'fan-properties',
    title: 'Fan Properties',
    // icon: 'mdi-badge-account-horizontal',
    items: nonLegacyAudienceFilterOptionsMeta.filter((f) => f.group === 'fan-properties'),
  };
  groups.permission = {
    group: 'permission',
    title: 'Permissions',
    // icon: 'mdi-badge-account-horizontal',
    items: nonLegacyAudienceFilterOptionsMeta.filter((f) => f.group === 'permission'),
  };

  return Object.values(groups);
};
