import {
  mapSummary,
  doFetchCities,
  doFetchAllCountries,
} from '@/services/agent/geographical.service';
import { getField, updateField } from 'vuex-map-fields';
import { doAudienceCountries } from '@/services/agent/fan.service';
import humps from 'lodash-humps';

export const geographicalOne = {
  namespaced: true,
  state: () => ({
    topCountries: [],
    topCities: [],
    target: {
      fans: 10,
      distance: 65,
    },
    inProgress: false,
    citiesInProgress: false,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setTopCountries(state, topCountries) {
      state.topCountries = topCountries;
    },
    setTopCities(state, topCities) {
      state.topCities = topCities;
    },
    setDistance(state, distance) {
      state.target.distance = distance;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    citiesInProgress(state, yesOrNo) {
      state.citiesInProgress = yesOrNo;
    },
  },
  actions: {
    fetchTopCountries: async ({ commit, rootState }) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id');
        return;
      }
      try {
        commit('inProgress', true);
        const top = await doAudienceCountries(rootState.artistOne.one.id, null, [], []);
        if (Array.isArray(top.items)) commit('setTopCountries', humps(top.items));
        else commit('setTopCountries', []);
        commit('inProgress', false);
      } catch (err) {
        commit('inProgress', false);
        throw err;
      }
    },
    fetchTopCities: async ({ commit, rootState }, { countries = null } = {}) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id');
        return;
      }
      try {
        commit('citiesInProgress', true);
        const citiesList = await mapSummary(rootState.artistOne.one.id, countries);
        if (citiesList) commit('setTopCities', citiesList);
        else commit('setTopCities', []);
        commit('citiesInProgress', false);
      } catch (err) {
        commit('citiesInProgress', false);
        throw err;
      }
    },
    getCityById: async ({ commit }, id) => {
      try {
        commit('inProgress', true);
        const response = await doFetchCities(null, id);
        if (!response || response?.status === 'ERROR') {
          throw new Error(response.message);
        }
        commit('inProgress', false);
        return response;
      } catch (err) {
        commit('inProgress', false);
        return null;
      }
    },
    getCountryByCode: async ({ commit }, code) => {
      try {
        commit('inProgress', true);
        const response = await doFetchAllCountries(code);
        if (!response || response?.status === 'ERROR') {
          throw new Error(response.message);
        }
        commit('inProgress', false);
        return response;
      } catch (err) {
        commit('inProgress', false);
        return null;
      }
    },
    setDistance: async ({ commit }, distance) => {
      commit('setDistance', distance);
    },
    resetOne: async ({ commit }) => {
      commit('setTopCities', []);
      commit('setTopCountries', []);
    },
  },
};
