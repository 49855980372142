/**
 * COLOR utilities
 */

export const hasEnoughContrast = (color1, color2) => {
  // Convert hex color strings to RGB values
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  // Calculate the contrast ratio
  const contrast = getContrastRatio(rgb1, rgb2);

  // Check if the contrast ratio meets the minimum requirement
  // http://web-accessibility.carnegiemuseums.org/design/color/#:~:text=%E2%80%9CWCAG%20(Web%20Content%20Accessibility%20Guidelines,of%20at%20least%204.5%3A1.
  return contrast >= 4.5;
};

// Helper function to convert hex color string to RGB object
export const hexToRgb = (hex) => {
  // Remove the "#" symbol if present
  const hexValue = hex.replace('#', '');

  // Handle short hex format (eg. #fff)
  if (hexValue.length === 3) {
    const [r, g, b] = hexValue.split('').map((component) => parseInt(component + component, 16));
    return { r, g, b };
  }

  // Handle long hex format (eg. #ffffff)
  const [r, g, b] = hexValue.match(/\w{2}/g).map((component) => parseInt(component, 16));
  return { r, g, b };
};

// Helper function to calculate contrast ratio between two RGB colors
export const getContrastRatio = (rgb1, rgb2) => {
  // Calculate relative luminance of each color
  let luminance1 = calculateRelativeLuminance(rgb1);
  let luminance2 = calculateRelativeLuminance(rgb2);

  // Ensure luminance1 is the lighter color
  if (luminance1 < luminance2) {
    [luminance1, luminance2] = [luminance2, luminance1];
  }

  // Calculate the contrast ratio
  return (luminance1 + 0.05) / (luminance2 + 0.05);
};

// Helper function to calculate relative luminance
export const calculateRelativeLuminance = (rgb) => {
  const { r, g, b } = rgb;
  const sRGB = [r, g, b].map((val) => {
    const sRGBValue = val / 255;
    return sRGBValue <= 0.03928 ? sRGBValue / 12.92 : Math.pow((sRGBValue + 0.055) / 1.055, 2.4);
  });

  const [R, G, B] = sRGB;

  // Calculate relative luminance using formula: 0.2126 * R + 0.7152 * G + 0.0722 * B
  return 0.2126 * R + 0.7152 * G + 0.0722 * B;
};

// Colors class used for color palette generation
export class PageColors {
  static hslRegex = /^(\d{1,3}), (\d{1,3})%, (\d{1,3})%/;

  constructor(cssVariablePrefix, base, text, accent, accentLabel) {
    this._cssVariablePrefix = cssVariablePrefix;
    this._base = PageColors.parseHSLColor(base);
    this._text = text
      ? PageColors.parseHSLColor(text)
      : PageColors.getComplementer(this._base, 150);
    this._accent = accent
      ? PageColors.parseHSLColor(accent)
      : PageColors.getComplementer(this._base, 210); // or this._text?
    this._accentLabel = accentLabel
      ? PageColors.parseHSLColor(accentLabel)
      : PageColors.getTextColorAgainst(this._accent);

    this._error = PageColors.getErrorColorAgainst(this._base);
  }

  get accentColor() {
    return this._accent;
  }
  get cssVars() {
    const vars = {};
    vars[`${this._cssVariablePrefix}--base`] = PageColors.hslToString(this._base);
    vars[`${this._cssVariablePrefix}--text`] = PageColors.hslToString(this._text);
    vars[`${this._cssVariablePrefix}--text--error`] = PageColors.hslToString(this._error);
    vars[`${this._cssVariablePrefix}--accent`] = PageColors.hslToString(this._accent);
    vars[`${this._cssVariablePrefix}--accent--label`] = PageColors.hslToString(this._accentLabel);
    vars[`${this._cssVariablePrefix}--accent--hover`] = PageColors.hslToString(
      PageColors.getHoverColorFor(this._accent),
    );
    vars[`${this._cssVariablePrefix}--accent--disabled`] = PageColors.hslToString(
      PageColors.getDisabledColorFor(this._accent),
    );
    return vars;
  }
  static hslToString(hslColor) {
    const { h, s, l } = hslColor;
    return `${h}, ${s}%, ${l}%`;
  }
  static rgbToHsl(rgbColor) {
    // Remove 'rgb(' and ')' from the input string
    const values = rgbColor.substring(4, rgbColor.length - 1).split(',');

    // Convert RGB values to the range [0, 1]
    const r = parseInt(values[0]) / 255;
    const g = parseInt(values[1]) / 255;
    const b = parseInt(values[2]) / 255;

    // Find the maximum and minimum values to calculate lightness and saturation
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    // Calculate lightness (L)
    let l = (max + min) / 2;
    let h, s;

    if (max === min) {
      // Achromatic (gray)
      h = 0;
      s = 0;
    } else {
      // Calculate saturation (S)
      s = l > 0.5 ? (max - min) / (2 - max - min) : (max - min) / (max + min);

      // Calculate hue (H)
      if (max === r) {
        h = (g - b) / (max - min) + (g < b ? 6 : 0);
      } else if (max === g) {
        h = (b - r) / (max - min) + 2;
      } else {
        h = (r - g) / (max - min) + 4;
      }

      h /= 6;
    }

    // Round H, S, and L to reasonable precision
    h = Math.round(h * 360);
    s = Math.round(s * 100);
    l = Math.round(l * 100);

    return { h, s, l };
  }
  static hslToRgb(hslColor) {
    let h = hslColor.h;
    let s = hslColor.s;
    let l = hslColor.l;
    // Ensure h, s, and l are in the correct range
    h = ((h % 360) + 360) % 360; // Make sure h is between 0 and 360
    s = Math.max(0, Math.min(100, s)); // Clamp s between 0 and 100
    l = Math.max(0, Math.min(100, l)); // Clamp l between 0 and 100

    // Convert h, s, and l to values between 0 and 1
    h /= 360;
    s /= 100;
    l /= 100;

    // Calculate the RGB values
    let r, g, b;
    if (s === 0) {
      r = g = b = l; // Achromatic (gray) color
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;

      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    // Convert the RGB values to integers in the range [0, 255] and create the RGB string
    r = Math.round(r * 255);
    g = Math.round(g * 255);
    b = Math.round(b * 255);

    return `rgb(${r}, ${g}, ${b})`;
  }
  static hexToHsl(hexString) {
    let hex = hexString.replace('#', '');
    const hexRegex3 = /^([0-9A-Fa-f]{3})$/;
    const hexRegex6 = /^([0-9A-Fa-f]{6})$/;
    if (!hex.match(hexRegex3) && !hex.match(hexRegex6)) {
      return { h: 0, s: 0, l: 0 };
    }
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map((char) => `${char}${char}`)
        .join('');
    }
    const red = parseInt(hex.slice(0, 2), 16);
    const green = parseInt(hex.slice(2, 4), 16);
    const blue = parseInt(hex.slice(4, 6), 16);
    const normalizedRed = red / 255;
    const normalizedGreen = green / 255;
    const normalizedBlue = blue / 255;
    const min = Math.min(normalizedRed, normalizedGreen, normalizedBlue);
    const max = Math.max(normalizedRed, normalizedGreen, normalizedBlue);
    const lightness = (min + max) / 2;
    let saturation = 0;
    if (min !== max) {
      const delta = max - min;
      saturation = lightness > 0.5 ? delta / (2 - max - min) : delta / (max + min);
    }
    let hue = 0;
    if (min !== max) {
      if (max === normalizedRed) hue = (normalizedGreen - normalizedBlue) / (max - min);
      else if (max === normalizedGreen) hue = 2 + (normalizedBlue - normalizedRed) / (max - min);
      else hue = 4 + (normalizedRed - normalizedGreen) / (max - min);
      hue *= 60;
      if (hue < 0) hue += 360;
    }
    return {
      h: Math.round(hue),
      s: Math.round(saturation * 100),
      l: Math.round(lightness * 100),
    };
  }
  static hslToHex(hslColor) {
    if (!hslColor) {
      return null;
    }
    const { h, s, l } = hslColor;
    if (!h && !s && !l) return '#000000';
    const newL = l / 100;
    const a = (s * Math.min(newL, 1 - newL)) / 100;
    const f = (n) => {
      const k = (n + h / 30) % 12;
      const color = newL - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0');
    };
    return `#${f(0)}${f(8)}${f(4)}`.toUpperCase();
  }
  static parseHSLColor(colorString) {
    try {
      if (colorString.startsWith('#')) {
        return PageColors.hexToHsl(colorString);
      }
      if (!PageColors.hslRegex.test(colorString)) {
        throw new Error(`A Invalid HSL color format: ${colorString}`);
      }
      const matches = colorString.match(PageColors.hslRegex);
      if (!matches) {
        throw new Error(`B Invalid HSL color format: ${colorString}`);
      }
      const h = parseInt(matches[1]);
      const s = parseInt(matches[2]);
      const l = parseInt(matches[3]);
      return { h, s, l };
    } catch (error) {
      return { h: 0, s: 0, l: 0 }; // black as default?
    }
  }
  static getComplementer(hslColor, wheelShift = 180) {
    const { h, s, l } = hslColor;
    const complementaryHue = (h + wheelShift + 360) % 360;
    return {
      h: complementaryHue,
      s: Math.max(s, 50), // max, sure?
      l: Math.abs(l - 100),
    };
  }
  static hslToRelativeLuminance(s, l) {
    const normalizedS = s / 100;
    const normalizedL = l / 100;
    const r = (normalizedL + normalizedS * Math.min(normalizedL, 1 - normalizedL)) / 2;
    const g = r;
    const b = (normalizedL - normalizedS * Math.min(normalizedL, 1 - normalizedL)) / 2;
    const gammaCorrect = (color) =>
      color <= 0.03928 ? color / 12.92 : ((color + 0.055) / 1.055) ** 2.4;
    const R = gammaCorrect(r);
    const G = gammaCorrect(g);
    const B = gammaCorrect(b);

    return 0.2126 * R + 0.7152 * G + 0.0722 * B;
  }
  static getTextColorAgainst(hslColor) {
    const { h, s, l } = hslColor;
    const blackLuminance = PageColors.hslToRelativeLuminance(s, 0);
    const whiteLuminance = PageColors.hslToRelativeLuminance(s, 100);
    const colorLuminance = PageColors.hslToRelativeLuminance(s, l);
    const contrastWithBlack = (colorLuminance + 0.05) / (blackLuminance + 0.05);
    const contrastWithWhite = (whiteLuminance + 0.05) / (colorLuminance + 0.05);
    return {
      h,
      s,
      l: contrastWithBlack > contrastWithWhite ? 10 : 90, // not not pitch color
    };
  }
  static getErrorColorAgainst(hslColor) {
    const { s, l } = hslColor;
    const defaultErrorColor = { h: 356, s: 72, l: 48 };
    const colorLuminance = PageColors.hslToRelativeLuminance(s, l);
    const defaultErrorLuminance = PageColors.hslToRelativeLuminance(
      defaultErrorColor.s,
      defaultErrorColor.l,
    );
    const lighterErrorLuminance = PageColors.hslToRelativeLuminance(
      defaultErrorColor.s,
      Math.min(defaultErrorColor.l + 25, 100),
    );
    const darkerErrorLuminance = PageColors.hslToRelativeLuminance(
      defaultErrorColor.s,
      Math.max(defaultErrorColor.l - 25, 0),
    );
    const contrastWithDefault = (defaultErrorLuminance + 0.05) / (colorLuminance + 0.05);
    const contrastWithLighter = (lighterErrorLuminance + 0.05) / (colorLuminance + 0.05);
    const contrastWithDarker = (colorLuminance + 0.05) / (darkerErrorLuminance + 0.05);
    const adjust =
      contrastWithDefault > 2.0 ? 0 : contrastWithLighter > contrastWithDarker ? 15 : -15;
    return {
      h: defaultErrorColor.h,
      s: defaultErrorColor.s,
      l: defaultErrorColor.l + adjust,
    };
  }
  static getDisabledColorFor(hslColor) {
    const { h, l } = hslColor;
    return { h, s: 15, l };
  }

  static getHoverColorFor(hslColor) {
    const { h, s, l } = hslColor;
    const adjust = l > 85 ? -15 : 15;
    return { h, s, l: l + adjust };
  }
}
