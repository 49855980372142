import { doFetchList, spotifySearch } from '@/services/agent/catalog.service';
import { getField, updateField } from 'vuex-map-fields';

export const catalogList = {
  namespaced: true,
  state: () => ({
    list: null,
    inProgress: false,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setList(state, list) {
      state.list = list;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
  },
  actions: {
    fetchList: async ({ commit, rootState }, { artistId } = {}) => {
      try {
        if (!artistId && !rootState.artistOne?.one?.id)
          throw new Error('Artist not found, no id given');
        const list = await doFetchList(artistId || rootState.artistOne.one.id);
        commit('setList', list || []);
      } catch (err) {
        console.error(err);
      }
    },
    setList: async ({ commit }, list) => {
      commit('setList', list);
    },
    resetList: async ({ commit }) => {
      commit('setList', []);
    },
    getTrackById: async ({ commit }, id) => {
      try {
        commit('inProgress', true);
        const response = await spotifySearch({ trackId: id });
        if (response.status === 'ERROR') {
          throw new Error(response.message);
        }
        commit('inProgress', false);
        return response;
      } catch (err) {
        commit('inProgress', false);
        throw err;
      }
    },
  },
};
