import { doFetchList, doLookup } from '@/services/agent/artist.service';
import { getField, updateField } from 'vuex-map-fields';
import humps from 'lodash-humps';

export const artistList = {
  namespaced: true,
  state: () => ({
    list: [],
    recordsCount: 0,
    inProgress: false,
    error: null,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    setList(state, list) {
      state.list = list;
    },
    setListCount(state, number) {
      state.recordsCount = number;
    },
  },
  actions: {
    fetchList: async ({ commit }, { organizationId }) => {
      commit('inProgress', true);
      try {
        const response = await doFetchList(organizationId);
        const list = await response.data;
        if (list) {
          commit('setList', humps(list));
          commit('setListCount', list.size);
        } else {
          commit('setList', []);
        }
      } finally {
        commit('inProgress', false);
      }
    },
    lookUpList: async ({ commit }, { search, organizationId }) => {
      commit('inProgress', true);
      try {
        const response = await doLookup(search, organizationId);
        const list = await response.data;
        if (list) {
          commit('setList', humps(list));
          commit('setListCount', list.size);
        }
      } finally {
        commit('inProgress', false);
      }
    },
    resetList: async ({ commit }) => {
      commit('setList', []);
      commit('setListCount', 0);
    },
  },
};
