import {
  moveOrganization,
  doFetchOne,
  doFetchOneByShortName,
  doPostOne,
  doPatchOne,
  doFetchArtistMetrics,
  doDeleteOne,
  doCallingCountryPrice,
  artistFanHistory,
  artistEmailHistory,
  doFetchParams,
  doSyncShopifyShop,
  doDisconnectIntegration,
  doConnectAtvenueToken,
  doConnectOchreStore,
  doConnectBandsintownAccount,
  doConnectSeatedAccount,
} from '@/services/agent/artist.service';
import { doFetchArtistFanTags } from '@/services/agent/fan.service';
import { doDisconnectShopify } from '@/services/agent/oauth.service';
import { sanitizeTags } from '@/services/util.service';
import { doFetchSmsCredit } from '@/services/agent/organization.service'; // ADAM - Ask if this is a ORG or ARTIST service, and then move appropriately
import { getField, updateField } from 'vuex-map-fields';
import humps from 'lodash-humps';
import createHumps from 'lodash-humps/lib/createHumps';
import { snakeCase } from 'lodash';
const snakes = createHumps(snakeCase);
import moment from 'moment';
import { useCachedQueryStore } from '../pinia/query.cached.store';
import { useFilteredParameters } from '@/_helpers/parameters';

const getDateArray = function (start, end) {
  var arr = new Array(),
    dt = new Date(start);
  while (dt <= end) {
    arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
};

export const artistOne = {
  namespaced: true,
  state: () => ({
    //use a emptyOne template?
    one: {},
    oneUpdated: {},
    fanHistoryMetrics: null,
    fanHistoryMetricsInProgress: false,
    emailHistoryMetrics: null,
    emailHistoryMetricsInProgress: false,
    artistAudienceMetrics: null,
    smsDetails: null,
    smsDetailsFailed: false,
    smsDetailsInProgress: false,
    inProgress: true,
    artistAudienceMetricsInProgress: false,
    // statsDateRange: -1,
    statsDateRange: null,
    dashboardMetricsHasFans: false,
    dashboardMetricsHasIncome: false,
    dashboardMetricsHasEngagement: false,
    callingCountries: null,
    callingCountriesInProgress: false,
    callingCountriesError: '',
    artistFanTags: [],
    artistFanTagsInProgress: false,
    parameters: [],
  }),
  getters: {
    getField,
    homepageId: (state) => {
      return state?.one?.pageId || null;
    },
    currencyFromPriceList: (state) => {
      const entries = Object.entries(state.callingCountries || {});
      if (!entries?.length) return null;
      return entries[0].currency || null;
    },
    refreshFanTotalInProgress: () => {
      return useCachedQueryStore().allFansQuerySummaryInProgress;
    },
    getShopifyStores: (state) => {
      return useFilteredParameters(state.parameters, 'shopify');
    },
    getBandsintownAccount: (state) => {
      const bandsInTownAccount = useFilteredParameters(state.parameters, 'bandsintown');

      return {
        store: bandsInTownAccount,
        artistId: bandsInTownAccount.find((item) => item.key === 'artistId')?.value,
        artistName: bandsInTownAccount.find((item) => item.key === 'artistName')?.value,
        thumbUrl: bandsInTownAccount.find((item) => item.key === 'artistThumbUrl')?.value,
        artistUrl: bandsInTownAccount.find((item) => item.key === 'artistUrl')?.value,
      };
    },
    getBigCartelToken: (state) => {
      return useFilteredParameters(state.parameters, 'bigCartel').find(
        (item) => item.key === 'token',
      )?.value;
    },
    getOchreAccount: (state) => {
      const ochreStore = useFilteredParameters(state.parameters, 'ochre');

      return {
        store: ochreStore,
        ochreClientId: ochreStore.find((item) => item.key === 'clientId')?.value,
        ochreArtistId: ochreStore.find((item) => item.key === 'artistId')?.value,
        ochreClientSecret: ochreStore.find((item) => item.key === 'clientSecret')?.value,
        ochreStoreId: ochreStore.find((item) => item.key === 'storeId')?.value,
      };
    },
    getMoshtixAccount: (state) => {
      const moshtixStore = useFilteredParameters(state.parameters, 'moshtix');

      return {
        store: moshtixStore,
        clientId: moshtixStore.find((item) => item.key === 'clientId')?.value,
        token: moshtixStore.find((item) => item.key === 'token')?.value,
      };
    },
    getAtvenuAccount: (state) => {
      const atVenuStore = useFilteredParameters(state.parameters, 'atvenu');
      return {
        store: atVenuStore,
        token: atVenuStore.find((item) => item.key === 'token')?.value,
      };
    },
    getSeatedAccount: (state) => {
      const seatedStore = useFilteredParameters(state.parameters, 'seated');
      return {
        store: seatedStore,
        artistId: seatedStore.find((item) => item.key === 'artistId')?.value,
      };
    },
    getInstagramAccount: (state) => {
      const intagramStore = useFilteredParameters(state.parameters, 'instagram');
      return {
        store: intagramStore,
        token: intagramStore.find((item) => item.key === 'token')?.value,
        userId: intagramStore.find((item) => item.key === 'userId')?.value,
      };
    },
    totalFans: (state) => {
      const cachedQueryStore = useCachedQueryStore();
      if (cachedQueryStore.allFansQuerySummaryFailed) {
        console.log(
          'Error refreshing fan total - falling back to artistStats',
          state.artistAudienceMetrics?.allTimeContactable,
        );
        if (state.artistAudienceMetricsInProgress) return 0;
        return state.artistAudienceMetrics?.allTimeContactable || 0;
      }
      return cachedQueryStore.allFansQuerySummaryCount;
    },
  },
  mutations: {
    updateField,
    setOne(state, one) {
      if (!one.homepageParameters) {
        one.homepageParameters = {};
      }
      state.one = one;
      //Use initial and updated objects instead of the values below - tidyup
      state.statsDateRange = localStorage.getItem('dateRange') || -1;
      state.dashboardMetricsHasFans = false;
      state.dashboardMetricsHasIncome = false;
      state.dashboardMetricsHasEngagement = false;
    },
    setOneUpdated(state) {
      state.oneUpdated.wallet_coupon_design = state.one.wallet_coupon_design || null;
    },
    setWallet(state, data) {
      state.oneUpdated.wallet_coupon_design = data;
    },
    setWalletValue(state, data) {
      state.oneUpdated.wallet_coupon_design[data.key] = data.value;
    },
    setDateRange(state, range) {
      state.statsDateRange = range;
    },
    setDashboardMetric(state, data) {
      if (data.key === 'fans') {
        state.dashboardMetricsHasFans = data.value;
      }
      if (data.key === 'income') {
        state.dashboardMetricsHasIncome = data.value;
      }
      if (data.key === 'engagement') {
        state.dashboardMetricsHasEngagement = data.value;
      }
    },
    setFanHistoryMetrics(state, metrics) {
      state.fanHistoryMetrics = metrics;
    },
    setFanHistoryMetricsInProgress(state, yesOrNo = false) {
      state.fanHistoryMetricsInProgress = yesOrNo || false;
    },
    setEmailHistoryMetrics(state, metrics) {
      state.emailHistoryMetrics = metrics;
    },
    artistAudienceMetrics(state, artistAudienceMetrics) {
      const {
        yesterdayContactable = 0,
        dayBeforeContactable = 0,
        lastWeekContactable = 0,
        weekBeforeContactable = 0,
        lastMonthContactable = 0,
        monthBeforeContactable = 0,
        lastQuarterContactable = 0,
        quarterBeforeContactable = 0,
        lastYearContactable = 0,
        yearBeforeContactable = 0,
        allTimeContactable = 0,
        yesterdayValue = 0,
        lastWeekValue = 0,
        lastMonthValue = 0,
        lastQuarterValue = 0,
        lastYearValue = 0,
        allTimeValue = 0,
        dayBeforeValue = 0,
        weekBeforeValue = 0,
        monthBeforeValue = 0,
        quarterBeforeValue = 0,
        yearBeforeValue = 0,
        yesterdayEngagement = 0,
        lastWeekEngagement = 0,
        lastMonthEngagement = 0,
        lastQuarterEngagement = 0,
        lastYearEngagement = 0,
        allTimeEngagement = 0,
        dayBeforeEngagement = 0,
        weekBeforeEngagement = 0,
        monthBeforeEngagement = 0,
        quarterBeforeEngagement = 0,
        yearBeforeEngagement = 0,
        yesterdayPresave = 0,
        lastWeekPresave = 0,
        lastMonthPresave = 0,
        lastQuarterPresave = 0,
        lastYearPresave = 0,
        allTimePresave = 0,
        ...rest
      } = artistAudienceMetrics;

      state.artistAudienceMetrics = {
        yesterdayContactable,
        dayBeforeContactable,
        lastWeekContactable,
        weekBeforeContactable,
        lastMonthContactable,
        monthBeforeContactable,
        lastQuarterContactable,
        quarterBeforeContactable,
        lastYearContactable,
        yearBeforeContactable,
        allTimeContactable,
        yesterdayValue,
        lastWeekValue,
        lastMonthValue,
        lastQuarterValue,
        lastYearValue,
        allTimeValue,
        dayBeforeValue,
        weekBeforeValue,
        monthBeforeValue,
        quarterBeforeValue,
        yearBeforeValue,
        yesterdayEngagement,
        lastWeekEngagement,
        lastMonthEngagement,
        lastQuarterEngagement,
        lastYearEngagement,
        allTimeEngagement,
        dayBeforeEngagement,
        weekBeforeEngagement,
        monthBeforeEngagement,
        quarterBeforeEngagement,
        yearBeforeEngagement,
        yesterdayPresave,
        lastWeekPresave,
        lastMonthPresave,
        lastQuarterPresave,
        lastYearPresave,
        allTimePresave,
        ...rest,
      };
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    artistAudienceMetricsInProgress(state, yesOrNo) {
      state.artistAudienceMetricsInProgress = yesOrNo;
    },
    smsDetails(state, value) {
      state.smsDetails = value;
    },
    smsDetailsFailed(state, value) {
      state.smsDetailsFailed = value || false;
    },
    smsDetailsInProgress(state, value) {
      state.smsDetailsInProgress = value || false;
    },
    callingCountries(state, callingCountries = null) {
      state.callingCountries = callingCountries;
    },
    parameters(state, params = []) {
      state.parameters = params || [];
    },
    callingCountriesInProgress(state, yesOrNo = false) {
      state.callingCountriesInProgress = yesOrNo;
    },
    callingCountriesError(state, error = '') {
      state.callingCountriesError = error;
    },
    setArtistFanTags(state, list = []) {
      state.artistFanTags = sanitizeTags(list);
    },
    setArtistFanTagsInProgress(state, yesOrNo = false) {
      state.artistFanTagsInProgress = yesOrNo || false;
    },
    setEmailHistoryMetricsInProgress(state, yesOrNo = false) {
      state.emailHistoryMetricsInProgress = yesOrNo || false;
    },
  },
  actions: {
    new({ commit }) {
      commit('inProgress', false);
      commit('setOne', {
        name: '',
        shortName: '',
        avatarUrl: '',
        facebookUrl: '',
        twitterUrl: '',
        spotifyUrl: '',
        appleUrl: '',
        deezerUrl: '',
        instagramUrl: '',
        tiktokUrl: '',
        youtubeUrl: '',
        twitchUrl: '',
        discordUrl: '',
        websiteUrl: '',
        // TODO - add other field defaults?
        // messagingSenderNumber: null,
        // twilioUkVerificationStatus: null,
        // messagingSenderNumberUs: null,
        // twilioUsVerificationStatus: null,
        // voicemailMessage: null,
        // twilioCallAppSid: null,
        // smsHelpText: null,
        // smsBudget: null, // used?
        // smsBudgetStartAt: null, // used?
      });
    },
    fetchOne: async ({ commit, dispatch, rootState }, { artistId, artistShortName, recursive }) => {
      try {
        commit('inProgress', true);
        const list = artistShortName
          ? await doFetchOneByShortName(artistShortName)
          : await doFetchOne(artistId);

        if (!list) throw new Error('Could not get artist details');
        const one = humps(list[0]);

        // Map old property to new property
        if (!one.appleUrl && one.homepageParameters && one.homepageParameters.appleMusicUrl) {
          one.appleUrl = one.homepageParameters.appleMusicUrl;
        }

        // Get the demo working for Wallet then fix casing on BE
        one.wallet_coupon_design = one.walletCouponDesign;

        commit('setOne', one);
        commit('setOneUpdated'); // add all other artist properties soon (or move to pinia). JSON.parse(JSON.stringify(one)));

        dispatch('fetchParameters');
        dispatch('refreshFanTotal');

        commit('inProgress', false);
        if (recursive && one.organizationId != rootState.organizationOne.one.id) {
          dispatch('organizationOne/fetchOne', one.organizationId, { root: true });
        }
      } catch (err) {
        commit('inProgress', false);
        throw err;
      }
    },
    fetchArtistAudienceMetrics: async ({ commit }, artistId) => {
      try {
        commit('artistAudienceMetricsInProgress', true);
        const data = await doFetchArtistMetrics({ artist_id: artistId });
        const metrics = humps(data);
        commit('artistAudienceMetricsInProgress', false);
        commit('artistAudienceMetrics', metrics);
      } catch (err) {
        commit('artistAudienceMetricsInProgress', false);
        throw err;
      }
    },
    fetchFanHistoryMetrics: async ({ commit }, { artistId }) => {
      commit('setFanHistoryMetricsInProgress', true);
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await artistFanHistory(artistId);
        if (!Array.isArray(result)) throw new Error('Could not fetch fan history metrics');
        const resultWithTimestamp = result
          .map((m) => {
            const dateString = m.day.split(', ');
            return {
              ...m,
              day: `${dateString[0]} ${dateString[1]}`,
              timestamp: new Date(m.day).getTime(),
            };
          })
          .sort((a, b) => a.timestamp - b.timestamp);
        // Generate all dates between first data record and today
        let endDate = new Date();
        let startDate = new Date();
        const oneDay = 1000 * 3600 * 24;
        if (resultWithTimestamp[0]?.day) {
          startDate = new Date(new Date(resultWithTimestamp[0].day) - oneDay);
        }
        const dateArray = getDateArray(Date.parse(startDate), Date.parse(endDate));

        let totalFanHistoryCount = 0;
        const resultWithAllDates = dateArray.map((m) => {
          const day = moment(m).format('MMM D YYYY');
          const dayData = resultWithTimestamp.find((f) => f.day === day);
          if (dayData) {
            totalFanHistoryCount += dayData.count;
          }
          return {
            day,
            count: dayData?.count || 0,
            total: totalFanHistoryCount,
          };
        });

        const storeObject = {
          daily: resultWithAllDates,
        };
        commit('setFanHistoryMetrics', storeObject);
      } catch (err) {
        throw err;
      }
      commit('setFanHistoryMetricsInProgress');
    },
    fetchEmailHistoryMetrics: async ({ commit }, { artistId }) => {
      commit('setEmailHistoryMetricsInProgress', true);
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await artistEmailHistory(artistId);

        const resultWithTimestamp = result
          .map((m) => {
            const dateString = m.day.split(', ');
            return {
              ...m,
              day: `${dateString[0]} ${dateString[1]}`,
              timestamp: new Date(m.day).getTime(),
            };
          })
          .sort((a, b) => a.timestamp - b.timestamp);

        // Generate all dates between first data record and today
        let endDate = new Date();
        let startDate = new Date();
        const oneDay = 1000 * 3600 * 24;
        if (resultWithTimestamp[0]?.day) {
          startDate = new Date(new Date(resultWithTimestamp[0].day) - oneDay);
        }
        const dateArray = getDateArray(Date.parse(startDate), Date.parse(endDate));

        let totalSentCount = 0;
        let totalReadCount = 0;
        const resultWithAllDates = dateArray.map((m) => {
          const day = moment(m).format('MMM D YYYY');
          const dayData = resultWithTimestamp.find((f) => f.day === day);
          if (dayData) {
            totalSentCount += dayData.sent;
            totalReadCount += dayData.read;
          }
          return {
            day,
            bounced: dayData?.bounced || 0,
            complained: dayData?.complained || 0,
            read: dayData?.read || 0,
            sent: dayData?.sent || 0,
            unsubscribed: dayData?.unsubscribed || 0,
          };
        });

        const storeObject = {
          daily: resultWithAllDates,
          sentCount: totalSentCount,
          readCount: totalReadCount,
        };
        commit('setEmailHistoryMetrics', storeObject);
      } catch (err) {
        throw err;
      }
      commit('setEmailHistoryMetricsInProgress');
    },
    fetchSMSDetails: async ({ commit }, artistId) => {
      commit('smsDetails', null);
      commit('smsDetailsFailed', false);
      commit('smsDetailsInProgress', true);
      const apiData = await doFetchSmsCredit(artistId);
      if (apiData && 'credit' in apiData) {
        const apiDataCamelCase = humps(apiData);
        commit('smsDetails', apiDataCamelCase);
      } else {
        commit('smsDetails', null);
        commit('smsDetailsFailed', true);
      }
      commit('smsDetailsInProgress', false);
    },
    save: async ({ commit, state, rootState }, newData) => {
      commit('inProgress', true);
      if (!state.one?.organizationId && !newData.organizationId)
        newData.organizationId = rootState.organizationOne?.one?.id || null;
      try {
        if (undefined === state.one.id || newData.new) {
          delete newData.new;
          const artist = await doPostOne(snakes(Object.assign({}, newData)));
          if (artist) {
            return { new: true, id: artist.id, shortName: artist.short_name };
          } else {
            throw 'Could not save artist';
          }
        } else {
          const patch = {
            ...snakes({ ...state.one, ochreClientSecret: undefined }), // Remove ochreClientSecret because unless we are updating it it will get double encrypted.
            ...state.oneUpdated, // Remove ochreClientSecret because unless we are updating it it will get double encrypted.
            ...snakes(Object.assign({}, newData)),
          };
          const artist = await doPatchOne(patch);
          if (artist) {
            commit('setOne', humps(artist));
            commit('inProgress', false);
            return { update: true };
          } else {
            throw 'Could not save artist';
          }
        }
      } catch (err) {
        commit('inProgress', false);
        throw err;
      }
    },
    moveArtist: async ({ commit }, data) => {
      const { artist, organizationId } = data;
      commit('inProgress', true);
      try {
        await moveOrganization(artist.id, organizationId);
        commit('inProgress', false);
        return { update: true };
      } catch (err) {
        commit('inProgress', false);
        throw err;
      }
    },
    delete: async ({ commit, state }) => {
      commit('inProgress', true);
      try {
        await doDeleteOne(state.one.id);
        commit('setOne', {});
        commit('inProgress', false);
        return { removed: true };
      } catch (err) {
        commit('inProgress', false);
        throw err;
      }
    },
    setOne: async ({ commit }, page) => {
      commit('setOne', humps(page));
    },
    setWallet: async ({ commit }, data) => {
      commit('setWallet', data);
    },
    setWalletValue: async ({ commit }, data) => {
      commit('setWalletValue', data);
    },
    setDateRange: async ({ commit }, range) => {
      commit('setDateRange', range);
    },
    setDashboardMetric: async ({ commit }, data) => {
      commit('setDashboardMetric', data);
    },
    resetOne: async ({ commit }) => {
      commit('setOne', {});
      commit('callingCountries');
      commit('setArtistFanTags');
      commit('smsDetails', null);
      commit('smsDetailsFailed', false);
    },
    fetchCallingCountries: async ({ commit, state }) => {
      commit('callingCountriesInProgress', true);
      commit('callingCountriesError');
      try {
        let list = await doCallingCountryPrice(state.one.id);
        const callingCountries = {};
        for (let country of list) {
          callingCountries[country.callingCountryCode] = country;
        }
        commit('callingCountries', callingCountries);
      } catch (err) {
        console.log(err);
        commit(
          'callingCountriesError',
          'Could not retrieve cost list. Please refresh and try again.',
        );
      }
      commit('callingCountriesInProgress');
    },
    fetchParameters: async ({ commit, state }) => {
      if (!state.one.id) return;

      try {
        const parameters = await doFetchParams(state.one.id);

        if (parameters) {
          commit('parameters', humps(parameters));
        } else {
          commit('parameters');
        }
      } catch (err) {
        console.log(err);
      }
    },
    syncShopifyShop: async ({ dispatch, state }, name) => {
      try {
        await doSyncShopifyShop(state.one.id, name);
        dispatch('fetchParameters');
      } catch (err) {
        console.log(err);
      }
    },
    connectAtvenuToken: async ({ dispatch, state }, token) => {
      try {
        await doConnectAtvenueToken(state.one.id, token);
        dispatch('fetchParameters');
      } catch (err) {
        console.log(err);
      }
    },
    connectBandsintownAccount: async (
      { dispatch, state },
      artistName,
      artistThumbUrl,
      artistUrl,
      artistId,
    ) => {
      try {
        await doConnectBandsintownAccount(
          state.one.id,
          artistName,
          artistThumbUrl,
          artistUrl,
          artistId,
        );
        dispatch('fetchParameters');
      } catch (err) {
        console.log(err);
      }
    },
    connectSeatedAccount: async ({ dispatch, state }, id) => {
      try {
        await doConnectSeatedAccount(state.one.id, id);
        dispatch('fetchParameters');
      } catch (err) {
        console.log(err);
      }
    },
    connectOchreStore: async (
      { dispatch, state },
      clientId,
      clientSecret,
      storeId,
      ochreArtistId,
    ) => {
      try {
        await doConnectOchreStore(state.one.id, clientId, clientSecret, storeId, ochreArtistId);
        dispatch('fetchParameters');
      } catch (err) {
        console.log(err);
      }
    },
    disconnectIntegration: async ({ dispatch, state }, id) => {
      try {
        await doDisconnectIntegration(state.one.id, id);
        dispatch('fetchParameters');
      } catch (err) {
        console.log(err);
      }
    },
    refreshFanTotal: (_, force = false) => {
      const cachedQueryStore = useCachedQueryStore();
      cachedQueryStore.fetchAllFansQuerySummary(force || false);
    },
    fetchArtistFanTags: async ({ commit, rootState }) => {
      if (!rootState.artistOne?.one?.id) {
        console.log('No artist Id');
        return;
      }
      commit('setArtistFanTagsInProgress', true);
      let tags = [];
      try {
        const response = await doFetchArtistFanTags(rootState.artistOne.one.id);
        if (response?.status === 'error' || response?.status === 'ERROR') {
          throw new Error('Could not fetch list of existing tags in use');
        }
        tags = response;
      } catch (error) {
        console.log(error);
      }
      commit('setArtistFanTags', tags || []);
      commit('setArtistFanTagsInProgress');
    },
    disconnectShopifyStore: async ({ commit, dispatch }, { artistId, shop }) => {
      commit('inProgress', true);
      try {
        const isDisconnected = await doDisconnectShopify(artistId, shop);
        if (isDisconnected?.status !== 'OK') {
          throw new Error('Failed to disconnect');
        }
        dispatch('fetchOne', { artistId: artistId });
        commit('inProgress', false);
      } catch (err) {
        commit('inProgress', false);
        throw err;
      }
    },
  },
};
