import { wrapRequest } from '../nav';
import { authHeader, openstageApiCrudUrl, openstageApi2Url } from '../auth';
import * as Sentry from '@sentry/vue';

export const doFetchQRCodes = wrapRequest((artistId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  };
  const url = `${openstageApiCrudUrl}/qr_code?artist_id=eq.${artistId}`;
  return fetch(url, requestOptions);
});

export const doFetchQRCode = wrapRequest((artistId, pageId) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  };
  const url = `${openstageApiCrudUrl}/qr_code?artist_id=eq.${artistId}&page_id=eq.${pageId}`;
  return fetch(url, requestOptions);
});

export const doRegenerateQRCode = wrapRequest(
  (
    artistId,
    qrCodeId = null,
    imageUrl = null,
    pageId = null,
    targetUrl = null,
    margin = 10,
    tag = null,
    lightColour = null,
    darkColour = null,
  ) => {
    // Required props
    if (!artistId) {
      Sentry.captureException('doGenerateQRCodePreview artistId missing');
      throw Error('artistId missing');
    }
    if (!pageId && !targetUrl) {
      Sentry.captureException('doGenerateQRCodePreview both pageId and targetUrl missing');
      throw Error('both pageId and targetUrl missing');
    }
    if (pageId && targetUrl) {
      Sentry.captureException('doGenerateQRCodePreview both pageId and targetUrl were supplied');
      throw Error('both pageId and targetUrl supplied');
    }
    const requestOptions = {
      method: qrCodeId === null ? 'POST' : 'PUT',
      headers: {
        'Content-type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        artistId,
        qrCodeId,
        imageURL: imageUrl,
        pageId,
        targetUrl,
        margin,
        tag,
        lightColour,
        darkColour,
      }),
    };
    const url = `${openstageApi2Url}/generateQRCode`;
    return fetch(url, requestOptions);
  },
);

export const doGenerateQRCodePreview = async (
  artistId,
  imageUrl = null,
  pageId = null,
  targetUrl = null,
  margin = 10,
  tag = null,
  lightColour = null,
  darkColour = null,
) => {
  // Required props
  if (!artistId) {
    Sentry.captureException('doGenerateQRCodePreview artistId missing');
    throw Error('artistId missing');
  }
  if (!pageId && !targetUrl) {
    Sentry.captureException('doGenerateQRCodePreview noth pageId and targetUrl missing');
    throw Error('both pageId and targetUrl missing');
  }
  if (pageId && targetUrl) {
    Sentry.captureException('doGenerateQRCodePreview both pageId and targetUrl were supplied');
    throw Error('both pageId and targetUrl supplied');
  }

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      ...authHeader(),
    },
  };

  let queryParams = new URLSearchParams();
  queryParams.append('artistId', artistId);
  if (pageId) queryParams.append('pageId', pageId);
  if (targetUrl) queryParams.append('targetUrl', targetUrl);
  if (imageUrl) queryParams.append('imageURL', imageUrl);
  if (margin) queryParams.append('margin', margin);
  if (tag) queryParams.append('tag', tag);
  if (lightColour) queryParams.append('lightColour', lightColour);
  if (darkColour) queryParams.append('darkColour', darkColour);
  queryParams = queryParams.toString();
  if (queryParams) queryParams = `?${queryParams}`;

  const url = `${openstageApi2Url}/generateQRCode${queryParams || ''}`;
  return await fetch(url, requestOptions);
};
