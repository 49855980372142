export interface Parameters {
  date: string | null;
  createdAt: string;
  id: string;
  type: string;
  artistId: string;
  value: string | null;
  key: 'shop' | 'token' | 'sync' | string;
  group: string;
}

export interface GorupedParametes {
  id?: string;
  name: string;
  shop?: string;
  token?: string;
  clientSecret?: string;
  storeId?: string;
  clientId?: string;
  sync?: string;
}

export const groupParameters = (parameters: Parameters[]): GorupedParametes[] => {
  const grouped: Record<string, GorupedParametes> = {};

  parameters.forEach((item) => {
    if (!grouped[item.group]) {
      grouped[item.group] = { id: item.id, name: item.group };
    }

    if (item.key === 'shop' || item.key === 'token' || item.key === 'sync') {
      (grouped[item.group] as unknown as Record<string, string>)[item.key] = item.value ?? '';
    }
  });

  return Object.values(grouped).map((item) => ({
    ...item,
    shop: item.shop ?? '',
  }));
};

export const useFilteredParameters = (parameters: any[], key: string) => {
  return parameters.filter((param) => param.type === key);
};
