import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

import {
  doFanQuery,
  doFanQuerySummary,
  doFanQueryCityTierSummary,
  doFanQueryCountrySummary,
} from '@/services/agent/fan.service';
import { useQueryCachedStore, type TChannel } from './query.cache.composable';
import type { AudienceCriteria } from '@/interfaces/AudienceCriteria.interface';
import { sanitizeTags } from '@/services/util.service';

export interface IQuerySummary {
  count: number;
  value: number;
  engagement?: number;
}
export interface IFan {
  artistId: string;
  city?: string;
  countryCallingCode?: string;
  countryCode?: string;
  deliveryAddress?: string;
  email?: string;
  firstName?: string;
  fullName?: string;
  id: string;
  lastName?: string;
  nationalPhoneNumber?: string;
  oneYearEngagement?: number;
  oneYearValue?: number;
  receivesApple?: boolean;
  receivesDeezer?: boolean;
  receivesEmail?: boolean;
  receivesMessaging?: boolean;
  receivesSpotify?: boolean;
  tags?: string[];
  timeZone?: string;
}
export interface ICountry {
  count: number;
  country: string;
  country_code: string;
}
export interface ICity {
  city: string;
  city_id: string;
  count: number;
  country: string;
  latitude: number;
  longitude: number;
}

export type MapCityTier = { value: string; title: string };

export const mapQueryTiersList: MapCityTier[] = [
  { title: 'Major cities', value: 'one' },
  { title: 'Medium cities', value: 'two' },
  { title: 'Minor cities', value: 'three' },
  { title: 'Towns', value: 'four' },
];

export const useCachedQueryStore = defineStore('cachedQuery', () => {
  /* QUERY SUMMARY */
  const summaryFetcher = async (
    artistId: string,
    criteria: AudienceCriteria,
    channel: TChannel | null = null,
  ) => {
    const response = await doFanQuerySummary(artistId, criteria, channel);
    if (!response || !('count' in response)) throw new Error('Could not fetch query summary');
    return {
      count: response?.count || 0,
      value: response?.value || 0,
      engagement: response?.engagement || 0,
    };
  };
  const summaryStore = useQueryCachedStore<IQuerySummary>(summaryFetcher);

  /* ALL-FANS QUERY SUMMARY */
  const allFansQuerySummary = computed(() => summaryStore.getFromCache(null, false) || null);
  const allFansQuerySummaryData = computed(() => allFansQuerySummary.value?.data || null);
  const allFansQuerySummaryCount = computed(() => allFansQuerySummaryData.value?.count || 0);
  const allFansQuerySummaryValue = computed(() => allFansQuerySummaryData.value?.value || 0);
  const allFansQuerySummaryEngagement = computed(
    () => allFansQuerySummaryData.value?.engagement || 0,
  );
  const allFansQuerySummaryInProgress = computed(
    () => allFansQuerySummary.value?.inProgress || false,
  );
  const allFansQuerySummaryFailed = computed(
    () => !allFansQuerySummary.value?.inProgress && !allFansQuerySummaryData.value,
  );
  const fetchAllFansQuerySummary = async (force?: boolean) =>
    await summaryStore.fetchQuery(null, force || false);

  /* QUERY FAN LIST */
  const fanListFetcher = async (
    artistId: string,
    criteria: AudienceCriteria,
    channel: TChannel | null = null,
  ) => {
    const response = await doFanQuery(artistId, criteria, channel);
    if (
      !Array.isArray(response?.items) ||
      response?.status === 'error' ||
      response?.status === 'ERROR'
    ) {
      throw new Error('Could not fetch fans list');
    }
    return (response.items || []).map((fan: IFan) => ({
      ...fan,
      tags: fan?.tags?.length ? sanitizeTags(fan.tags) : [], // why sanitize here?
    }));
  };
  const fanListStore = useQueryCachedStore<IFan[]>(fanListFetcher);

  /* MAP: COUNTRIES */
  const countriesFetcher = async (
    artistId: string,
    criteria: AudienceCriteria,
    channel: TChannel | null = null,
  ) => {
    const response = await doFanQueryCountrySummary(artistId, criteria, channel);
    if (!response?.items) throw new Error('Could not fetch countries list');
    return response.items;
  };
  const countriesStore = useQueryCachedStore<ICountry[]>(countriesFetcher);

  /* MAP: CITIES */
  const defaultQueryCitiesTiers = ['one'];
  const queryCitiesTiers = ref(defaultQueryCitiesTiers);
  const groupByCity = (data: any[]) =>
    Object.values(
      data.reduce((acc, item) => {
        if (!acc[item.city]) {
          acc[item.city] = {
            city: item.city,
            city_id: item.city_id,
            country: item.country,
            count: 0,
            longitude: item.longitude,
            latitude: item.latitude,
          };
        }
        acc[item.city].count += item.count;
        return acc;
      }, {}) as ICity[],
    ).sort((a, b) => b.count - a.count);

  const citiesFetcher = async (
    artistId: string,
    criteria: AudienceCriteria,
    channel: TChannel | null = null,
  ) => {
    const response = await doFanQueryCityTierSummary(
      artistId,
      criteria,
      queryCitiesTiers.value || defaultQueryCitiesTiers,
      [], // countries // needed?
      channel,
    );
    if (!response?.items) throw new Error('Could not fetch cities list');
    return groupByCity(response?.items);
  };
  const getAddtionalCacheKeyData = () => ({
    tier: queryCitiesTiers.value || defaultQueryCitiesTiers,
  });
  const citiesStore = useQueryCachedStore<ICity[]>(citiesFetcher, getAddtionalCacheKeyData);

  return {
    allFansQuerySummaryData,
    allFansQuerySummaryCount,
    allFansQuerySummaryValue,
    allFansQuerySummaryEngagement,
    allFansQuerySummaryInProgress,
    allFansQuerySummaryFailed,
    fetchAllFansQuerySummary,

    getQuerySummary: summaryStore.getFromCache,
    fetchQuerySummary: summaryStore.fetchQuery,

    getQueryFanList: fanListStore.getFromCache,
    fetchQueryFanList: fanListStore.fetchQuery,

    getQueryCountries: countriesStore.getFromCache,
    fetchQueryCountries: countriesStore.fetchQuery,

    queryCitiesTiers,
    getQueryCities: citiesStore.getFromCache,
    fetchQueryCities: citiesStore.fetchQuery,

    groupByCity,
  };
});
