import mixpanel from 'mixpanel-browser';

export const mixpanelPeopleSet = (eventObject: Record<string, unknown>) => {
  if (import.meta.env.VITE_FF_MIXPANEL == 'true') {
    mixpanel.people.set(eventObject);
  }
};

export const mixpanelTrack = (eventName: string, eventObject: Record<string, unknown>) => {
  if (import.meta.env.VITE_FF_MIXPANEL == 'true') {
    mixpanel.track(eventName, eventObject);
  }
};
