import {
  doPostOne,
  doPatchOne,
  doFetchOne,
  doDeleteOne,
  doCancelSubscription,
  doMarkAsNotBot,
} from '@/services/agent/fan.service';
import { getField, updateField } from 'vuex-map-fields';
import humps from 'lodash-humps';
import createHumps from 'lodash-humps/lib/createHumps';
import { snakeCase } from 'lodash';
const snakes = createHumps(snakeCase);

export const fanOne = {
  namespaced: true,
  state: () => ({
    one: {},
    inProgress: false,
    cancelSubscriptionInProgress: false,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    cancelSubscriptionInProgress(state, yesOrNo) {
      state.cancelSubscriptionInProgress = yesOrNo;
    },
  },
  actions: {
    fetchOne: async ({ commit }, fanId) => {
      commit('inProgress', true);
      const fans = await doFetchOne(fanId);
      if (fans && fans.length > 0) commit('setOne', humps(fans[0]));
      commit('inProgress', false);
    },
    save: async ({ commit, state }) => {
      commit('inProgress', true);
      try {
        if (undefined === state.one.id) {
          const fan = await doPostOne(snakes(state.one));
          commit('setOne', humps(fan));
          commit('inProgress', false);
          return { new: true, id: fan.id };
        } else {
          const fan = await doPatchOne(snakes(state.one));
          commit('setOne', humps(fan));
          commit('inProgress', false);
          return { update: true };
        }
      } catch (err) {
        commit('inProgress', false);
        throw err;
      }
    },
    deleteOne: async ({ commit, state }) => {
      commit('inProgress', true);
      try {
        await doDeleteOne(state.one.id);
        commit('setOne', {});
        commit('inProgress', false);
      } catch (err) {
        commit('inProgress', false);
        throw err;
      }
    },
    setOne: async ({ commit }, fan) => {
      commit('setOne', humps(fan));
    },
    cancelSubscription: async ({ commit }, { fanId, artistId }) => {
      commit('cancelSubscriptionInProgress', true);
      try {
        if (!fanId) throw new Error('Cannot cancel fan subscription with no fanId');
        if (!artistId) throw new Error('Cannot cancel fan subscription with no artistId');
        await doCancelSubscription(fanId, artistId);
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        commit('cancelSubscriptionInProgress', false);
      }
    },
    markAsNotBot: async ({ commit, dispatch }, { fanId, artistId }) => {
      commit('inProgress', true);
      try {
        if (!fanId) throw new Error('Cannot mark fan as not bot without fanId');
        if (!artistId) throw new Error('Cannot mark fan as not bot without artistId');
        await doMarkAsNotBot(fanId, artistId);
        await dispatch('fetchOne', fanId);
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        commit('inProgress', false);
      }
    },
  },
};
