<template>
  <svg class="custom-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 0C14.4707 0 18.5 4.02926 18.5 9C18.5 13.9707 14.4707 18 9.5 18C4.52926 18 0.5 13.9707 0.5 9C0.5 4.02926 4.52926 0 9.5 0ZM11.5215 3.72691C11.5218 3.88793 11.5693 6.21703 14.013 6.36223C14.013 7.03371 14.0137 7.52062 14.0137 8.16152C13.8288 8.17207 12.405 8.06871 11.5187 7.2784L11.5159 10.7814C11.5496 13.2128 9.76051 14.6921 7.42156 14.181C3.38879 12.9748 4.72895 6.99363 8.77824 7.63348C8.77824 9.56461 8.7793 7.63312 8.7793 9.56461C7.10656 9.31852 6.54688 10.71 6.9916 11.7067C7.3959 12.6137 9.0616 12.8102 9.64273 11.5305C9.70848 11.2802 9.74117 10.9944 9.74117 10.6738V3.71813L11.5215 3.72691Z"
    />
  </svg>
</template>

<style scoped>
.svg-icon {
  fill: currentColor;
}
</style>
