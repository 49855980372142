import {
  createRouter,
  createWebHistory,
  type NavigationGuardNext,
  type RouteLocationNormalized,
} from 'vue-router';
import store from '@/stores/agent';
import { featureFlags } from '@/services/util.service';
import { mixpanelTrack } from '@/_helpers/mixpanel';

const router = createRouter({
  // Set the router history mode to use browser history
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '',
      name: 'Home',
      component: () => import('@/layouts/DefaultLayout.vue'),
      redirect: { name: 'Directory' },
      children: [
        {
          // Public routes accessible without authentication
          path: '/public',
          redirect: { name: 'TermsPage' },
          name: 'Terms',
          component: () => import('@/layouts/PublicLayout.vue'),
          // Children routes under the 'public' route
          children: [
            {
              path: 'terms',
              name: 'TermsPage',
              component: () => import('@/views/public/Terms.vue'),
              meta: {
                title: 'Terms',
              },
            },
            {
              path: 'sla',
              name: 'SLAPage',
              component: () => import('@/views/public/Sla.vue'),
              meta: {
                title: 'SLA',
              },
            },
            {
              path: 'pricing',
              name: 'PricingPage',
              component: () => import('@/views/public/Pricing.vue'),
              meta: {
                title: 'Pricing',
              },
            },
            {
              path: 'pricing-full',
              name: 'PricingFullPage',
              component: () => import('@/views/public/PricingFull.vue'),
              meta: {
                title: 'Pricing Full',
              },
            },
          ],
        },
        {
          // Gate routes for authentication-related pages
          path: '/gate',
          name: 'Gate',
          redirect: { name: 'LoginPage' },
          component: () => import('@/layouts/GateLayout.vue'),
          // Children routes under the 'gate' route
          children: [
            {
              path: 'login',
              name: 'LoginPage',
              component: () => import('@/views/gate/Login.vue'),
              meta: {
                title: 'Login',
              },
              // beforeEnter: (to, from, next) => {
              //   const isLoggedinUser = store?.getters['auth/isUser'];
              //   if (isLoggedinUser) {
              //     next({ name: 'Directory' });
              //   } else {
              //     next();
              //   }
              // },
            },
            {
              path: 'forgot-password',
              name: 'ForgotPassword',
              component: () => import('@/views/gate/ForgotPassword.vue'),
              meta: {
                title: 'Forgot Password',
              },
            },
            {
              path: 'reset-password',
              name: 'ResetPassword',
              component: () => import('@/views/gate/ResetPassword.vue'),
              meta: {
                title: 'Reset Password',
              },
            },
            {
              path: 'reset-password/openstage',
              name: 'ResetPasswordOpenstage',
              component: () => import('@/views/gate/ResetPassword.vue'),
              meta: {
                title: 'Reset Password Openstage',
              },
            },
            {
              path: 'new-account',
              name: 'NewAccountPage',
              redirect: { name: 'NewAccountStep1' },
              component: () => import('@/views/gate/NewAccount.vue'),
              children: [
                {
                  path: '',
                  name: 'NewAccountStep1',
                  redirect: featureFlags.accountCreationSuspended
                    ? { name: 'NewAccountSuspendedPage' }
                    : undefined,
                  component: () => import('@/components/modules/NewAccount/Step1.vue'),
                  meta: {
                    title: 'New Account Step 1',
                  },
                  children: [],
                },
                {
                  path: 'details',
                  name: 'NewAccountStep2',
                  component: () => import('@/components/modules/NewAccount/Step2.vue'),
                  meta: {
                    title: 'New Account Step 2',
                  },
                },
                {
                  path: 'details-existing',
                  name: 'NewAccountStep2Existing',
                  component: () => import('@/components/modules/NewAccount/Step2.vue'),
                  meta: {
                    title: 'New Account Step 2 Existing',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'finish',
                  name: 'NewAccountStep3',
                  component: () => import('@/components/modules/NewAccount/Step3.vue'),
                  meta: {
                    title: 'New Account Step 3',
                    requiresAuth: true,
                  },
                },
              ],
            },
            {
              path: 'new-account-suspended',
              name: 'NewAccountSuspendedPage',
              redirect: !featureFlags.accountCreationSuspended
                ? { name: 'NewAccountStep1' }
                : undefined,
              component: () => import('@/views/gate/NewAccountSuspended.vue'),
              meta: {
                title: 'Request Demo',
              },
              children: [],
            },
          ],
        },
        {
          // Authenticated routes for the artist directory
          path: '',
          name: 'Directory',
          redirect: { name: 'DirectoryPage' },
          component: () => import('@/layouts/DirectoryLayout.vue'),
          meta: { requiresAuth: true },
          children: [
            {
              path: 'directory',
              name: 'DirectoryPage',
              component: () => import('@/views/manager/ArtistHome.vue'),
              meta: {
                title: 'Directory',
                requiresAuth: true,
              },
            },
            {
              path: 'profile',
              name: 'ProfilePage',
              component: () => import('@/views/manager/Profile.vue'),
              meta: {
                title: 'Profile',
                requiresAuth: true,
              },
            },
          ],
        },
        {
          // Authenticated routes for the artist directory
          path: 'facebook',
          name: 'Facebook',
          component: () => import('@/layouts/Facebook.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: 'bigcartel',
          name: 'BigCartelRedirect',
          component: () => import('@/layouts/BigCartelRedirect.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: 'shopify',
          name: 'ShopifyRedirect',
          component: () => import('@/layouts/ShopifyRedirect.vue'),
          meta: { requiresAuth: true },
        },
        {
          // Authenticated routes for manager artist pages
          path: ':artistId',
          name: 'Artist',
          component: () => import('@/layouts/AgentLayout.vue'),
          redirect: { name: 'Dashboard' },
          meta: {
            title: 'Dashboard',
            breadcrumb: 'Dashboard',
            requiresAuth: true,
          },
          children: [
            // testing embeds
            {
              path: 'embed-test',
              name: 'EmbedTest',
              component: () => import('@/views/public/EmbedTest.vue'),
              meta: {
                title: 'Testing iframe embeds',
                requiresSuperAdmin: true,
              },
            },
            // Temporary redirect for dashboard, remove later
            {
              path: 'dashboard',
              name: 'DashboardRedirect',
              redirect: { name: 'Dashboard' },
            },
            // Temporary redirect for dashboard, remove later
            {
              path: 'dashboard-beta',
              name: 'DashboardBetaRedirect',
              redirect: { name: 'Dashboard' },
            },
            {
              path: '',
              name: 'Dashboard',
              redirect: { name: 'DashboardOverview' },
              component: () => import('@/components-new/views/Dashboard/Dashboard.vue'),
              children: [
                {
                  path: '',
                  name: 'DashboardOverview',
                  component: () => import('@/components-new/views/Dashboard/Overview.vue'),
                },
                {
                  path: 'tasks',
                  name: 'DashboardTasks',
                  component: () => import('@/layouts/Empty.vue'), // TODO
                },
                {
                  path: 'reports',
                  name: 'DashboardReports',
                  component: () => import('@/layouts/Empty.vue'), // TODO
                },
              ],
            },
            {
              path: 'fans',
              name: 'FansPage',
              component: () => import('@/layouts/Empty.vue'),
              redirect: { name: 'FansPageOverview' },
              meta: {
                requiresAuth: true,
                breadcrumb: 'Fans',
              },
              children: [
                {
                  path: 'overview',
                  name: 'FansPageOverview',
                  component: () => import('@/views/manager/fans/Overview.vue'),
                  meta: {
                    title: 'Overview',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'list',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: {
                    name: 'Audience',
                    params: { audienceId: 'new' },
                  },
                  meta: { requiresAuth: true },
                  children: [
                    {
                      path: ':fanId',
                      name: 'FanEditPage',
                      component: () => import('@/views/manager/fans/Fan.vue'),
                      meta: {
                        title: 'Fan Edit',
                        requiresAuth: true,
                      },
                    },
                  ],
                },
                {
                  path: 'audience',
                  name: 'AudiencesBeta',
                  component: () => import('@/components-new/views/Audiences/Audiences.vue'),
                  meta: {
                    title: 'Audiences',
                    breadcrumb: 'Audiences',
                    requiresAuth: true,
                  },
                  redirect: { name: 'AudienceList' },
                  children: [
                    {
                      path: '',
                      name: 'AudienceList',
                      component: () => import('@/components-new/views/Audiences/AudienceList.vue'),
                      meta: {
                        title: 'Audiences',
                      },
                    },
                    {
                      path: ':audienceId',
                      name: 'Audience',
                      meta: {
                        title: 'Audience',
                      },
                      component: () => import('@/components-new/views/Audiences/AudienceOne.vue'),
                      beforeEnter: (
                        to: RouteLocationNormalized,
                        from: RouteLocationNormalized,
                        next: NavigationGuardNext,
                      ) => {
                        const uuidPattern =
                          /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
                        if (
                          to.params.audienceId === 'new' ||
                          (to.params.audienceId as string)?.startsWith('suggestion-') ||
                          uuidPattern.test(to.params.audienceId as string)
                        ) {
                          next();
                        } else {
                          next({
                            name: 'AudienceList',
                            params: {
                              artistId: to.params.artistId,
                            },
                          });
                        }
                      },
                      redirect: { name: 'AudienceView' },
                      children: [
                        {
                          path: '',
                          name: 'AudienceView',
                          meta: {
                            breadcrumb: 'Filters',
                          },
                          component: () =>
                            import(
                              '@/components-new/modules/AudienceBuilder/AudienceFilters/AudienceFilters.vue'
                            ),
                        },
                        {
                          path: 'map',
                          name: 'AudienceViewMap',
                          meta: {
                            breadcrumb: 'Map',
                          },
                          component: () =>
                            import(
                              '@/components-new/modules/AudienceBuilder/AudienceViews/AudienceViewMap.vue'
                            ),
                        },
                        {
                          path: 'list',
                          name: 'AudienceViewList',
                          meta: {
                            breadcrumb: 'Fans',
                          },
                          component: () =>
                            import(
                              '@/components-new/modules/AudienceBuilder/AudienceViews/AudienceViewFans.vue'
                            ),
                        },
                        {
                          path: 'history',
                          name: 'AudienceViewHistory',
                          meta: {
                            breadcrumb: 'History',
                          },
                          component: () =>
                            import(
                              '@/components-new/modules/AudienceBuilder/AudienceViews/AudienceViewHistory.vue'
                            ),
                        },
                        {
                          path: 'comms',
                          name: 'AudienceViewCommunications',
                          meta: {
                            breadcrumb: 'Communications',
                          },
                          redirect: { name: 'AudienceView' }, // temp
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'import',
                  name: 'NewFanUploadPage',
                  component: () => import('@/views/manager/fans/Import.vue'),
                  meta: {
                    title: 'Fan Import',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'stories',
                  name: 'FansPageUploads',
                  component: () => import('@/views/manager/fans/Stories.vue'),
                  meta: {
                    title: 'Fan Uploads',
                    requiresAuth: true,
                  },
                },
              ],
            },

            // Engage
            {
              path: 'engage',
              name: 'EngagePage',
              component: () => import('@/layouts/Empty.vue'),
              redirect: { name: 'SMSDashboard' },
              meta: {
                requiresAuth: true,
                // breadcrumb: 'Engage',
              },
              children: [
                {
                  path: 'page',
                  name: 'Pages',
                  component: () => import('@/components-new/views/Page/Index.vue'),
                  meta: {
                    title: 'Pages',
                    requiresAuth: true,
                    breadcrumb: 'Pages',
                  },
                  redirect: { name: 'PagesOverview' },
                  children: [
                    {
                      path: '',
                      name: 'PagesOverview',
                      component: () => import('@/components-new/views/Page/Overview.vue'),
                      meta: {
                        title: 'Pages',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: 'fanpage', // link-in-bio // ?
                      name: 'FanPage',
                      component: () => import('@/views/manager/engage/Fanpage.vue'),
                      meta: {
                        title: 'Fanpage',
                        requiresAuth: true,
                        breadcrumb: featureFlags.pagesView ? 'Fanpage' : undefined,
                      },
                    },
                    {
                      path: 'landing',
                      name: 'PageList',
                      component: () => import('@/layouts/Empty.vue'),
                      redirect: { name: 'LandingPageList' },
                      meta: {
                        title: 'Landing Pages',
                        requiresAuth: true,
                        breadcrumb: featureFlags.pagesView ? 'Landing Pages' : undefined,
                      },
                      children: [
                        {
                          path: '',
                          name: 'LandingPageList',
                          component: () => import('@/components-new/views/Page/LandingPages.vue'),
                          meta: {
                            title: 'Landing Pages',
                            requiresAuth: true,
                          },
                        },
                        {
                          path: ':pageId',
                          name: 'LandingPageEditor',
                          component: () => import('@/views/manager/engage/LandingPageEdit.vue'), // TODO - in component, if type is stream, redirect to StreamPageEdit
                          meta: {
                            title: 'Landing Page',
                            requiresAuth: true,
                          },
                        },
                        // guided build? currently handled in component
                      ],
                    },
                    {
                      path: 'stream',
                      name: 'StreamPages',
                      component: () => import('@/layouts/Empty.vue'),
                      redirect: { name: 'LaunchPageList' },
                      meta: {
                        title: 'Stream Pages',
                        requiresAuth: true,
                        breadcrumb: featureFlags.pagesView ? 'Stream Pages' : undefined,
                      },
                      children: [
                        {
                          path: '',
                          name: 'LaunchPageList', // StreamPageList
                          component: () => import('@/components-new/views/Page/StreamPages.vue'),
                          meta: {
                            title: 'Stream Pages',
                            requiresAuth: true,
                          },
                        },
                        {
                          path: ':pageId',
                          name: 'LaunchPage', // StreamPage
                          component: () => import('@/views/manager/engage/LaunchPageEdit.vue'), // TODO - in component, if type is landing, redirect to LandingPageEditor
                          meta: {
                            title: 'Stream Page',
                            requiresAuth: true,
                          },
                        },
                      ],
                    },
                    {
                      path: 'content',
                      name: 'Content',
                      component: () => import('@/layouts/Empty.vue'),
                      redirect: { name: 'ContentList' },
                      meta: {
                        title: 'Subscriber content',
                        requiresAuth: true,
                        breadcrumb: featureFlags.pagesView ? 'Subscriber content' : undefined,
                      },
                      children: [
                        {
                          path: '',
                          name: 'ContentList',
                          component: () => import('@/views/manager/engage/ContentList.vue'),
                          meta: {
                            title: 'Content List',
                            requiresAuth: true,
                          },
                        },
                        {
                          path: ':contentId',
                          name: 'ContentEditPage',
                          component: () => import('@/views/manager/engage/ContentEdit.vue'),
                          meta: {
                            title: 'Content',
                            requiresAuth: true,
                          },
                        },
                      ],
                    },
                  ],
                },
                // for legacy route redirect
                {
                  path: 'content',
                  redirect: { name: 'ContentList' },
                },
                {
                  path: 'qrs',
                  name: 'QRCodeList',
                  component: () => import('@/views/manager/engage/QRCodes.vue'),
                  meta: {
                    title: 'QR List',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'codes',
                  name: 'UniqueCodes',
                  component: () => import('@/components-new/views/UniqueCodes/UniqueCodes.vue'),
                  meta: {
                    title: 'Unique Codes',
                    requiresAuth: true,
                  },
                  redirect: { name: 'UniqueCodesList' },
                  children: [
                    {
                      path: '',
                      name: 'UniqueCodesList',
                      component: () =>
                        import('@/components-new/views/UniqueCodes/UniqueCodesList.vue'),
                    },
                    {
                      path: 'design',
                      name: 'UniqueCodesDesign',
                      component: () =>
                        import('@/components-new/views/UniqueCodes/UniqueCodesDesign.vue'),
                    },
                  ],
                },
                {
                  path: 'broadcast',
                  name: 'BroadcastBeta',
                  component: () => import('@/components-new/views/Broadcast/Index.vue'),
                  meta: {
                    requiresAuth: true,
                    breadcrumb: 'Broadcasts',
                    title: 'Broadcasts',
                  },
                  redirect: { name: 'BroadcastListBeta' },
                  children: [
                    {
                      path: '',
                      name: 'BroadcastListBeta',
                      component: () => import('@/components-new/views/Broadcast/List.vue'),
                    },
                    {
                      path: ':broadcastId',
                      name: 'BroadcastOneBeta', // TODO -rename to BroadastOne
                      component: () => import('@/components-new/views/Broadcast/One/Index.vue'),
                      meta: {
                        title: 'Broadcast',
                      },
                      beforeEnter: (
                        to: RouteLocationNormalized,
                        from: RouteLocationNormalized,
                        next: NavigationGuardNext,
                      ) => {
                        const uuidPattern =
                          /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
                        if (
                          to.params.broadcastId === 'new' ||
                          uuidPattern.test(to.params.broadcastId as string)
                        ) {
                          next();
                        } else {
                          next({
                            name: 'BroadcastListBeta',
                            params: {
                              artistId: to.params.artistId,
                            },
                          });
                        }
                      },
                      children: [
                        // legacy
                        {
                          path: 'edit',
                          name: 'BroadcastOneLegacy',
                          component: () =>
                            import('@/components-new/views/Broadcast/One/Legacy/Index.vue'),
                          meta: {
                            breadcrumb: 'Broadcasts',
                            title: 'Broadcast',
                          },
                          redirect: { name: 'BroadcastOneBetaOverview' },
                          children: [
                            {
                              path: '',
                              name: 'BroadcastOneBetaOverview',
                              component: () =>
                                import('@/components-new/views/Broadcast/One/Legacy/Details.vue'),
                            },
                            {
                              path: 'content',
                              name: 'BroadcastOneBetaContent',
                              component: () =>
                                import('@/components-new/views/Broadcast/One/Legacy/Content.vue'),
                            },
                            {
                              path: 'audience',
                              name: 'BroadcastOneBetaAudience',
                              component: () =>
                                import('@/components-new/views/Broadcast/One/Legacy/Audience.vue'),
                              redirect: { name: 'BroadcastOneBetaAudienceViewFilters' },
                              children: [
                                {
                                  path: '',
                                  name: 'BroadcastOneBetaAudienceViewFilters',
                                  component: () =>
                                    import(
                                      '@/components-new/modules/AudienceBuilder/AudienceFilters/AudienceFilters.vue'
                                    ),
                                },
                                {
                                  path: 'map',
                                  name: 'BroadcastOneBetaAudienceViewMap',
                                  component: () =>
                                    import(
                                      '@/components-new/modules/AudienceBuilder/AudienceViews/AudienceViewMap.vue'
                                    ),
                                },
                                {
                                  path: 'list',
                                  name: 'BroadcastOneBetaAudienceViewList',
                                  component: () =>
                                    import(
                                      '@/components-new/modules/AudienceBuilder/AudienceViews/AudienceViewFans.vue'
                                    ),
                                },
                              ],
                            },
                          ],
                        },
                        // v2.0
                        {
                          path: '',
                          name: 'Broadcast', // probably not needed when merged, use BroadcastSummary with loading state within component
                          meta: {
                            title: 'Broadcast',
                          },
                          children: [
                            {
                              path: 'setup',
                              name: 'BroadcastNew', // Rename to BroadcastOneSetup
                              component: () =>
                                import('@/components-new/views/Broadcast/One/Create/Index.vue'),
                              meta: {
                                title: 'Create a Broadcast',
                                breadcrumb: 'Broadcast',
                              },
                              // TODO - add children for each step
                              // children: [
                              // ],
                            },
                            // TODO - this should be the root '' route with loading state within component
                            {
                              path: 'summary',
                              name: 'BroadcastSummary',
                              component: () =>
                                import('@/components-new/views/Broadcast/One/Summary/Index.vue'),
                              meta: {
                                title: 'Broadcast Summary',
                                breadcrumb: 'Broadcast',
                              },
                              redirect: { name: 'BroadcastOverview' },
                              children: [
                                {
                                  path: '',
                                  name: 'BroadcastOverview',
                                  component: () =>
                                    import(
                                      '@/components-new/views/Broadcast/One/Summary/Overview/Overview.vue'
                                    ),
                                },
                                {
                                  path: 'performance',
                                  name: 'BroadcastPerformance',
                                  component: () =>
                                    import(
                                      '@/components-new/views/Broadcast/One/Summary/Performance/Performance.vue'
                                    ),
                                },
                                ...(featureFlags.summaryOffers
                                  ? [
                                      {
                                        path: 'offers',
                                        name: 'BroadcastOffers',
                                        meta: { requiresSuperAdmin: true },
                                        component: () =>
                                          import(
                                            '@/components-new/views/Broadcast/One/Summary/Offers.vue'
                                          ),
                                      },
                                    ]
                                  : []),
                                ...(featureFlags.summaryEarnings
                                  ? [
                                      {
                                        path: 'earnings',
                                        name: 'BroadcastEarnings',
                                        meta: { requiresSuperAdmin: true },
                                        component: () =>
                                          import(
                                            '@/components-new/views/Broadcast/One/Summary/Earnings.vue'
                                          ),
                                      },
                                    ]
                                  : []),
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'automation',
                  name: 'AutomationBeta',
                  component: () => import('@/components-new/views/Automation/Index.vue'),
                  meta: {
                    requiresAuth: true,
                    breadcrumb: 'Automation',
                    title: 'Automations',
                  },
                  redirect: { name: 'AutomationListBeta' },
                  children: [
                    {
                      path: '',
                      name: 'AutomationListBeta',
                      component: () => import('@/components-new/views/Automation/List.vue'),
                    },
                    {
                      path: ':broadcastId',
                      name: 'AutomationOneBeta',
                      component: () => import('@/components-new/views/Automation/One/Index.vue'),
                      meta: {
                        breadcrumb: 'Automation',
                        title: 'Automation',
                      },
                      redirect: { name: 'AutomationOneBetaOverview' },
                      beforeEnter: (
                        to: RouteLocationNormalized,
                        from: RouteLocationNormalized,
                        next: NavigationGuardNext,
                      ) => {
                        const uuidPattern =
                          /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
                        if (
                          to.params.broadcastId === 'new' ||
                          uuidPattern.test(to.params.broadcastId as string)
                        ) {
                          next();
                        } else {
                          next({
                            name: 'AutomationListBeta',
                            params: {
                              artistId: to.params.artistId,
                            },
                          });
                        }
                      },
                      children: [
                        {
                          path: '',
                          name: 'AutomationOneBetaOverview',
                          component: () =>
                            import('@/components-new/views/Automation/One/Details.vue'),
                        },
                        {
                          path: 'content',
                          name: 'AutomationOneBetaContent',
                          component: () =>
                            import('@/components-new/views/Automation/One/Content.vue'),
                        },
                        {
                          path: 'audience',
                          name: 'AutomationOneBetaAudience',
                          component: () =>
                            import('@/components-new/views/Automation/One/Audience.vue'),
                          redirect: { name: 'AutomationOneBetaAudienceViewFilters' },
                          children: [
                            {
                              path: '',
                              name: 'AutomationOneBetaAudienceViewFilters',
                              component: () =>
                                import(
                                  '@/components-new/modules/AudienceBuilder/AudienceFilters/AudienceFilters.vue'
                                ),
                            },
                            {
                              path: 'map',
                              name: 'AutomationOneBetaAudienceViewMap',
                              component: () =>
                                import(
                                  '@/components-new/modules/AudienceBuilder/AudienceViews/AudienceViewMap.vue'
                                ),
                            },
                            {
                              path: 'list',
                              name: 'AutomationOneBetaAudienceViewList',
                              component: () =>
                                import(
                                  '@/components-new/modules/AudienceBuilder/AudienceViews/AudienceViewFans.vue'
                                ),
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'tags',
                  name: 'TagManager',
                  component: () => import('@/views/manager/engage/TagManager.vue'),
                  meta: {
                    title: 'Tag Manager',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'sms',
                  name: 'SMSDashboard',
                  component: () => import('@/views/manager/engage/SMSDashboard.vue'),
                  meta: {
                    title: 'SMS Inbox',
                    requiresAuth: true,
                  },
                },
              ],
            },

            // Analytics
            {
              path: 'analytics',
              name: 'AnalyticsPage',
              component: () => import('@/layouts/Empty.vue'),
              redirect: { name: 'EngagementMetricsPage' },
              meta: { requiresAuth: true },
              children: [
                {
                  path: 'enagement',
                  name: 'EngagementMetricsPage',
                  component: () => import('@/views/manager/analytics/EngagementMetrics.vue'),
                  meta: {
                    title: 'Engagement Metrics',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'transactions',
                  name: 'TransactionsMetricsPage',
                  component: () => import('@/views/manager/analytics/TransactionsMetrics.vue'),
                  meta: {
                    title: 'Transactions Metrics',
                    requiresAuth: true,
                  },
                },
                // TODO - hide for Oasis
                {
                  path: 'metrics',
                  name: 'ArtistMetricsPage',
                  component: () => import('@/views/manager/analytics/ArtistMetrics.vue'),
                  meta: {
                    title: 'Artist Metrics',
                    requiresAuth: true,
                  },
                  beforeEnter: (
                    to: RouteLocationNormalized,
                    from: RouteLocationNormalized,
                    next: NavigationGuardNext,
                  ) => {
                    if ((to.params.artistId as string)?.toLowerCase() === 'oasis') {
                      next({
                        name: 'EngagementMetricsPage',
                        params: {
                          artistId: to.params.artistId,
                        },
                      });
                    } else {
                      next();
                    }
                  },
                },
              ],
            },

            // Artist Settings
            {
              path: 'settings',
              name: 'SettingsPage',
              component: () => import('@/layouts/Empty.vue'),
              redirect: { name: 'ArtistEditPage' },
              meta: { requiresAuth: true },
              children: [
                {
                  path: 'artist',
                  name: 'ArtistEditPage',
                  component: () => import('@/views/manager/settings/Artist.vue'),
                  meta: {
                    title: 'Artist Settings',
                    requiresAuth: true,
                  },
                },
                {
                  path: 'organization',
                  name: 'Organization',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'OrganizationOverviewPage' },
                  meta: { requiresAdmin: true },
                  children: [
                    {
                      path: '',
                      name: 'OrganizationOverviewPage',
                      component: () =>
                        import('@/views/manager/settings/Organisation/Organisation.vue'),
                      meta: {
                        title: 'Account Overview',
                        requiresAdmin: true,
                      },
                    },
                    {
                      path: 'team/:teamId',
                      name: 'OrganizationTeam',
                      component: () =>
                        import('@/views/manager/settings/Organisation/OrganisationTeam.vue'),
                      meta: {
                        title: 'Account Team',
                        requiresAdmin: true,
                      },
                    },
                    {
                      path: 'team/:teamMembershipId/edit',
                      name: 'OrganizationTeamMembershipEditPage',
                      component: () =>
                        import(
                          '@/views/manager/settings/Organisation/OrganisationTeamMembershipEdit.vue'
                        ),
                      meta: {
                        title: 'Account Team Membership',
                        requiresAdmin: true,
                      },
                    },
                  ],
                },
                {
                  path: 'catalog',
                  name: 'Catalog',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'CatalogList' },
                  meta: { requiresAuth: true },
                  children: [
                    {
                      path: '',
                      name: 'CatalogList',
                      component: () => import('@/views/manager/settings/Catalog/CatalogList.vue'),
                      meta: {
                        title: 'Music catalog',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: 'new',
                      name: 'CatalogItemNew',
                      component: () => import('@/views/manager/settings/Catalog/CatalogNew.vue'),
                      meta: {
                        title: 'Catalog Create',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: ':id',
                      name: 'CatalogId',
                      component: () => import('@/layouts/Empty.vue'),
                      redirect: { name: 'CatalogItem' },
                      meta: { requiresAuth: true },
                      children: [
                        {
                          path: '',
                          name: 'CatalogItem',
                          component: () =>
                            import('@/views/manager/settings/Catalog/CatalogView.vue'),
                          meta: {
                            title: 'Catalog Item',
                            requiresAuth: true,
                          },
                        },
                        {
                          path: 'edit',
                          name: 'CatalogItemEdit',
                          component: () =>
                            import('@/views/manager/settings/Catalog/CatalogEdit.vue'),
                          meta: {
                            title: 'Catalog Edit',
                            requiresAuth: true,
                          },
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'integrations',
                  name: 'IntegrationsOverviewPage',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'Integrations' },
                  meta: {
                    requiresAuth: true,
                    title: 'Integrations',
                    breadcrumb: 'Integrations',
                  },
                  children: [
                    {
                      path: '',
                      name: 'Integrations',
                      component: () =>
                        import('@/components-new/views/Integrations/Integrations.vue'),
                      meta: {
                        title: 'Integrations',
                        requiresAuth: true,
                      },
                    },
                    {
                      path: ':integration',
                      name: 'Integration',
                      component: () =>
                        import('@/components-new/views/Integrations/Integration.vue'),
                      meta: {
                        requiresAuth: true,
                        title: 'Integration',
                        breadcrumb: 'Integrations',
                      },
                    },
                  ],
                },
              ],
            },

            // Admin Settings
            {
              path: 'admin',
              name: 'AdminPage',
              component: () => import('@/layouts/Empty.vue'),
              redirect: { name: 'Activity' },
              meta: { requiresSuperAdmin: true },
              children: [
                {
                  path: 'artist-activity',
                  name: 'Activity',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'ArtistActivityPage' },
                  meta: { requiresSuperAdmin: true },
                  children: [
                    {
                      path: '',
                      name: 'ArtistActivityPage',
                      component: () => import('@/views/manager/admin/ArtistActivityList.vue'),
                      meta: { requiresSuperAdmin: true },
                    },
                    {
                      path: ':artist',
                      name: 'ArtistActivityOrganizationPage',
                      component: () => import('@/views/manager/admin/ArtistActivityView.vue'),
                      meta: { requiresSuperAdmin: true },
                    },
                  ],
                },
                {
                  path: 'artist-financials',
                  name: 'ArtistFinancialsPage',
                  component: () => import('@/views/manager/admin/ArtistFinancialsPage.vue'),
                  meta: { requiresSuperAdmin: true },
                },
                {
                  path: 'fan-activity',
                  name: 'FanActivityPage',
                  component: () => import('@/views/manager/admin/FanActivity.vue'),
                  meta: { requiresSuperAdmin: true },
                },
                {
                  path: 'organization',
                  name: 'OrganizationManagement',
                  component: () => import('@/layouts/Empty.vue'),
                  redirect: { name: 'OrganizationManagementPage' },
                  meta: { requiresSuperAdmin: true },
                  children: [
                    {
                      path: '',
                      name: 'OrganizationManagementPage',
                      component: () => import('@/views/manager/admin/OrganizationManagement.vue'),
                      meta: { requiresSuperAdmin: true },
                    },
                    {
                      path: ':orgId',
                      name: 'OrganizationEditPage',
                      component: () => import('@/views/manager/admin/OrganizationEdit.vue'),
                      meta: { requiresSuperAdmin: true },
                    },
                  ],
                },
                {
                  path: 'fan',
                  name: 'FanManagementPage',
                  component: () => import('@/views/manager/admin/FanManagement.vue'),
                  meta: { requiresSuperAdmin: true },
                },
                {
                  path: 'user',
                  name: 'UserManagementPage',
                  component: () => import('@/views/manager/admin/UserManagement.vue'),
                  meta: { requiresSuperAdmin: true },
                },
                // {
                //   path: 'query',
                //   name: 'AIQueryPage',
                //   component: () => import('@/views/manager/admin/Cities.vue'),
                //   meta: { requiresSuperAdmin: true },
                // },
              ],
            },

            // {
            //   path: 'twilio',
            //   name: 'Twilio',
            //   beforeEnter(to, from, next) {
            //     next({
            //       name: 'ArtistEditPage',
            //       params: to.params,
            //       query: {
            //         ...(to.query || {}),
            //         tab: 'phone',
            //       },
            //       replace: true,
            //     })
            //   },
            // },
          ],
        },

        // Root third party path/redirect pages
        {
          path: '/stripeConnect',
          name: 'StripeConnect',
          component: () => import('@/views/manager/StripeConnected.vue'),
          meta: { requiresAuth: true },
        },
      ],
    },

    // Other
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('@/layouts/NotFoundLayout.vue'),
    },
  ],
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed') ||
    error.message.includes('error loading dynamically imported module') ||
    error.message.includes('Unable to preload CSS') ||
    error.message.includes("'text/html' is not a valid JavaScript MIME type")
  ) {
    window.location = to.fullPath as any;
  }
});

router.beforeEach((to, from, next) => {
  const isLoggedinUser = store?.getters['auth/isUser'];
  const isAdminUser = store?.getters['auth/isAdmin'];
  const isSuperAdminUser = store?.getters['auth/isAdmin'];

  if (
    !isLoggedinUser &&
    to.matched.some(
      (record) =>
        record.meta.requiresAuth || record.meta.requiresAdmin || record.meta.requiresSuperAdmin,
    )
  ) {
    if (sessionStorage) sessionStorage.interrupted_page = to.fullPath;
    next({ path: '/gate', query: to.query });
  } else if (
    !isAdminUser &&
    to.matched.some((record) => record.meta.requiresAdmin || record.meta.requiresSuperAdmin)
  ) {
    next('');
  } else if (!isSuperAdminUser && to.matched.some((record) => record.meta.requiresSuperAdmin)) {
    next('');
  } else {
    document.title = `${to.meta?.title ? `${to.meta?.title} - ` : ''}Openstage Manager`;

    /*
     * Track Event when a page is viewed in Mixpanel.
     * Track Property 'Page' to see which URL was viewed.
     * Track Property 'FullPage' to see which URL was viewed on which artist.
     * Track Property 'Title' to see the name of the page viewed.
     */
    const pathname = to.fullPath;
    const parts = pathname.split('/').slice(2).join('/');
    const newPath = '/' + parts;
    mixpanelTrack('Page Viewed', {
      Page: newPath,
      FullPage: pathname,
      Title: `${to.meta?.title ? `${to.meta?.title}` : 'Openstage Manager'}`,
    });

    next();
  }
});

export default router;
