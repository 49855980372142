import {
  doDeleteOne as doDeleteOneTeamMembership,
  doPostOne as doPostOneTeamMembership,
  doFetchOne as doFetchOneTeamMembership,
} from '@/services/team_membership.service.js';
import { getField, updateField } from 'vuex-map-fields';
import humps from 'lodash-humps';

export const teamMembershipOne = {
  namespaced: true,
  state: () => ({
    one: null,
    inProgress: true,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setOne(state, one) {
      state.one = one;
    },
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    updateSelectedUser(state, selectedUser) {
      state.one.userId = selectedUser.userId;
    },
  },
  actions: {
    newInvite({ commit }, { organizationId, userId }) {
      commit('inProgress', false);
      commit('setOne', {
        team: 'main',
        userProfile: null,
        organizationId: organizationId,
        userId: userId,
        organization: null,
        teamRoles: ['guest'],
      });
    },
    fetchOne: async ({ commit }, teamMembershipId) => {
      try {
        commit('inProgress', true);
        const list = await doFetchOneTeamMembership(teamMembershipId);
        if (list) {
          let teamMembership = list[0];
          commit('inProgress', false);
          commit('setOne', humps(teamMembership));
        }
      } catch (err) {
        commit('inProgress', false);
        throw err;
      }
    },
    save: async ({ commit }, { organizationId, userId }) => {
      commit('inProgress', true);
      try {
        let teamMembership = {};
        teamMembership.user_id = userId;
        teamMembership.organization_id = organizationId;
        teamMembership.team = 'main';
        await doPostOneTeamMembership(teamMembership);
      } catch (err) {
        commit('inProgress', false);
        throw err;
      }
    },
    delete: async ({ commit, state }) => {
      commit('inProgress', true);
      try {
        await doDeleteOneTeamMembership(state.one.id);
        commit('setOne', {});
        commit('inProgress', false);
        return { removed: true };
      } catch (err) {
        commit('inProgress', false);
        throw err;
      }
    },
  },
};
