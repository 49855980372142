import { mixpanelTrack } from '@/_helpers/mixpanel';
import { getField, updateField } from 'vuex-map-fields';
import { uuidv4, toSnakes, ensureHttps } from '@/services/util.service';
import humps from 'lodash-humps';
import { isEqual, cloneDeep } from 'lodash';
import {
  doFetchOne,
  doPostOne,
  doPatchOne,
  doPublish,
  doCreateOrPatchPollAnswer,
  doCreateOrPatchPoll,
  doFetchListByArtist,
  doDeletePoll,
  doDeletePollAnswer,
} from '@/services/agent/page.service';
import { convertToUrlSafe } from '@/_helpers/url';

import { JourneySteps } from './journeysteps.class';
import defaultLandingpageFactory from './landingpage.default';
import { PageColors } from '@/services/color.service';
import moment from 'moment';

export const availableColorSlots = ['a', 'b', 'c', 'd'];
export const availableColorSlotsNew = ['base', 'text', 'accent', 'label'];

export const tabLabels = {
  info: {
    short: 'Config',
    long: 'Configuration & Setup',
  },
  design: {
    short: 'Design',
    long: 'Page Design',
  },
  layout: {
    short: 'Layout',
    long: 'Layout & Positioning',
  },
  journey: {
    short: 'Journey',
    long: 'Journey Steps',
  },
  seo: {
    short: 'Stats',
    long: 'Statistics',
  },
};

export const availableSteps = {
  welcome: {
    title: 'Welcome Step',
    icon: 'mdi-hand-wave-outline',
    singleton: true,
  },
  thanks: {
    title: 'Thanks Step',
    icon: 'mdi-hand-heart-outline',
    singleton: true,
  },
  presave: {
    title: 'Pre-save Step',
    icon: 'mdi-music-box-multiple-outline',
    singleton: true,
  },
  poll: {
    title: 'Poll Step',
    icon: 'mdi-poll',
  },
  links: {
    title: 'Links Step',
    icon: 'mdi-link',
  },
  upload: {
    title: 'Collect Upload Step',
    icon: 'mdi-cloud-upload-outline',
  },
  videoPlayer: {
    title: 'Video Player Step',
    icon: 'mdi-video-outline',
  },
  audioPlayer: {
    title: 'Audio Player Step',
    icon: 'mdi-headphones',
  },
  download: {
    title: 'Download Step',
    icon: 'mdi-download-circle-outline',
  },
  socials: {
    title: 'Social Handles Step',
    icon: 'mdi-at',
    singleton: true,
  },
  extra: {
    title: 'Hidden Extra Page',
    icon: 'mdi-note-plus-outline',
    singleton: true,
  },
  whereShouldWePlay: {
    title: 'Where should we play?',
    icon: 'mdi-map-marker-question',
    singleton: true,
  },
  text: {
    title: 'Simple Text Step',
    icon: 'mdi-card-text-outline',
  },
  numericSlider: {
    title: 'Numeric Slider Step',
    icon: 'mdi-tune-variant',
  },
};

export const completeTagPrefix = 'signup-';

const hasChangesHelper = (a, b) => {
  if (!a || !b) return true;
  return !isEqual(a, b);
};

const emptyOne = {
  type: 'landing',
  // id: null,
  // artistId: null,
  // createdAt: null,
  // updatedAt: null,
  title: '',
  relativeUrl: '',
  accessToken: '',
  metaData: {
    info: {},
    design: {},
    layout: {},
    journey: {},
    seo: {},
    backgroundColor: '#060d13',
    primaryColor: '#ffffff',
  },
  publishedMetaData: {},
};

export const landingPageOne = {
  namespaced: true,
  state: () => ({
    initializing: true,
    inProgress: true,
    archivingInProgress: false,
    error: { state: false },
    one: cloneDeep(emptyOne),
    oneUpdated: cloneDeep(emptyOne),

    formValid: {
      info: true,
      design: true,
      layout: true,
      seo: true,
    },
    invalidJourneySteps: new Set(),
  }),
  getters: {
    getField,
    getPageId: (state) => {
      return state.oneUpdated?.id || '';
    },
    isLegacy: (state) => {
      return state.one?.type === 'legacy' || false;
    },
    baseUrl: (state, getters, rootState) => {
      return `${import.meta.env.VITE_PROTOCOL}://${rootState.artistOne?.one?.shortName?.toLowerCase()}${import.meta.env.VITE_FANPAGE_SUBDOMAIN_MODIFIER || ''}.${import.meta.env.VITE_FANPAGE_DOMAIN}`;
    },
    socialShareUrl: (state, getters) => {
      return `${getters.baseUrl}/${state.oneUpdated?.metaData?.info?.relativeUrl || getters.defaultPageShortUrlName || ''}`;
    },
    fullUrl: (state, getters) => {
      return `${getters.baseUrl}/${state.oneUpdated?.metaData?.info?.relativeUrl || getters.defaultPageShortUrlName || ''}`;
    },
    defaultPageShortUrlName: (state) => {
      if (!state?.oneUpdated?.title) return '';
      return convertToUrlSafe(state.oneUpdated.title);
    },
    hasChangesInfo: (state) => {
      return (
        state?.one?.title !== state?.oneUpdated?.title ||
        !state?.oneUpdated?.metaData?.info?.relativeUrl ||
        hasChangesHelper(state?.one?.metaData?.info, state?.oneUpdated?.metaData?.info)
      );
    },
    hasChangesDesign: (state) => {
      return hasChangesHelper(state?.one?.metaData?.design, state?.oneUpdated?.metaData?.design);
    },
    hasChangesLayout: (state) => {
      return hasChangesHelper(state?.one?.metaData?.layout, state?.oneUpdated?.metaData?.layout);
    },
    hasChangesJourney: (state) => {
      return hasChangesHelper(state?.one?.metaData?.journey, state?.oneUpdated?.metaData?.journey);
    },
    hasChangesSeo: (state) => {
      return hasChangesHelper(state?.one?.metaData?.seo, state?.oneUpdated?.metaData?.seo);
    },
    hasChanges: (state, getters) => {
      return (
        state.one.type !== state.oneUpdated.type ||
        getters.hasChangesInfo ||
        getters.hasChangesDesign ||
        getters.hasChangesLayout ||
        getters.hasChangesJourney ||
        getters.hasChangesSeo
      );
    },
    hasErrors: (state) => {
      return (
        !state.formValid.info ||
        !state.formValid.design ||
        !state.formValid.layout ||
        state.invalidJourneySteps.size > 0
      );
    },
    hasUnpublishedChangesInfo: (state) => {
      return hasChangesHelper(
        state?.oneUpdated?.metaData?.info,
        state?.oneUpdated?.publishedMetaData?.info,
      );
    },
    hasUnpublishedChangesDesign: (state) => {
      return hasChangesHelper(
        state?.oneUpdated?.metaData?.design,
        state?.oneUpdated?.publishedMetaData?.design,
      );
    },
    hasUnpublishedChangesLayout: (state) => {
      return hasChangesHelper(
        state?.oneUpdated?.metaData?.layout,
        state?.oneUpdated?.publishedMetaData?.layout,
      );
    },
    hasUnpublishedChangesJourney: (state) => {
      return hasChangesHelper(
        state?.oneUpdated?.metaData?.journey,
        state?.oneUpdated?.publishedMetaData?.journey,
      );
    },
    hasUnpublishedChangesSeo: (state) => {
      return hasChangesHelper(
        state?.oneUpdated?.metaData?.seo,
        state?.oneUpdated?.publishedMetaData?.seo,
      );
    },
    hasUnpublishedChanges: (state, getters) => {
      return (
        getters.hasUnpublishedChangesInfo ||
        getters.hasUnpublishedChangesDesign ||
        getters.hasUnpublishedChangesLayout ||
        getters.hasUnpublishedChangesJourney ||
        getters.hasUnpublishedChangesSeo
      );
    },
    isPublished: (state) => {
      return !!state?.one?.publishedMetaData?.info?.completeTag;
    },
    getStep: (state) => (id) => {
      return state.oneUpdated.metaData.journey.getStep(id);
    },
    getStepInPosition: (state) => (position) => {
      return state.oneUpdated.metaData.journey.steps.find((f) => f.position === position) || null;
    },
    getContentField: (state) => (id, key) => {
      return state.oneUpdated.metaData.journey.getContentField(id, key);
    },
    isJourneyStepInvalid: (state) => (id) => {
      return state.invalidJourneySteps.has(id);
    },
    isJourneyStepChanged: (state) => (id) => {
      return hasChangesHelper(
        state?.oneUpdated?.metaData?.journey?.getStep(id),
        state?.one?.metaData?.journey?.getStep(id),
      );
    },
    isJourneyStepUnpublished: (state) => (id) => {
      return hasChangesHelper(
        state.oneUpdated.metaData.journey.getStep(id),
        state.one.publishedMetaData?.journey?.getStep(id) || null,
      );
    },
    journeyStepsSize: (state) => {
      return state?.oneUpdated?.metaData?.journey?.length || 0;
    },
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo) {
      state.inProgress = yesOrNo;
    },
    archivingInProgress(state, yesOrNo) {
      state.archivingInProgress = yesOrNo;
    },
    initializing(state, yesOrNo) {
      state.initializing = yesOrNo;
    },
    error(state, error) {
      state.error = error;
    },
    setArtistId(state, id) {
      state.one.artistId = id;
      state.oneUpdated.artistId = id;
    },
    one(state, one) {
      one.metaData.journey = new JourneySteps(one.metaData.journey || {});
      if (one?.publishedMetaData?.journey?.first) {
        one.publishedMetaData.journey = new JourneySteps(one.publishedMetaData.journey || {});
      }
      state.one = one;
    },
    oneUpdated(state, one) {
      one.metaData.journey = new JourneySteps(one.metaData.journey || {});
      if (one?.publishedMetaData?.journey?.first) {
        one.publishedMetaData.journey = new JourneySteps(one.publishedMetaData.journey || {});
      }
      one.metaData.info.title = one?.title;
      state.oneUpdated = one;
    },
    addStep(state, data) {
      const { type, position } = data;
      state.oneUpdated.metaData.journey.addStep(type, position);
    },
    removeStep(state, id) {
      state.oneUpdated.metaData.journey.removeStep(id);
    },
    moveStep(state, data) {
      const { id, movement = 1 } = data;
      state.oneUpdated.metaData.journey.moveStep(id, movement);
    },
    updateColor(state, data) {
      const { group, key, newColor } = data;
      if (!group || !key) return;
      state.oneUpdated.metaData.design.colorPalette[group][key] = newColor || null;
    },
    updateContentField(state, data) {
      const { id, key, value } = data;
      state.oneUpdated.metaData.journey.updateContentField(id, key, value);
    },
    switchStatus(state, data) {
      const { id, status } = data;
      state.oneUpdated.metaData.journey.switchStatus(id, (status && 'live') || 'draft');
    },
    setPageStatus(state, status) {
      if (!['live', 'draft', 'archived'].includes(status)) return;
      state.oneUpdated.status = status;
    },
    setRelativeUrl(state, relativeUrl) {
      state.oneUpdated.relativeUrl = relativeUrl;
      state.oneUpdated.metaData.info.relativeUrl = relativeUrl;
      if (state.oneUpdated?.publishedMetaData?.info?.relativeUrl)
        state.oneUpdated.publishedMetaData.info.relativeUrl = relativeUrl;
    },

    // Step-list mutations
    createContentListItem(state, data) {
      const { stepId = null, type = null } = data;
      state.oneUpdated.metaData.journey
        .getStep(stepId)
        ?.createContentListItem(null, null, null, null, null, null, type);
    },
    updateContentListItem(state, data) {
      const { stepId, optionId, key, value, type = null } = data;
      if (!stepId || !optionId || !key) return;
      state.oneUpdated.metaData.journey
        .getStep(stepId)
        ?.updateContentListItem(optionId, key, value, type);
    },
    deleteContentListItem(state, data) {
      const { stepId, optionId, type = null } = data;
      if (!stepId || !optionId) return;
      state.oneUpdated.metaData.journey.getStep(stepId)?.deleteContentListItem(optionId, type);
    },
    moveContentListItem(state, data) {
      const { stepId, optionId, movement = 1, type = null } = data;
      state.oneUpdated.metaData.journey
        .getStep(stepId)
        ?.moveContentListItem(optionId, movement, type);
    },
    cloneContentListItem(state, data) {
      const { stepId, optionId, type } = data;
      if (!stepId || !optionId) return;
      state.oneUpdated.metaData.journey.getStep(stepId)?.cloneContentListItem(optionId, type);
    },
    sortContentList(state, data) {
      const { stepId } = data;
      state.oneUpdated.metaData.journey.getStep(stepId)?.sortContentList();
    },

    addInvalidJourneyStepId(state, id) {
      state.invalidJourneySteps.add(id);
      state.invalidJourneySteps = new Set(state.invalidJourneySteps);
    },
    deleteInvalidJourneyStepId(state, id) {
      state.invalidJourneySteps.delete(id);
      state.invalidJourneySteps = new Set(state.invalidJourneySteps);
    },
    resetInvalidJourneyStepIds(state) {
      state.invalidJourneySteps = new Set();
    },
  },
  actions: {
    setInitializing: ({ commit }, yesOrNo) => {
      if (yesOrNo) commit('error', { state: false });
      commit('initializing', yesOrNo);
      commit('inProgress', yesOrNo);
    },
    setInProgress: ({ commit }, yesOrNo) => {
      if (yesOrNo) commit('error', { state: false });
      commit('inProgress', yesOrNo);
    },
    setOne({ commit }, one) {
      const processOne = (input) => {
        const humped = humps(input);

        const layout = humped.metaData.layout;
        const publishedLayout = humped?.publishedMetaData?.layout;
        const info = humped.metaData.info;
        const publishedInfo = humped?.publishedMetaData?.info;

        layout.content.shadow = layout.content.shadow || false;
        layout.hideFooterLinks = layout.hideFooterLinks || false;
        if (publishedLayout) {
          publishedLayout.content.shadow = publishedLayout.content.shadow || false;
          publishedLayout.hideFooterLinks = publishedLayout.hideFooterLinks || false;
        }

        info.skipFanStatusCheck = info.skipFanStatusCheck || false;
        info.skipCaptcha = info.skipCaptcha || false;
        if ('skipFirstFanUpdate' in info) delete info.skipFirstFanUpdate;
        info.signupsDisabled = info.signupsDisabled || false;
        if (publishedInfo) {
          publishedInfo.skipFanStatusCheck = publishedInfo.skipFanStatusCheck || false;
          publishedInfo.skipCaptcha = publishedInfo.skipCaptcha || false;
          if ('skipFirstFanUpdate' in publishedInfo) delete publishedInfo.skipFirstFanUpdate;
          publishedInfo.signupsDisabled = publishedInfo.signupsDisabled || false;
        }

        return humped;
      };

      const humpedOne = processOne(one);
      commit('one', humpedOne);

      const humpedOneUpdated = cloneDeep(humpedOne);
      humpedOneUpdated.type = 'landing';

      commit('oneUpdated', humpedOneUpdated);
    },
    newOne({ dispatch, commit }, artistId) {
      dispatch('setInitializing', true);
      commit('resetInvalidJourneyStepIds');
      dispatch('setOne', defaultLandingpageFactory());
      commit('setArtistId', artistId);
      dispatch('setInitializing', false);
    },
    setFromLegacyOne({ dispatch }, legacyOne) {
      const newOne = defaultLandingpageFactory();
      // ROOT
      newOne.id = legacyOne.id;
      newOne.artistId = legacyOne.artistId;
      newOne.createdAt = legacyOne.createdAt;
      newOne.updatedAt = legacyOne.updatedAt;
      newOne.type = 'legacy';
      // INFO
      if (legacyOne.accessToken) {
        newOne.accessToken = legacyOne.accessToken;
        newOne.metaData.info.accessToken = legacyOne.accessToken;
        newOne.metaData.info.passwordProtected = true;
      }
      newOne.title = legacyOne.title;
      newOne.metaData.info.title = legacyOne.title;
      newOne.relativeUrl = legacyOne.relativeUrl.toLowerCase();
      newOne.metaData.info.relativeUrl = legacyOne.relativeUrl.toLowerCase();
      newOne.metaData.info.completeTag = `${completeTagPrefix}${legacyOne.metaData.currentTag}`;
      // storing old tags just in case for reference
      newOne.metaData.info.legacyPageTag = legacyOne.metaData.currentTag;
      if (legacyOne.metaData.launchDate) {
        newOne.metaData.info.scheduled = true;
        newOne.metaData.info.launchDate = moment
          .utc(legacyOne.metaData.launchDate)
          .format('YYYY-MM-DDTHH:mm:ss');
      }
      // DESIGN
      const primary = new PageColors('--page', legacyOne.metaData.backgroundColor || '#000000');
      const secondary = new PageColors('--page', legacyOne.metaData.highlightColor || '#000000');
      newOne.metaData.design.colorPalette.primary.base = primary._base;
      newOne.metaData.design.colorPalette.primary.text = primary._text;
      newOne.metaData.design.colorPalette.primary.accent = primary._accent;
      newOne.metaData.design.colorPalette.primary.label = primary._accentLabel;
      newOne.metaData.design.colorPalette.secondary.base = secondary._base;
      newOne.metaData.design.colorPalette.secondary.text = secondary._text;
      newOne.metaData.design.colorPalette.secondary.accent = secondary._accent;
      newOne.metaData.design.colorPalette.secondary.label = secondary._accentLabel;
      newOne.metaData.design.background.portrait.useFade = true;
      newOne.metaData.design.background.landscape.contained =
        legacyOne.metaData.containBackground || false;
      if (legacyOne.metaData.backgroundUrl)
        newOne.metaData.design.background.landscape.url = legacyOne.metaData.backgroundUrl;
      if (legacyOne.metaData.backgroundUrlMobile)
        newOne.metaData.design.background.portrait.url = legacyOne.metaData.backgroundUrlMobile;
      // LAYOUT
      if (legacyOne.metaData.anchorLeft) newOne.metaData.layout.content.leftAligned = true;
      newOne.metaData.layout.content.blur = true;
      if (legacyOne.metaData.foregroundVideoUrl) {
        newOne.metaData.layout.foreground.use = true;
        newOne.metaData.layout.foreground.type = 'video';
        newOne.metaData.layout.foreground.videoType = 'upload';
        newOne.metaData.layout.foreground.videoUrl = legacyOne.metaData.foregroundVideoUrl;
      }
      // foregroundVideoSelectToShow: true, --> hideVideoOnWelcome: Boolean ?

      // JOURNEY
      // welcome
      const firstContent = {};
      if (legacyOne.metaData.beginPhone) firstContent.beginEmail = false;
      else firstContent.beginEmail = true;
      if (legacyOne.metaData.collectName) firstContent.collectName = true;
      if (legacyOne.metaData.collectNameMethod === 'mandatory')
        firstContent.collectNameMandatory = true;
      if (legacyOne.metaData.collectPhoneNumber) firstContent.collectPhoneNumber = true;
      if (legacyOne.metaData.collectPhoneNumberMethod === 'mandatory')
        firstContent.collectPhoneNumberMandatory = true;
      if (legacyOne.metaData.collectLocation) firstContent.collectLocation = true;
      if (legacyOne.metaData.collectLocationMethod === 'mandatory')
        firstContent.collectLocationMandatory = true;
      if (legacyOne.metaData.collectDateOfBirth) firstContent.collectDateOfBirth = true;
      if (legacyOne.metaData.collectDateOfBirthMethod === 'mandatory')
        firstContent.collectDateOfBirthMandatory = true;
      if (legacyOne.metaData.collectDeliveryAddress) {
        firstContent.collectDeliveryAddress = true;
        firstContent.collectDeliveryAddressMandatory = true;
      }
      if (legacyOne.metaData.dobAgeLimit) firstContent.dobAgeLimit = legacyOne.metaData.dobAgeLimit;
      if (legacyOne.metaData.dobFormatDmy) firstContent.dobFormatDmy = true;
      if (legacyOne.metaData.welcomeTermsLabel)
        firstContent.termsLabel = legacyOne.metaData.welcomeTermsLabel;
      if (legacyOne.metaData.welcomeTerms) firstContent.termsText = legacyOne.metaData.welcomeTerms;

      // last
      const lastContent = {};
      if (legacyOne.metaData.successfulText)
        lastContent.successfulText = legacyOne.metaData.successfulText;
      if (legacyOne.metaData.hideLinksThanks) lastContent.hideLinks = true;

      // extra
      const extraContent = {};
      if (legacyOne.metaData.pageText) extraContent.text = legacyOne.metaData.pageText;
      // check if video type or other
      if (
        legacyOne.metaData.pageVideoUrl &&
        (legacyOne.metaData.pageVideoUrl.endsWith('.mov') ||
          legacyOne.metaData.pageVideoUrl.endsWith('.mp4'))
      ) {
        extraContent.videoUsed = true;
        extraContent.videoType = 'upload';
        extraContent.videoUrl = legacyOne.metaData.pageVideoUrl;
      } else if (
        legacyOne.metaData.pageVideoUrl &&
        (legacyOne.metaData.pageVideoUrl.endsWith('.jpg') ||
          legacyOne.metaData.pageVideoUrl.endsWith('.jpeg') ||
          legacyOne.metaData.pageVideoUrl.endsWith('.png') ||
          legacyOne.metaData.pageVideoUrl.endsWith('.gif') ||
          legacyOne.metaData.pageVideoUrl.endsWith('.webp'))
      ) {
        extraContent.giveawayUsed = true;
        extraContent.giveawayUrl = legacyOne.metaData.pageVideoUrl;
        extraContent.giveawayImage = legacyOne.metaData.pageVideoUrl;
      } else if (legacyOne.metaData.pageVideoUrl) {
        extraContent.giveawayUsed = true;
        extraContent.giveawayUrl = legacyOne.metaData.pageVideoUrl;
        extraContent.giveawayLabel = 'Download file';
      }
      if (legacyOne.metaData.pageVideoButtonUrl) {
        extraContent.linksUsed = true;
        extraContent.links = [
          {
            id: uuidv4(),
            position: 0,
            url: ensureHttps(legacyOne.metaData.pageVideoButtonUrl),
            label: legacyOne.metaData.pageVideoButtonText || null,
            image: null,
          },
        ];
      }

      // inbetweens
      let steps = [];
      const pollContent = {};
      if (legacyOne.metaData.questionsLabel)
        pollContent.question = legacyOne.metaData.questionsLabel;
      if (legacyOne.metaData.questionSingleChoice) pollContent.singleChoice = true;
      if (legacyOne.metaData.questions?.length) {
        pollContent.options = legacyOne.metaData.questions.map((m, i) => ({
          id: uuidv4(),
          position: i,
          value: m.value || '',
          file: m.file || '',
          tag: m.tag || '',
        }));
      }
      if (Object.keys(pollContent).length > 0) {
        steps.push({
          content: pollContent,
          id: uuidv4(),
          position: steps.length,
          status: 'live',
          type: 'poll',
        });
      }
      const linksContent = {};
      if (legacyOne.metaData.links) {
        linksContent.links = legacyOne.metaData.links.map((m, i) => ({
          id: uuidv4(),
          position: i,
          url: ensureHttps(m.url) || '',
          label: m.text || '',
          image: '',
        }));
      }
      if (Object.keys(linksContent).length > 0 && linksContent.links.length) {
        steps.push({
          content: linksContent,
          id: uuidv4(),
          position: steps.length,
          status: 'live',
          type: 'links',
        });
      }
      const presaveContent = {};
      if (legacyOne.metaData.followText)
        presaveContent.labelSpotify = legacyOne.metaData.followText;
      if (legacyOne.metaData.followTextApple)
        presaveContent.labelAppleMusic = legacyOne.metaData.followTextApple;
      if (legacyOne.metaData.followTextDeezer)
        presaveContent.labelDeezer = legacyOne.metaData.followTextDeezer;
      if (legacyOne.metaData.skipPresave) presaveContent.allowSkip = true;
      if (legacyOne.metaData.hideLinksPresave) presaveContent.hideLinks = true;
      if (
        legacyOne.metaData.welcomeHeader &&
        !['SPOTIFY', 'Pre-save on Spotify', 'Spotify'].includes(legacyOne.metaData.welcomeHeader)
      )
        presaveContent.heading = legacyOne.metaData.welcomeHeader;
      if (legacyOne.metaData.welcomeText)
        presaveContent.description = legacyOne.metaData.welcomeText;
      if (legacyOne.metaData.doPresave) presaveContent.spotify = true;
      if (legacyOne.metaData.doPresaveApple) presaveContent.appleMusic = true;
      if (legacyOne.metaData.doPresaveDeezer) presaveContent.deezer = true;
      if (Object.keys(presaveContent).length > 0) {
        // set to false, so not true by default
        presaveContent.spotify = presaveContent.spotify || false;
        presaveContent.appleMusic = presaveContent.appleMusic || false;
        presaveContent.deezer = presaveContent.deezer || false;
        if (presaveContent.spotify || presaveContent.appleMusic || presaveContent.deezer)
          steps.push({
            type: 'presave',
            status: 'live',
            id: uuidv4(),
            content: presaveContent,
            position: steps.length,
          });
      }
      const uploadContent = {};
      if (legacyOne.metaData.upload) uploadContent.uploadNeeded = true;
      if (legacyOne.metaData.uploadLabel)
        uploadContent.uploadTitle = legacyOne.metaData.uploadLabel;
      if (legacyOne.metaData.story) uploadContent.storyNeeded = true;
      if (legacyOne.metaData.storyTitle) uploadContent.storyTitle = legacyOne.metaData.storyTitle;
      if (legacyOne.metaData.storyLabel)
        uploadContent.storyInputLabel = legacyOne.metaData.storyLabel;
      if (legacyOne.metaData.storyDisclaimer)
        uploadContent.disclaimer = legacyOne.metaData.storyDisclaimer;
      if (legacyOne.metaData.storyTerms) uploadContent.terms = legacyOne.metaData.storyTerms;
      if (legacyOne.metaData.storyButton)
        uploadContent.continueButtonLabel = legacyOne.metaData.storyButton;
      if (legacyOne.metaData.storyContinueText)
        uploadContent.continueText = legacyOne.metaData.storyContinueText;
      const uploadStepStatus =
        uploadContent.uploadNeeded || uploadContent.storyNeeded ? 'live' : 'draft';
      if (Object.keys(uploadContent).length > 0) {
        steps.push({
          type: 'upload',
          status: uploadStepStatus,
          id: uuidv4(),
          content: uploadContent,
          position: steps.length,
        });
      }
      const socialsContent = {};
      if (legacyOne.metaData.collectSocialHandleCallToAction)
        uploadContent.callToAction = legacyOne.metaData.collectSocialHandleCallToAction;
      if (legacyOne.metaData.collectSocialHandleFacebook) uploadContent.collectFacebook = true;
      if (legacyOne.metaData.collectSocialHandleInstagram) uploadContent.collectInstagram = true;
      if (legacyOne.metaData.collectSocialHandleTiktok) uploadContent.collectTiktok = true;
      if (legacyOne.metaData.collectSocialHandleTwitter) uploadContent.collectTwitter = true;
      if (legacyOne.metaData.collectSocialHandleYoutube) uploadContent.collectYoutube = true;
      if (Object.keys(socialsContent).length > 0) {
        steps.push({
          type: 'socials',
          status: 'live',
          id: uuidv4(),
          content: socialsContent,
          position: steps.length,
        });
      }

      // set it finally
      newOne.metaData.journey = new JourneySteps({
        first: { content: firstContent },
        last: { content: lastContent },
        extra: {
          status: Object.keys(extraContent).length ? 'live' : 'draft',
          content: extraContent,
        },
        steps,
      });

      // SEO
      if (legacyOne.metaData.facebookPixel)
        newOne.metaData.seo.facebookPixelId = legacyOne.metaData.facebookPixel;
      if (legacyOne.metaData.gtm) newOne.metaData.seo.googleTagManagerId = legacyOne.metaData.gtm;
      if (legacyOne.metaData.tiktokPixel)
        newOne.metaData.seo.tiktokPixelId = legacyOne.metaData.tiktokPixel;

      dispatch('setOne', JSON.parse(JSON.stringify(newOne)));
    },
    async fetchOne({ commit, dispatch }, pageId) {
      dispatch('setInitializing', true);
      commit('resetInvalidJourneyStepIds');
      try {
        if (!pageId) throw new Error('no page id');
        const list = await doFetchOne(pageId);
        if (!list || !list[0]) throw new Error('no such page in list');
        const one = humps(list[0]);
        if (one?.type !== 'landing') {
          // WHat about 'launch' pages?
          dispatch('setFromLegacyOne', one);
        } else if (!one.metaData.info.relativeUrl) {
          dispatch('setOne', {
            ...one,
            metaData: {
              ...one.metaData,
              info: {
                ...(one?.metaData?.info || {}),
                relativeUrl: one?.relativeUrl || null,
              },
            },
          });
        } else {
          dispatch('setOne', one);
        }
        dispatch('setInitializing', false);
      } catch (err) {
        console.log(err);
        commit('error', {
          state: true,
          type: 'not found',
          message: 'Could not load this landing page, please reload and retry.',
        });
        dispatch('setInitializing', false);
      }
    },
    async saveChanges({ state, commit, dispatch, rootState }) {
      if (!rootState.artistOne.one.id) return;
      commit('inProgress', true);
      let success = true;

      try {
        await dispatch('applyDefaultsToEmpties', state.oneUpdated);

        const one = toSnakes(JSON.parse(JSON.stringify(state.oneUpdated)));
        let responseOne = {};

        if (state?.one?.id) {
          responseOne = await doPatchOne(one);
        } else {
          responseOne = await doPostOne(one);
          mixpanelTrack('Page published', {
            Type: 'landing page',
            Method: 'create',
          });
        }

        if (!responseOne.id) throw new Error('failed');
        dispatch('setOne', responseOne);
      } catch (error) {
        console.error(error);
        success = false;
      }

      commit('inProgress', false);
      return success;
    },
    async publishChanges({ state, commit, dispatch }) {
      commit('inProgress', true);
      let success = true;

      try {
        await dispatch('applyDefaultsToEmpties', {
          ...state.oneUpdated,
          relativeUrl: state.oneUpdated.metaData.info.relativeUrl,
        });

        // Create/update polls and answers before publishing
        await dispatch('updatePollSteps');

        let one = JSON.parse(JSON.stringify(state.oneUpdated));
        one.publishedMetaData = JSON.parse(JSON.stringify(one.metaData));
        one = toSnakes(one);

        const responseOne = await doPatchOne(one);
        if (!responseOne.id) throw new Error('failed');

        await doPublish({ pageId: responseOne.id });

        dispatch('setOne', responseOne);
      } catch (error) {
        console.error(error);
        success = false;
      }

      commit('inProgress', false);
      return success;
    },

    async updatePollSteps({ state, rootState, commit }) {
      const artistId = rootState.artistOne?.one?.id;
      if (!artistId) throw new Error('No artist ID found');

      // Get all original poll steps to check for removed polls
      const originalPollSteps = state.one.publishedMetaData?.journey?.steps?.filter(
        (step) => step.type === 'poll' && step.content?.version === '2.0',
      );

      // Find polls that were removed (only if we have published metadata)
      if (originalPollSteps?.length) {
        const removedPollIds = originalPollSteps
          .map((step) => step.content.pollId)
          .filter(
            (pollId) =>
              pollId &&
              !state.oneUpdated.metaData.journey.steps.some(
                (step) => step.type === 'poll' && step.content?.pollId === pollId,
              ),
          );

        // Delete removed polls (this will cascade delete their answers)
        for (const pollId of removedPollIds) {
          await doDeletePoll(pollId);
        }
      }

      // Filter steps that are poll steps with version "2.0"
      const pollSteps = state.oneUpdated.metaData.journey.steps.filter(
        (step) => step.type === 'poll' && step.content?.version === '2.0',
      );
      if (!pollSteps?.length) return;

      for (const step of pollSteps) {
        const originalStep = state.one.publishedMetaData?.journey?.steps?.find(
          (s) => s.id === step.id,
        );

        // Find answers that were removed
        if (originalStep?.content?.options) {
          const removedAnswerIds = originalStep.content.options
            .map((option) => option.pollAnswerId)
            .filter(
              (answerId) =>
                answerId &&
                !step.content.options.some((option) => option.pollAnswerId === answerId),
            );

          // Delete removed answers
          for (const answerId of removedAnswerIds) {
            await doDeletePollAnswer(answerId);
          }
        }

        // Check if poll needs to be created or updated
        const needsPollUpdate =
          !step.content.pollId || // New poll
          step.content.question !== originalStep?.content?.question ||
          step.content.singleChoice !== originalStep?.content?.singleChoice;

        // Only create/update poll if needed
        const updatedPoll = needsPollUpdate
          ? await doCreateOrPatchPoll({
              artist_id: artistId,
              page_id: state.one.id,
              id: step.content.pollId || undefined,
              question: step.content.question || null,
              unique: step.content.singleChoice || false,
            })
          : { id: step.content.pollId };

        // Update poll ID in store
        commit('updateContentField', { id: step.id, key: 'pollId', value: updatedPoll.id });

        // Handle answers
        for (const option of step.content.options || []) {
          const needsAnswerUpdate =
            !option.pollAnswerId || // New answer
            option.value !==
              originalStep?.content?.options?.find((o) => o.id === option.id)?.value ||
            option.file !== originalStep?.content?.options?.find((o) => o.id === option.id)?.file ||
            option.image !== originalStep?.content?.options?.find((o) => o.id === option.id)?.image;

          if (needsAnswerUpdate) {
            const updatedAnswer = await doCreateOrPatchPollAnswer({
              artist_id: artistId,
              poll_id: updatedPoll.id,
              id: option.pollAnswerId || null,
              answer: option.value || null,
              audio: option.file || null,
              image: option.image || null,
            });

            // Update store with response data
            commit('updateContentListItem', {
              stepId: step.id,
              optionId: option.id,
              key: 'pollId',
              value: updatedAnswer.poll_id,
            });
            commit('updateContentListItem', {
              stepId: step.id,
              optionId: option.id,
              key: 'pollAnswerId',
              value: updatedAnswer.id,
            });
          }
        }
      }
    },

    async applyDefaultsToEmpties({ rootState, getters, commit }, one) {
      const info = JSON.parse(JSON.stringify(one.metaData.info));
      const layout = JSON.parse(JSON.stringify(one.metaData.layout));
      const relativeUrl = info.relativeUrl || one.relativeUrl || getters.defaultPageShortUrlName;
      info.relativeUrl = relativeUrl;
      if (!info.completeTag || info.completeTag === completeTagPrefix) {
        info.completeTag = `${completeTagPrefix}${relativeUrl}`;
      }
      const primaryColors = one.metaData.design.colorPalette.primary;

      if (
        layout?.foreground?.use &&
        !layout?.foreground?.url &&
        !layout?.foreground?.urlModified &&
        !layout?.foreground?.videoType &&
        !layout?.foreground?.videoUrl
      ) {
        layout.foreground.use = false;
      }
      await commit('oneUpdated', {
        ...one,
        accessToken: (one.metaData.info.passwordProtected && one.accessToken) || null,
        metaData: {
          ...one.metaData,
          info,
          layout,
          journey: {
            ...one.metaData.journey,
            first: {
              ...one.metaData.journey.first,
              content: {
                ...one.metaData.journey.first.content,
                beginEmail: one.metaData.journey.first.content.collectPhoneNumber
                  ? one.metaData.journey.first.content.beginEmail
                  : true,
              },
            },
          },
          seo: {
            ...one.metaData.seo,
            facebookPixelId:
              one.metaData.seo.facebookPixelId || rootState?.artistOne?.one?.metaPixelId || null,
            googleTagManagerId:
              one.metaData.seo.googleTagManagerId ||
              rootState?.artistOne?.one?.googleTagManagerId ||
              null,
          },
          backgroundColor: PageColors.hslToHex(primaryColors.base),
          primaryColor: PageColors.hslToHex(
            primaryColors.text || PageColors.getComplementer(primaryColors.base, 150),
          ),
        },
        relativeUrl,
      });
    },
    revertToLastSaved({ state, commit, dispatch }) {
      commit('resetInvalidJourneyStepIds');
      dispatch('setOne', state.one);
    },
    async revertToLastPublished({ state, commit, dispatch }) {
      commit('resetInvalidJourneyStepIds');
      dispatch('setOne', {
        ...state.one,
        title:
          state.one.publishedMetaData.info.title ||
          state.one.metaData.info.title ||
          state.one.title ||
          'Untitled Page',
        metaData: state.one.publishedMetaData,
      });
      await dispatch('publishChanges');
    },
    removeStep({ commit }, stepId) {
      commit('removeStep', stepId);
      commit('deleteInvalidJourneyStepId', stepId);
    },
    async archivePage({ state, commit, dispatch, rootState }) {
      if (!rootState?.artistOne?.one?.id) return;

      commit('archivingInProgress', true);
      commit('setPageStatus', 'archived');

      // ensure page list is fetched
      if (!rootState?.pageList?.list?.length) {
        await dispatch('pageList/fetchListByArtist', rootState.artistOne.one.id, { root: true });
      }

      let newRelativeUrl = state.oneUpdated.relativeUrl;
      let otherPageWithUrlExists = true;
      let suffixCounter = 0;
      let suffix = '--archived';
      while (otherPageWithUrlExists) {
        if (suffixCounter) suffix = `-${suffixCounter}--archived`;
        otherPageWithUrlExists =
          rootState?.list?.find((f) => f.relativeUrl === `${newRelativeUrl}${suffix}`) || false;
        if (otherPageWithUrlExists) {
          suffixCounter += 1;
        }
      }
      commit('setRelativeUrl', `${newRelativeUrl}${suffix}`);

      const saveSuccess = await dispatch('saveChanges');
      const publishSuccess = await dispatch('publishChanges');
      commit('archivingInProgress');
      return saveSuccess && publishSuccess;
    },
    async cloneOne({ state, commit, dispatch, rootState }, { title = '', relativeUrl = '' }) {
      if (!title || !relativeUrl) {
        return { success: false, message: "Can't clone a page without a title" };
      }
      dispatch('setInitializing', true);
      commit('inProgress', true);

      try {
        // Check if page with this URL already exists
        const existingPages = await doFetchListByArtist(rootState.artistOne.one.id);
        const urlExists = existingPages.some(
          (page) => page.relative_url?.toLowerCase() === relativeUrl.toLowerCase(),
        );

        if (urlExists) {
          dispatch('setInitializing', false);
          commit('inProgress', false);
          return { success: false, message: `Page with URL '${relativeUrl}' already exists` };
        }

        let one = JSON.parse(JSON.stringify(state.one));

        // Create a clean version of steps without poll data
        one.metaData.journey.steps = one.metaData.journey.steps.map((step) => {
          if (step.type === 'poll' && step.content?.version === '2.0') {
            return {
              ...step,
              content: {
                ...step.content,
                pollId: null,
                options: step.content.options.map((option) => ({
                  ...option,
                  pollId: null,
                  pollAnswerId: null,
                })),
              },
            };
          }
          return step;
        });

        const clonedOne = {
          ...one,
          title: title,
          relativeUrl: relativeUrl,
          metaData: {
            ...one.metaData,
            info: {
              ...one.metaData.info,
              title: title,
              relativeUrl: relativeUrl,
              completeTag: `${completeTagPrefix}${relativeUrl}`,
            },
            seo: {
              facebookPixelId: '',
              googleTagManagerId: '',
              tiktokPixelId: '',
            },
          },
          publishedMetaData: {},
        };

        if ('id' in clonedOne) delete clonedOne.id;
        if ('createdAt' in clonedOne) delete clonedOne.createdAt;
        if ('updatedAt' in clonedOne) delete clonedOne.updatedAt;
        if ('cloudinaryAt' in clonedOne) delete clonedOne.cloudinaryAt;

        let responseOne;
        try {
          responseOne = await doPostOne(toSnakes(clonedOne));
        } catch (error) {
          const errorMessage =
            error.response?.data?.message || error.message || 'Failed to create page';
          dispatch('setInitializing', false);
          commit('inProgress', false);
          return { success: false, message: errorMessage };
        }

        if (!responseOne?.id) {
          dispatch('setInitializing', false);
          commit('inProgress', false);
          return { success: false, message: 'Failed to create page - no ID returned' };
        }

        // Process the response and set it in store
        const processedResponse = humps(responseOne);
        dispatch('setOne', processedResponse);

        // Publish the page to create polls and update store
        await dispatch('publishChanges');

        mixpanelTrack('Page published', {
          Type: 'landing page',
          Method: 'clone',
        });

        dispatch('setInitializing', false);
        commit('inProgress', false);

        return { success: true, pageId: responseOne.id };
      } catch (error) {
        console.error(error);
        dispatch('setInitializing', false);
        commit('inProgress', false);
        return { success: false, message: error };
      }
    },
  },
};
