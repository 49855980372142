import { doFetchListByArtist, doPatchOne, doFetchOne } from '@/services/agent/page.service';
import { getField, updateField } from 'vuex-map-fields';
import humps from 'lodash-humps';
import createHumps from 'lodash-humps/lib/createHumps';
import { snakeCase } from 'lodash';
const snakes = createHumps(snakeCase);

export const pageList = {
  namespaced: true,
  state: () => ({
    list: [],
    selected: [],
    inProgress: false,
    recordsCount: 0,
    error: null,
  }),
  getters: {
    getField,
  },
  mutations: {
    updateField,
    inProgress(state, yesOrNo = false) {
      state.inProgress = yesOrNo;
    },
    setList(state, list = []) {
      state.list = list;
    },
    // setPage(state, page) {
    //     if(!page?.id) return
    //     const index = state?.list?.find(f => f.id === page.id)
    //     if(index < 0) return
    //     state.list = [
    //         ...state.list.slice(0, index),
    //         page,
    //         ...state.list.slice(index + 1),
    //     ]
    // },
    setListCount(state, number) {
      state.recordsCount = number;
    },
  },
  actions: {
    fetchListByArtist: async ({ commit }, artistId) => {
      commit('inProgress', true);
      const list = await doFetchListByArtist(artistId);
      if (list) {
        commit('setList', humps(list));
      }
      commit('inProgress');
    },
    resetList: async ({ commit }) => {
      commit('setList', []);
    },
    async unarchivePage({ state, commit, dispatch, rootState }, pageJson) {
      if (!pageJson?.relativeUrl || !rootState?.artistOne?.one?.id) {
        console.log('TODO - could not unarchive');
        return false;
      }
      commit('inProgress', true);

      let newRelativeUrl = pageJson.relativeUrl.replace(/--archived$/, '');
      let otherPageWithUrlExists = true;
      let suffixCounter = 0;
      let suffix = '';
      while (otherPageWithUrlExists) {
        suffix = suffixCounter ? `-${suffixCounter}` : '';
        otherPageWithUrlExists =
          state?.list?.find((f) => f.relativeUrl === `${newRelativeUrl}${suffix}`) || false;
        if (otherPageWithUrlExists) {
          suffixCounter += 1;
        }
      }

      newRelativeUrl = `${newRelativeUrl}${suffix}`;

      try {
        let responseOne = {};
        responseOne = await doPatchOne(
          snakes({
            ...pageJson,
            status: 'draft',
            relativeUrl: newRelativeUrl,
            metaData: {
              ...pageJson.metaData,
              info: {
                ...pageJson.metaData.info,
                relativeUrl: newRelativeUrl,
              },
            },
            publishedMetaData: {},
          }),
        );

        if (!responseOne.id) throw new Error('failed');

        // commit('setPage', humps(responseOne))
        dispatch('fetchListByArtist', rootState.artistOne.one.id);
        return true;
      } catch (error) {
        console.error(error);
        commit('inProgress', false);
        return false;
      }
    },
    getPageById: async ({ commit }, pageId) => {
      try {
        commit('inProgress', true);
        const response = await doFetchOne(pageId);
        if (!response || response?.status === 'ERROR') {
          throw new Error(response.message);
        }
        commit('inProgress', false);
        return response;
      } catch (err) {
        commit('inProgress', false);
        return null;
      }
    },
  },
};
